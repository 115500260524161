body {
  margin: 20px auto;
  font-family: "Lato";
  background: #666;
  color: #fff;
}

* {
  margin: 0;
  padding: 0;
}

h2 {
  font-weight: bold;
  font-size: 2rem;
}
p {
  font-family: "Reenie Beanie";
  font-size: 20px;
}
.year-activity-notes-card-ul,
li {
  list-style: none;
}
.year-activity-notes-card-ul {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.year-activity-notes-card-ul li a {
  text-decoration: none;
  color: #000;
  background: #ffc;
  display: block;

  height: auto;
  width: 10em;
  padding: 1em;
  box-shadow: 5px 5px 7px rgba(33, 33, 33, 0.7);
  transform: rotate(-6deg);
  transition: transform 0.15s linear;
}

.year-activity-notes-card-ul li:nth-child(even) a {
  transform: rotate(4deg);
  position: relative;
  top: 5px;
  background: #cfc;
}

.year-activity-notes-card-ul li a:hover,
.year-activity-notes-card-ul li a:focus {
  box-shadow: 10px 10px 7px rgba(0, 0, 0, 0.7);
  transform: scale(1.25);
  position: relative;
  z-index: 5;
}

.year-activity-notes-card-ul li textarea {
  text-decoration: none;
  color: #000;
  background: #ffc;
  display: block;

  height: 200px;
  width: 250px;
  padding: 1em;
  box-shadow: 5px 5px 7px rgba(33, 33, 33, 0.7);
  transform: rotate(-6deg);
  transition: transform 0.15s linear;
}
.year-activity-notes-card-ul li textarea:hover,
.year-activity-notes-card-ul li textarea:focus {
  box-shadow: 10px 10px 7px rgba(0, 0, 0, 0.7);
  transform: scale(1.25);
  position: relative;
  z-index: 5;
}

.year-activity-notes-card-ul li {
  margin: 1em;
}

.year-activity-notes-card-title {
  font-size: 12px;
}
.year-activity-notes-card-content {
  font-size: 12px;
}

.year-activity-notes-card-btn-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, auto);
}
.year-activity-notes-card-trash {
  color: grey;
  font-size: 24px;
  grid-column: 1;
}
.year-activity-notes-card-trash:hover {
  color: red;
  font-size: 28px;
}
.year-activity-notes-card-edit {
  color: grey;
  font-size: 15px;
  grid-column: 2;
  align-self: flex-end;
  justify-self: end;
}
.year-activity-notes-card-edit:hover {
  color: blue;
  text-decoration: underline;
}
