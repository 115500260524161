.children-home-container {
  background-color: var(--white-smoke-home);
  border-collapse: collapse;
}
.children-embeded-selector-container {
  background-color: transparent;
  border-collapse: collapse;
  overflow: auto;
}

.children-home-table th {
  padding-left:10px;  

}
.children-home-table tr,
.children-home-table td{
  padding-left:10px ;
  padding-top: 5px;
}

.children-home-table td {
  font-size: 15px;
}

.selected-child,
.children-home-table tr:hover,
.children-home-card-edit:hover {
  background-color: var(--green-theme-color);
  color: #ffffff;
}

.children-home-card-edit {
  color: var(--grey-text-color);
  cursor: pointer;
}
.children-home-card-edit:hover {
  text-decoration: underline;
  color: var(--link-color);
}

.children-home-title,
.children-embeded-selector-title {
  padding-bottom: 5px;
}
.children-home-table-header {

}
.children-home-table-header-name {
  width: 250px;
}
.children-home-card-name {
  
}
.children-home-card-name {
  display: grid;
  grid-template-columns: repeat(auto-fit, auto);
  align-items: center;
  justify-content: start;
}
.children-home-card-name-avatar {
  grid-column: 1;
  grid-row: 1;
}

.children-home-card-name-text {
  grid-column: 2;
  grid-row: 1;
  padding-left: 10px;
  color: var(--grey-text-color)
}
.children-home-card-dob{
  color: var(--grey-text-color)
}
.children-home-card-year-text{
 color: var(--grey-text-color) 
}

.children-embeded-selector-name-text {
  font-family: "Newsreader";
  font-size: 16px;
}
.children-home-card-year-text{
  
  text-align: center;
  
}

.children-home-card-view-plan {


  cursor: pointer;
}
.children-home-card-view-plan:hover {
  text-decoration: underline;
  color: var(--link-color);
}
.selection-enabled {
  cursor: pointer;
}
