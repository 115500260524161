.sticky-side-container {
	position: sticky;
	top: 62px;
	z-index: 1000;
	height: 500px;
}

/* Base font size for mobile */

.base-title {
	font-size: 16px;
	line-height: 49px;
	letter-spacing: -0.36px;

	font-family: "Alegreya";
	font-style: normal;
	font-weight: 700;
}
.base-title-normal-height {
	font-size: 16px;
	line-height: 17px;
	letter-spacing: -0.36px;

	font-family: "Alegreya";
	font-style: normal;
	font-weight: 700;
}
.base-sub-title {
	font-size: 14px;
	line-height: 15px;
	letter-spacing: -0.36px;

	font-family: "Alegreya";
	font-style: normal;
	font-weight: 700;
}
.base-parafraph {
	font-size: 14px;
	line-height: 15px;
	letter-spacing: -0.3px;

	font-family: "Alegreya";
	font-style: normal;
	font-weight: 400;
}

.sidebar-menu-item {
	font-size: 14px;
	line-height: 15px;
	letter-spacing: -0.36px;

	font-family: "Alegreya";
	font-style: normal;
	font-weight: 400;
}
.overall-sidebar-menu-item {
	font-size: 17px;
	line-height: 15px;
	letter-spacing: -0.36px;

	font-family: "Alegreya";
	font-style: normal;
	font-weight: 400;
}

.base-button-text {
	font-size: 13px;
	line-height: 14px;
	letter-spacing: -0.3px;

	font-family: "Alegreya";
	font-style: normal;
	font-weight: 400;
}
.table-row-header {
	font-size: 13px;
	line-height: 14px;
	font-family: Vidaloka;
	letter-spacing: -0.21px;

	font-family: "Alegreya";
	font-style: normal;
	font-weight: 600;
}

.table-row-item {
	font-size: 12px;
	line-height: 12px;
	letter-spacing: -0.21px;

	font-family: "Alegreya";
	font-style: normal;
	font-weight: 400;
}

.table-row-link {
	font-size: 11px;
	line-height: 11px;
	letter-spacing: -0.21px;

	font-family: "Alegreya";
	font-style: normal;
	font-weight: 700;
}
.calendar-custom-day {
	font-size: 18px;
	line-height: 19px;
	letter-spacing: -0.36px;

	font-family: "Alegreya";
	font-style: normal;
	font-weight: 700;
}
.calendar-custom-month {
	font-size: 15px;
	line-height: 16px;
	letter-spacing: -0.36px;

	font-family: "Alegreya";
	font-style: normal;
	font-weight: 700;
}
.calendar-custom-time {
	font-size: 14px;
	line-height: 15px;
	letter-spacing: -0.36px;

	font-family: "Alegreya";
	font-style: normal;
	font-weight: 700;
}

.hover-brighten {
	transition: filter 0.3s ease;
}
.hover-brighten:hover {
	filter: brightness(1.12);
}
.hover-darken {
	transition: filter 0.3s ease;
}
.hover-darken:hover {
	filter: brightness(0.8);
}
@media screen and (max-height: 600px) {
	.home-page-sidebar-lower,
	.home-page-sidebar-lower-year-plan {
		display: none;
	}
}

/* Larger font size for tablets */
@media (min-width: 768px) {
	.base-title {
		font-size: 18px;
		line-height: 51px;
		letter-spacing: -0.36px;

		font-family: "Alegreya";
		font-style: normal;
		font-weight: 700;
	}
	.base-title-normal-height {
		font-size: 18px;
		line-height: 19px;
		letter-spacing: -0.36px;

		font-family: "Alegreya";
		font-style: normal;
		font-weight: 700;
	}
	.base-sub-title {
		font-size: 16px;
		line-height: 17px;
		letter-spacing: -0.36px;

		font-family: "Alegreya";
		font-style: normal;
		font-weight: 700;
	}
	.base-parafraph {
		font-size: 15px;
		line-height: 16px;
		letter-spacing: -0.3px;

		font-family: "Alegreya";
		font-style: normal;
		font-weight: 400;
	}

	.sidebar-menu-item {
		font-size: 14px;
		line-height: 15px;
		letter-spacing: -0.36px;

		font-family: "Alegreya";
		font-style: normal;
		font-weight: 400;
	}
	.overall-sidebar-menu-item {
		font-size: 17px;
		line-height: 15px;
		letter-spacing: -0.36px;

		font-family: "Alegreya";
		font-style: normal;
		font-weight: 400;
	}

	.base-button-text {
		font-size: 14px;
		line-height: 16px;
		letter-spacing: -0.3px;

		font-family: "Alegreya";
		font-style: normal;
		font-weight: 400;
	}
	.table-row-header {
		font-size: 15px;
		line-height: 14px;
		font-family: Vidaloka;
		letter-spacing: -0.21px;

		font-family: "Alegreya";
		font-style: normal;
		font-weight: 600;
	}

	.table-row-item {
		font-size: 14px;
		line-height: 14px;
		letter-spacing: -0.21px;

		font-family: "Alegreya";
		font-style: normal;
		font-weight: 400;
	}

	.table-row-link {
		font-size: 12px;
		line-height: 12px;
		letter-spacing: -0.21px;

		font-family: "Alegreya";
		font-style: normal;
		font-weight: 700;
	}

	.calendar-custom-day {
		font-size: 18px;
		line-height: 19px;
		letter-spacing: -0.36px;

		font-family: "Alegreya";
		font-style: normal;
		font-weight: 700;
	}
	.calendar-custom-month {
		font-size: 15px;
		line-height: 16px;
		letter-spacing: -0.36px;

		font-family: "Alegreya";
		font-style: normal;
		font-weight: 700;
	}
	.calendar-custom-time {
		font-size: 14px;
		line-height: 15px;
		letter-spacing: -0.36px;

		font-family: "Alegreya";
		font-style: normal;
		font-weight: 700;
	}
}

@media screen and (max-height: 500px) {
	.sticky-side-container {
		height: 400px;
	}
}
