.weekly-ordered-schedule-activity-container-normal {
	display: grid;
	grid-template-columns: repeat(auto-fit, auto);
	gap: 2px;
	grid-auto-flow: dense;

	justify-items: center;

	border-style: none;
	border-radius: 10px;

	border-bottom-style: solid;

	border-color: #335e4c;
	padding-bottom: 10px;
}
.weekly-ordered-schedule-activity-btns-container {
	display: flex;
	gap: 5px;
	justify-content: flex-end;
	align-items: center;
}
.weekly-ordered-schedule-activity-container-normal-selected {
	background-color: var(--greensoft-theme-color);
}

.weekly-ordered-schedule-activity-single-icon {
	color: var(--green-theme-color);
	font-size: 14px;
	grid-column: 1;
	grid-row: 1;
}
.weekly-ordered-schedule-activity-group-icon {
	color: var(--orange-theme-color);
	font-size: 10px;
	grid-column: 1;
	grid-row: 1;
}
.weekly-ordered-schedule-activity-container-normal-title {
	grid-column: 1;
	grid-row: 2;
	font-size: 12px;
	font-weight: bold;
	font-family: "Roboto";
	text-align: center;
}

.weekly-ordered-schedule-activity-container-normal-comment,
.weekly-ordered-schedule-activity-container-normal-comment p,
.weekly-ordered-schedule-activity-container-normal-comment .ql-bubble {
	grid-column: 1;
	grid-row: 3;
	font-size: 10px;
	font-family: "Poppins";
	text-align: center;
}

.weekly-ordered-schedule-activity-kid-name {
	font-size: 9px;
	font-family: "Poppins";
	text-align: left;
}
.weekly-ordered-schedule-activity-container-small {
	cursor: pointer;
}
.weekly-ordered-schedule-activity-container-normal {
	cursor: pointer;
}

.weekly-ordered-schedule-activity-container-small-title {
	grid-column: 1;
	grid-row: 2;
	font-size: 10px;
	font-weight: bold;
	font-family: "Roboto";
}
.weekly-ordered-schedule-activity-container-small-comment {
	grid-column: 1;
	grid-row: 3;
	font-size: 7px;
	font-family: "Poppins";

	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	max-width: 8ch;
}
