.planned-activities-per-child-per-year-container{
    border-radius: 10px;
    overflow-y: auto;
    overflow-x: hidden;
    padding-left: 10px;
    padding-right: 10px;
}

.planned-activities-per-child-per-year-activities{
    display: grid;
    gap: 5px;
}

.planned-activities-per-child-per-year-btn {
  padding-right: 10px;
  color: var(--pinki-theme-color);
  background: transparent;
  border-style: none;

  font-size: 22px;
  font-weight: bold;
  text-align: end;
  cursor: pointer;

  align-self: flex-end;
  justify-self: flex-end;
  grid-column: 1 / 3;
}
.planned-activities-per-child-per-year-header-details{
    padding-bottom: 10px;
}
.planned-activities-per-child-per-year-header-details-name{
    font-family: "Newsreader";
    font-size: 22px;
    font-weight: bold;
    color: var(--green-theme-color);
}
.planned-activities-per-child-per-year-header-details-year{
    font-family: "Newsreader";
    color: var(--link-color);
    cursor: pointer;
}
.planned-activities-per-child-per-year-header-details-year:hover{
    text-decoration: underline;
}