
.done-component-circle {
  position: relative; /* Add this line to allow absolute positioning within */
  width: 200px;
  height: 200px;
  background-color: transparent;
  border-radius: 50%;
  opacity: 1;
  transition: opacity 0.5s ease-in-out;
}

.done-component-fade-in {
  opacity: 1;
  transition: opacity 0.5s ease-in-out;
}

.done-component-fade-out {
  opacity: 0;
}

.done-component-circle-grid {
  position: relative; 
}

.done-component-logo {
  width: 100%;
  height: 100%;
}

.done-component-check {
  position: absolute; 
  top: 58%; 
  left: 77%;
  transform: translate(-50%, -50%); 
  color: green; 
  font-size: 100px; 
}