.sign-up-step1-close-btn-container {
	z-index: 1001;
	text-align: end;
	padding-right: 10px;
}
.modal-span {
	z-index: 1002;
}
.sign-up-step1-close-btn {
	font-family: "Roboto";
	padding-right: 10px;
	color: var(--pinki-theme-color);
	background: transparent;
	border-style: none;

	font-size: 22px;
	font-weight: bold;

	cursor: pointer;
}
.sign-up-step1-close-btn:hover {
	color: #ffffff;
}
.sign-up-step1-back {
	cursor: pointer;
}
.sign-up-step1-container {
	display: grid;
	grid-template-rows: repeat(12, auto);
	font-family: "Charter";
	background: transparent linear-gradient(180deg, #98b5a4 0%, #4c5b52 100%) 0%
		0% no-repeat padding-box;
	border: 1px solid #707070;
	opacity: 0.95;
	text-align: center;
}
.background-picture {
	background-image: url("../../Assets/HomePicture.png");
	background-size: 100%;
	background-repeat: no-repeat;
	opacity: 3%;
}

.sign-up-step1-text {
	padding-left: 10px;
	padding-right: 10px;
	text-align: left;
	letter-spacing: 1px;
	color: #ffffff;

	font-size: 18px;
	line-height: 24px;
	font-family: "Alegreya";
	font-style: italic;
	font-weight: 400;
}
.sign-up-step1-back {
	cursor: pointer;
	text-align: center;
	font-size: 35px;
	font-weight: bolder;
	letter-spacing: -0.24px;
	color: #ffffff;
}

.sign-up-step1-title-text {
	text-align: center;
	font-family: "Vidaloka";
	font-size: 32px;
	letter-spacing: -0.45px;
	color: #ffffff;
}
.sign-up-step1-form-item {
	display: grid;
	padding-top: 20px;
	padding-left: 20px;
	padding-right: 20px;
	z-index: 1002;
}
.sign-up-step1-label {
	text-align: left;

	font-size: 18px;
	letter-spacing: -0.27px;
	color: #ffffff;
}
.sign-up-step1-input {
	font-size: 18px;
	background: #cbdad1 0% 0% no-repeat padding-box;
	border-radius: 10px;
	padding-left: 5px;
}
.sign-up-step1-already-has-account-container {
	z-index: 1001;
}
.sign-up-step1-already-has-account {
	font-size: 18px;
	text-align: left;
	letter-spacing: -0.27px;
	color: #ffffff;
}

.sign-up-step1-login {
	cursor: pointer;
	font-size: 18px;
	text-align: left;
	letter-spacing: -0.27px;
	color: #ffffff;
	font-weight: bold;
}
.sign-up-step1-login:hover {
	text-decoration: underline;
}
.sign-up-step1-continue-container {
	cursor: pointer;
	padding-top: 15px;
	padding-left: 25px;
	padding-right: 25px;
	z-index: 1002;
}
.sign-up-step1-continue {
	width: auto;
	height: 56px;
	background: #e0e9e3 0% 0% no-repeat padding-box;
	border-radius: 20px;
}
.sign-up-step1-continue-text {
	font-size: 18px;
	font-weight: bold;
	padding-top: 10px;
	letter-spacing: -0.26px;
	color: #000000de;
}
