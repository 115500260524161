.third-party-admin-apply-plan-close-btn {
	font-family: "Roboto";
	padding-right: 10px;
	color: var(--pinki-theme-color);
	background: transparent;
	border-style: none;

	font-size: 22px;
	font-weight: bold;
	text-align: center;
	cursor: pointer;

	align-self: flex-end;
	justify-self: flex-end;
	grid-column: 1 / 3;
}

/* ThirdPartyAdminApplyPlan.css */

/* General styles */
.third-party-admin-apply-plan-container {
	display: flex;
	flex-direction: column;
	background-color: #fff;
	font-family: Arial, sans-serif;
}

.third-party-admin-apply-plan-body {
	margin-top: 20px;
	display: flex;
	flex-direction: column;
	gap: 20px;
}

/* Form controls */
.third-party-admin-apply-plan-target-students,
.third-party-admin-apply-plan-source-students {
	display: flex;
	flex-direction: column;
	gap: 10px;
}

/* Select styles */
.MuiFormControl-root {
	width: 100%;
}

.MuiInputLabel-root {
	color: #666;
}
.MuiInputLabel-outlined {
	background: white;
	padding: 0 5px;
}
.MuiSelect-root {
	border: 1px solid #ddd;
	border-radius: 4px;
	padding: 8px;
	transition: border-color 0.3s ease;
}

.MuiSelect-root:hover {
	border-color: #007bff;
}

.MuiMenuItem-root {
	font-size: 14px;
}

/* Start date section */
.third-party-admin-apply-plan-start-from-date {
	margin: 20px 0;
	font-size: 16px;
	font-weight: bold;
	color: #444;
	text-align: center;
}

/* Button container */
.third-party-admin-apply-plan-btn-container {
	display: flex;
	justify-content: space-between;
	margin-top: 20px;
}

.third-party-admin-apply-plan-btn-container div {
	padding: 10px 20px;
	border-radius: 5px;
	font-size: 16px;
	font-weight: bold;
	cursor: pointer;
	transition: background-color 0.3s ease, color 0.3s ease;
}

.third-party-admin-apply-plan-btn-container div:first-child {
	background-color: #f5f5f5;
	color: #333;
	border: 1px solid #ddd;
}

.third-party-admin-apply-plan-btn-container div:first-child:hover {
	background-color: #e74c3c;
	color: #fff;
	border-color: #e74c3c;
}

.third-party-admin-apply-plan-btn-container div:last-child {
	background-color: #007bff;
	color: #fff;
	border: 1px solid transparent;
}

.third-party-admin-apply-plan-btn-container div:last-child:hover {
	background-color: #0056b3;
	border-color: #0056b3;
}
.third-party-admin-apply-plan-start-from-date {
	margin: 20px 0;
	font-size: 16px;
	font-weight: bold;
	color: #444;
	text-align: center;
	padding: 10px;
	background-color: #f9f9f9;
	border-radius: 8px;
	border: 1px solid #ddd;
	box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
	transition: background-color 0.3s ease, box-shadow 0.3s ease;
	cursor: pointer;
}

.third-party-admin-apply-plan-start-from-date:hover {
	background-color: #e6f7ff;
	box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
	color: #0056b3;
}

/* Responsive design */
@media (max-width: 768px) {
	.third-party-admin-apply-plan-container {
		max-width: 90%;
		padding: 15px;
	}

	.third-party-admin-apply-plan-btn-container div {
		font-size: 14px;
		padding: 8px 16px;
	}
}
