.blog-link-icon {
  position: relative;
}

.blog-link-home-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(auto, 1fr));
  justify-items: start;
  gap: 5px;
  padding-bottom: 35px;
}

.blog-link-home-icon {
  grid-column: 1;
  color: var(--green-theme-color);
  font-weight: bolder;

}
.blog-link-home-text {
  grid-column: 2;
  padding-left: 10px;
  
}

.developer-picture {
  border-radius: 20px;
}
.developer-text {
  padding-left: 15px;
  font: normal normal normal 15px Vidaloka;
  letter-spacing: -0.45px;
}
