.time-picker-pop-up-container {
	text-align: center;
	display: grid;
	border-radius: 10px;
}
.time-picker-pop-ampm {
	font-size: 35px;
}
.time-picker-pop-up-am {
	color: orange;
}
.time-picker-pop-up-pm {
	color: darkblue;
}
.time-picker-pop-up-container-close-btn {
	font-family: "Roboto";
	padding-right: 10px;
	color: var(--pinki-theme-color);
	background: transparent;
	border-style: none;

	font-size: 22px;
	font-weight: bold;
	text-align: center;
	cursor: pointer;

	align-self: flex-end;
	justify-self: flex-end;
}
