.grouped-activities-modal-container {
	display: grid;
	grid-template-columns: repeat(auto-fit, auto);

	padding: 10px;
	background: #ffffff;
	border-style: none;
	border-radius: 30px;

	border-bottom-style: solid;
	border-top-style: solid;
	border-color: var(--green-theme-color);
}

.grouped-activities-modal-close-btn-container {
	font-family: "Roboto";
	background: white;
	position: sticky;
	top: 0;
	height: 40px;
	text-align: center;
}

.grouped-activities-modal-close-btn {
	font-family: "Roboto";
	position: sticky;
	top: 0;

	padding-right: 10px;
	color: var(--pinki-theme-color);
	background: transparent;
	border-style: none;

	font-size: 22px;
	font-weight: bold;
	text-align: end;
	cursor: pointer;

	align-self: flex-end;
	justify-self: flex-end;
}

.grouped-activities-modal-container {
	border-style: none;
	border-color: var(--pinki-theme-color);
}

.grouped-planned-activity-title-container {
	display: grid;
	grid-template-columns: repeat(auto-fit, auto);
	width: 100%;
}

.grouped-planned-activity-title-description {
	font-family: "Roboto";
	font-style: normal;
	font-weight: 700;
	font-size: 24px;
	line-height: 28px;

	color: #000000;
}
.grouped-planned-activity-title-date {
	font-family: "Roboto";
	font-style: normal;
	font-weight: 700;
	font-size: 17px;

	color: #605d5d;
}
.grouped-planned-activity-title-fromto {
	font-family: "Roboto";
	font-style: normal;
	font-weight: 700;
	font-size: 17px;

	color: #605d5d;
}
