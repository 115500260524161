.year-selector-modal-overall-container {
	display: grid;
	text-align: center;
	border-radius: 10px;
}
.year-selector-modal-close-btn {
	font-family: "Roboto";
	padding-right: 10px;
	color: var(--pinki-theme-color);
	background: transparent;
	border-style: none;

	font-size: 22px;
	font-weight: bold;
	text-align: center;
	cursor: pointer;

	align-self: flex-end;
	justify-self: flex-end;
}

.year-selector-modal-title {
	font: normal normal normal 20px/53px Vidaloka;
	letter-spacing: -0.36px;
	font-weight: bolder;
}

.year-selector-modal-container {
	display: grid;
	grid-template-columns: repeat(3, 20px);
	padding: 10px;
	gap: 20px;
	justify-self: center;
}
.year-selector-modal-item-container {
	border-style: solid;
	border-width: 1px;
	border-radius: 20px;
	width: 30px;
	height: 30px;
	text-align: center;
	cursor: pointer;
}
.year-selector-modal-item-text {
	font: normal normal normal 16px/16px Vidaloka;
	letter-spacing: -0.27px;
	padding-top: 4px;
}
.selected-year {
	background-color: var(--green-theme-color);
	border-radius: 20px;
	font: normal normal bold 18px/20px Charter;
	letter-spacing: -0.27px;
	color: #ffffff;
}

@media screen and (max-width: 512px) {
	.year-selector-modal-container {
		display: grid;
		grid-template-columns: repeat(6, 20px);
		padding: 10px;
		gap: 20px;
		justify-self: center;
	}
}
