.weekday-selector-container {
  display: grid;
  grid-template-columns: repeat(7, auto);
  padding-bottom: 20px;
}

.weekday-selector-day {
  height: 20px;
  width: 40px;

  font: normal normal normal 13px/13px Vidaloka;
  letter-spacing: -0.27px;

  border-style: none;
  border-radius: 10px;

  text-align: center;

  padding-top: 3px;
  background-color: var(--greensoft-theme-color);
  grid-row: 1;
  cursor: pointer;
}

.weekday-selector-selected {
  height: 20px;
  width: 50px;

  font: normal normal normal 13px/13px Vidaloka;
  letter-spacing: -0.27px;

  border-style: none;
  border-radius: 10px;

  text-align: center;

  padding-top: 3px;
  background-color: var(--orange-theme-color);

  grid-row: 1;
  cursor: pointer;
}
