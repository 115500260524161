.modal-container-image {
  background-image: url(../../Assets/ModalBackground.jpg);
  background-size: contain;
  background-position: center;
  border-radius: 10px;
  filter: brightness(1.2);
  opacity: 60%;
}

.modal-container-image2 {
  background-image: url(../../Assets/ModalBackground2.jpg);
  background-size: contain;
  background-position: center;
  border-radius: 10px;
  filter: brightness(1.2);
  opacity: 60%;
}
.modal-container-image3 {
  background-image: url(../../Assets/ModalBackground3.jpg);
  background-size: contain;
  background-position: center;
  border-radius: 10px;
  filter: brightness(1.2);
  opacity: 60%;
}
.modal-container-image4 {
  background-image: url(../../Assets/ModalBackground4.jpg);
  background-size: contain;
  background-position: center;
  border-radius: 10px;
  filter: brightness(1.2);
}
