.activity-options-container {
	display: grid;
	grid-template-columns: repeat(auto-fit, auto);

	padding: 10px;
	background: #ffffff;
	border-style: none;
	border-radius: 30px;
	border-bottom-style: solid;
	border-top-style: solid;

	overflow-y: auto;
}
.activity-options-close-btn {
	font-family: "Roboto";
	padding-right: 10px;
	color: var(--pinki-theme-color);
	background: transparent;
	border-style: none;

	font-size: 22px;
	font-weight: bold;
	text-align: center;
	cursor: pointer;

	align-self: flex-end;
	justify-self: flex-end;
}
.activity-options-item-title {
	font-weight: bold;
	padding-bottom: 5px;
}

.activity-options-item {
	font-family: sans-serif;
	cursor: pointer;
}
.activity-options-item:hover {
	font-weight: bold;
	color: blue;
	text-decoration: underline;
}
