.product-home-container {
  font-family: "Roboto";
}
.product-home-title {
  position: sticky;
  top: 0;
  font: normal normal normal 20px Vidaloka;
  letter-spacing: -0.36px;
  font-weight: bolder;
  padding-bottom: 10px;
  background-color: var(--white-smoke-home);
}

.product-home-list {
  display: grid;
  grid-template-columns: repeat(100%, auto);
  gap: 10px;
}
@media screen and (max-width: 639px) {
  .product-home-title {
    position: relative;
  }
}
