.calendar-options-container {
	padding: 10px;
}
.calendar-options-body {
	display: flex;
	flex-wrap: wrap;
	gap: 10px;
	padding: 10px;
	justify-content: flex-start;
	align-items: flex-start;
}
.calendar-options-close-btn {
	font-family: "Roboto";
	padding-right: 10px;
	color: var(--pinki-theme-color);
	background: transparent;
	border-style: none;

	font-size: 22px;
	font-weight: bold;
	text-align: center;
	cursor: pointer;

	align-self: flex-end;
	justify-self: flex-end;
	grid-column: 1 / 3;
}

.calendar-options-body .base-button-text {
	display: flex;
	width: 100%;
	align-items: center;
	justify-content: center;
	gap: 5px;
	padding: 10px;
	background-color: #fff;
	border: 1px solid #ccc;
	border-radius: 4px;
	cursor: pointer;
	transition: background-color 0.3s ease, box-shadow 0.3s ease;
	text-decoration: none;
	color: #333;
}

.calendar-options-body .base-button-text:hover {
	background-color: #eaeaea;
	box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
}

.calendar-options-body .base-button-text svg {
	font-size: 1.2rem;
	color: #666;
}
