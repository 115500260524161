.home-page-main {
	background-color: blue;
	height: 100%;
}
.home-page-container {
	display: grid;
	grid-template-columns: repeat(12, 1fr);
	gap: 3px;
	background-color: var(--white-smoke-home);
}

.home-page-middle-container {
	display: grid;
	grid-template-columns: repeat(12, 1fr);
	grid-template-rows: repeat(12, 1fr);
	height: 100vh;
	justify-items: center;
	gap: 3px;
}

.home-page-sidebar {
	grid-column: 1 / 3;
}

.home-page-middle-container {
	grid-column: 3 / 10;
}
.home-page-calendar-sidebar {
	grid-column: 10 / 13;
}

.home-page-students {
	grid-row: 1 / 6;
	grid-column: 1 / 9;

	width: 100%;
}
.home-page-activities {
	grid-row: 1 / 6;
	grid-column: 9 / 13;

	width: 100%;
	overflow: auto;
}
.home-page-products {
	padding-top: 12px;
	grid-row: 6 / 12;
	grid-column: 1 / 13;

	width: 100%;
}

.section-divider {
	grid-row: 6;
	grid-column: 1 / 13;
	height: 1px;
	background-color: #e0e0e0; /* Light gray */
	border-radius: 10px;
	box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05); /* Softer shadow */
	margin: 10px 0;
	width: 94%;
}
@media screen and (max-width: 639px) {
	.section-divider {
		display: none;
	}
	.home-page-middle-container {
		display: inline;
	}

	.home-page-students {
	}
	.home-page-activities {
		padding-top: 20px;
		padding-left: 25px;
		padding-bottom: 25px;
	}
}

@media screen and (max-width: 1211px) {
	.home-page-sidebar {
		display: none;
	}
	.home-page-calendar-sidebar {
		display: none;
	}

	.home-page-middle-container {
		grid-column: 1 / 13;
	}
}
