.plan-per-day-card-container {
  display: grid;
  gap: 10px;

  border-style: none;
  border-radius: 5px;

  border-bottom-style: solid;

  border-color: rgb(225, 219, 219);

  padding-top: 3px;
  padding-left: 10px;
  padding-bottom: 3px;
}
.plan-per-day-card-day-container {
  grid-row: 1;
  text-align: center;

  display: flex;
  gap: 3px;
  justify-content: center;
  align-items: center;
}

.plan-per-day-card-day-content-container {
  grid-row: 2;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(290px, 1fr));
  gap: 10px;
  text-align: start;
}

.plan-per-day-card-day-date-number {
  border-style: solid;
  border-width: 1px;
  border-radius: 20px;
  width: 36px;
  height: 36px;

  background-color: var(--orange-theme-color);
  color: #ffffff;

  padding-top: 4px;
}
.plan-per-day-card-day-date-number-text {
  font: normal normal normal 16px/16px Vidaloka;
  letter-spacing: -0.27px;
}
.plan-per-day-card-day-date-text {
  font: normal normal normal 16px/16px Vidaloka;
  letter-spacing: -0.27px;
  padding-top: 8px;

  grid-row: 2;
}
.plan-per-day-report-content-card-description {
  background-color: var(--green-theme-color);
  color: #ffffff;
  border-width: 1px;
  border-radius: 5px;
  width: 300px;

  padding: 7px;
}
.plan-per-day-report-content-card-description-title {
  font: normal normal normal 20px/16px Vidaloka;
  letter-spacing: -0.27px;
  font-weight: bold;
  color: rgb(47, 47, 47);
}
.plan-per-day-report-content-card-description-description {
  font: normal normal normal 15px/16px Vidaloka;
  letter-spacing: -0.27px;
  color: rgb(58, 58, 58);
}

.plan-per-day-report-content-card-description-children {
  font: normal normal normal 16px/16px Vidaloka;
  letter-spacing: -0.27px;
}
