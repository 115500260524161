.edit-planned-activity-multiple-container {
	display: grid;
	gap: 3px;

	background: #ffffff;
	border-style: none;
	border-radius: 20px;

	overflow: auto;
	padding: 20px;
	overflow: auto;
}
.edit-planned-activity-multiple-btns-container {
	display: flex;
	flex-wrap: wrap;
	gap: 7px;
	justify-content: flex-start;
	align-items: flex-start;
}

.edit-planned-activity-multiple-btns-delete {
	background-color: var(--pinki-theme-color);
	border-radius: 10px;
	justify-self: end;
	color: white;
	border: none;
	cursor: pointer;
	text-align: center;
	justify-self: center;
	padding: 10px;
	width: 100%;
}
.edit-planned-activity-multiple-btns-save {
	background-color: var(--greendark-theme-color);
	border-radius: 10px;
	justify-self: end;
	color: white;
	border: none;
	cursor: pointer;
	text-align: center;
	justify-self: center;
	justify-self: center;
	padding: 10px;
	width: 100%;
}
.edit-planned-activity-multiple-comment {
	z-index: inherit;
	height: auto;
}
.edit-planned-activity-comment-input {
	z-index: inherit;
	height: 150px;
	overflow-y: auto;
}

.edit-planned-activity-multiple-btns-save-container {
	width: 100%;
	display: flex;
	flex-wrap: wrap;
	gap: 7px;
	justify-content: center;
}

.edit-planned-activity-multiple-btns-savebtn {
	border-radius: 10px;
	background-color: var(--greensoft-theme-color);
	padding: 10px;
	cursor: pointer;
}
