.landing-container {
	display: grid;
	grid-template-columns: repeat(12, 1fr);
	gap: 3px;
}

.landing-left-container {
	display: grid;
	grid-template-rows: repeat(12, auto);

	background: transparent linear-gradient(180deg, #98b5a4 0%, #4c5b52 100%) 0%
		0% no-repeat padding-box;
	border: 1px solid #707070;
	opacity: 0.95;

	grid-column: 1 / 5;

	border-radius: 10px;
}

.landing-left-text-title {
	padding-left: 10px;

	text-align: center;
	letter-spacing: 1px;
	color: #ffffff;

	font-size: 30px;
	line-height: 33px;
	font-family: "Alegreya";
	font-style: normal;
	font-weight: 400;
}
.landing-left-text-paragraph {
	padding-left: 10px;
	text-align: left;
	letter-spacing: 1px;
	color: #ffffff;

	font-size: 18px;
	line-height: 24px;
	font-family: "Alegreya";
	font-style: italic;
	font-weight: 400;
}

.landing-left-background-picture {
	grid-column: 1 / 12;
	grid-row: 1 / 12;
	justify-self: center;
	z-index: -1;
}

.landing-left-text {
	grid-column: 1 / 12;
	grid-row: 4;
	padding-left: 25px;
	padding-right: 25px;
}

.landing-left-background-picture {
	opacity: 10%;
}
#home-pictures-landing {
	margin-top: 10%;
	width: 100%;
	height: auto;
}

.landing-left-menu {
	grid-column: 1 / 12;
	grid-row: 6;

	font: normal normal bold 17px/45px Charter;
	border-radius: 20px;
	padding-left: 15px;
	padding-right: 15px;
}
.landing-left-menu-btn {
	cursor: pointer;
	width: 100%;
	height: 66px;
	background: #e0e9e3 0% 0% no-repeat padding-box;
	text-align: center;
	border-radius: 20px;
}
.landing-left-menu-btn:hover {
	border-radius: 20px;
}
.landing-left-menu-btn-text {
	padding-top: 10px;
}

.landing-right-container {
	grid-column: 5 / 12;
	justify-self: center;

	display: inline-block;
	text-align: center;
}
.landing-right-logo {
	text-align: center;
}
.landing-right-brand-logo {
	height: 155px;
}
.landing-right-welcome {
	text-align: center;
	font: normal normal normal 33px/34px Vidaloka;
	letter-spacing: -0.8px;
	color: #000000de;
	padding-bottom: 10px;
}
.landing-right-google-signin {
	text-align: center;
	background: #e0e9e3 0% 0% no-repeat padding-box;
	border-radius: 20px;
}
.landing-right-login-text-container {
	text-align: center;
	padding-top: 10px;
	padding-bottom: 10px;
	display: flex;
	align-items: center;
	gap: 5px;
}
.landing-right-login-text {
	text-align: left;
	font: normal normal normal 23px/61px Charter;
	letter-spacing: -0.34px;
	color: #98b5a4;
	opacity: 0.5;
}
.landing-right-signin-username-container {
	padding-bottom: 10px;
}
.landing-right-google-login-btn {
	display: flex;
	justify-content: center;
	align-items: center;
}
.landing-right-signin-text {
	text-align: left;
	font: normal normal bold 17px/45px Charter;
	letter-spacing: -0.26px;
	color: #000000de;
	opacity: 1;
}
.landing-right-signin-input {
	width: 446px;
	height: 37px;
	border: 1px solid #91b7a3;
	border-radius: 20px;
	opacity: 1;
}

.landing-right-signin-forgot-pwd {
	cursor: pointer;
	text-align: right;
	font: normal normal bold 17px/45px Charter;
	letter-spacing: -0.26px;
	color: #f27475;
	padding-bottom: 10px;
}
.landing-right-signin-forgot-pwd:hover {
	text-decoration: underline;
}
.landing-right-login-btn {
	cursor: pointer;
	width: 446px;
	height: 57px;
	background: #98b5a4 0% 0% no-repeat padding-box;
	border-radius: 20px;
}
.landing-right-login-btn:hover {
	/* background: #98b5a4; */
}

.landing-right-login-btn-text {
	text-align: center;
	font: normal normal bold 17px/45px Charter;
	letter-spacing: -0.26px;
	color: #ffffff;
	padding-top: 10px;
}
.landing-right-login-btn-text:hover {
	/* color: #ffffff; */
}
.landing-right-dont-have-account-text {
	color: var(--unnamed-color-191919);
	text-align: left;
	font: normal normal bold 17px/45px Charter;
	letter-spacing: -0.26px;
	color: #191919;
}
.landing-right-dont-have-account-signup {
	cursor: pointer;
	text-align: left;
	font: normal normal bold 17px/45px Charter;
	letter-spacing: -0.26px;
	color: #f27475;
}
.landing-right-dont-have-account-signup:hover {
	text-decoration: underline;
}

@media screen and (max-width: 850px) {
	.landing-left-container {
		grid-row: 2;
		grid-column: 1 / 12;
	}
	.landing-right-container {
		grid-row: 1;
		grid-column: 1 / 12;
	}
}

@media screen and (max-width: 513px) {
	.landing-right-container {
		width: 100%;
	}
	.landing-right-signin-input,
	.landing-right-login-btn {
		width: 100%;
	}
}
