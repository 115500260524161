.home-page-sidebar-container {
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(auto, 1fr));
	grid-template-rows: repeat(12, 1fr);
	justify-items: start;

	height: 100%;
	background-color: white;
	padding-top: 10px;
	font: normal normal normal 18px Charter;
	letter-spacing: -0.34px;
	border-radius: 15px;
}
.home-page-sidebar-container-year-plan {
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(auto, 1fr));
	grid-template-rows: repeat(12, 1fr);
	justify-items: start;

	height: 100%;
	background-color: white;
	padding-top: 10px;
	font: normal normal normal 18px Charter;
	letter-spacing: -0.34px;
	border-radius: 15px;
}
.home-page-sidebar-upper {
	grid-row: 1 / 9;
}
.home-page-sidebar-lower {
	display: grid;
	gap: 10px;
	grid-row: 11 / 12;
	align-self: flex-end;
}
.home-page-sidebar-lower-year-plan {
	grid-row: 12;
	align-self: flex-end;
}
.home-page-sidebar-item {
	display: grid;
	grid-template-columns: repeat(auto-fill, auto);
	justify-content: start;

	gap: 5px;
	padding-bottom: 10px;
	padding-left: 10px;
	cursor: pointer;
}
.home-page-sidebar-item:hover {
	text-decoration: underline;
	color: var(--green-theme-color);
}

.home-page-sidebar-item-icon {
	grid-column: 1;
	grid-row: 1;
	color: var(--green-theme-color);
	font-weight: bolder;
	font-size: 36px;
}

.home-page-sidebar-item-text {
	grid-column: 2;
	grid-row: 1;
	padding-left: 10px;

	max-width: 150px;

	align-self: center;
}

@media screen and (max-width: 1207px) {
	.home-page-sidebar-item-text {
		max-width: 100px;
	}
}
