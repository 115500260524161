.update-skip-week-container {
	display: grid;
	grid-template-rows: auto auto auto 1fr;
	grid-template-columns: 1fr;
	gap: 10px;
	background-color: #fff;
	padding: 20px;
	border-radius: 8px;
	box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
	max-width: 400px;
	margin: auto;
	position: relative;
}

.update-skip-week-close-btn {
	font-family: "Roboto";
	grid-row: 1;
	justify-self: end;
	cursor: pointer;
	font-size: 1.5rem;
	color: var(--pinki-theme-color);
	font-weight: bold;
}

.update-skip-week-row {
	display: grid;
	grid-template-columns: 1fr 2fr;
	align-items: center;
	gap: 8px;
}

.update-skip-week-label {
	font-size: 1rem;
	color: #555;
}

.update-skip-week-input-container {
	display: flex;
	flex-direction: column;
	width: 300px;
}

.update-skip-week-input {
	padding: 8px;
	font-size: 15px;
	border: 1px solid #ddd;
	border-radius: 4px;
	transition: border-color 0.2s;
}

.update-skip-week-input:focus {
	border-color: #007bff;
	outline: none;
}

.update-skip-week-btn-container {
	display: flex;
	justify-content: center;
	margin-top: 20px;
}

.update-skip-week-btn-send {
	background-color: var(--greendark-theme-color);
	color: #fff;
	padding: 10px 20px;
	border-radius: 4px;
	cursor: pointer;
	text-align: center;
	transition: background-color 0.3s;
	font-size: 15px;
}
