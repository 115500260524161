.membership-card-benefit-container{
  display: grid;
  gap: 7px;
}
.membership-card-benefit-item-container{
  display: flex;
  gap: 7px;
}

.membership-card {
    
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 15px;
    margin-bottom: 20px;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    cursor: pointer;
    background-color: white;

}

.membership-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}
.membership-card-benefit-title{
    font-weight: bold;

}
.membership-card-icon-month{
    color: #c0c0c0;
    font-size: 55px;
    font-weight: bolder;
}
.membership-card-icon-year{
    color: #ffd700;
    font-size: 55px;
    font-weight: bolder;
}
.membership-card-benefit-title-check-mark{
    color: green;
}
.membership-card-description{
    color: grey;
}
