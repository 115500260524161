.yes-no-container {
	display: grid;
	grid-template-columns: repeat(auto-fill, minmax(auto, 1fr));
	gap: 1px;
	grid-auto-flow: dense;
	justify-items: center;
	border-radius: 10px;
}

.confirm-message {
	/* font: normal normal bold 18px/18px Charter; */

	color: #000000;

	grid-column: 1 / 4;
	grid-row: 1;
}

.yes-no-cancel {
	height: 23px;
	left: 12px;
	right: 188px;
	width: 80px;
	bottom: 27px;

	color: #ffffff;
	background: #c66868;
	border-style: none;
	border-radius: 10px;
	border-width: thin;
	font-size: 12px;
	letter-spacing: 1px;

	cursor: pointer;

	grid-column: 1;
	grid-row: 3;
	align-self: flex-end;
	justify-self: start;

	justify-self: start;
	grid-column: 1;
	grid-row: 2;
}

.yes-no-continue {
	height: 23px;
	left: 12px;
	right: 188px;
	width: 80px;
	bottom: 27px;

	color: #ffffff;
	background: var(--greendark-theme-color);
	border-style: none;
	border-radius: 10px;
	border-width: thin;
	font-size: 12px;
	letter-spacing: 1px;

	cursor: pointer;

	grid-column: 1;
	grid-row: 3;
	align-self: flex-end;
	justify-self: start;

	justify-self: end;
	grid-column: 3;
	grid-row: 2;
}
