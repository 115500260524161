.user-profile-modal-container {
	display: grid;
	gap: 10px;
	padding-left: 20px;
	padding-right: 20px;
	overflow-y: auto;
	overflow-x: hidden;
}

.user-profile-modal-close-btn {
	font-family: "Roboto";
	padding-right: 10px;
	color: var(--pinki-theme-color);
	background: transparent;
	border-style: none;

	font-size: 22px;
	font-weight: bold;
	text-align: center;
	cursor: pointer;

	align-self: flex-end;
	justify-self: flex-end;
	grid-column: 1 / 3;
}

.user-profile-modal-impersonator-btn-container {
	text-align: center;
	grid-column: 1 / 3;
}
.user-profile-modal-impersonator-btn {
	background-color: #007bff;
	color: #fff;
	border-radius: 4px;
	cursor: pointer;
	text-align: center;
	transition: background-color 0.3s;
	height: 25px;
	font-size: 15px;
}

.user-profile-modal-impersonator-btn:hover {
	background-color: #0056b3;
}
.user-profile-modal-title {
	text-align: center;
	font: normal normal normal 30px/30px Vidaloka;
	letter-spacing: -0.45px;
	color: #191919;
	grid-column: 1 / 3;
}
.user-profile-modal-picture-container {
	display: grid;
	text-align: center;
	grid-column: 1 / 3;
}
.user-profile-modal-picrure-text {
	font: normal normal bold 16px/14px Charter;
	letter-spacing: -0.24px;
	color: #191919;
}
.user-profile-modal-picrure-inner-text {
	cursor: pointer;
}
.user-profile-modal-picrure-inner-text:hover {
	text-decoration: underline;
}
.user-profile-modal-form-container {
	display: grid;
	gap: 10px;
	grid-column: 1 / 3;
	padding-bottom: 10px;
}
.user-profile-modal-form-row {
	display: grid;
	gap: 10px;
	grid-template-columns: repeat(auto-fit, minmax(auto, 1fr));
}
.user-profile-modal-form-label {
	justify-self: start;
	grid-column: 1;
	text-align: left;
	font: normal normal bold 16px/14px Charter;
	letter-spacing: -0.24px;
	color: #191919;
	padding-top: 10px;
}
.user-profile-modal-form-input {
	grid-column: 2;
	background: #e0e9e3 0% 0% no-repeat padding-box;
	border-radius: 10px;
	justify-self: end;
	padding-left: 10px;
	width: 270px;
	font: normal normal bold 16px Charter;
	letter-spacing: -0.24px;
	color: rgb(98, 97, 97);
	border: none;
}
.user-profile-modal-form-input-btn-container {
	padding-top: 3px;
	grid-column: 2;
	border-radius: 10px;
	width: 270px;
	font: normal normal bold 16px Charter;
	letter-spacing: -0.24px;
	color: rgb(98, 97, 97);
	border: none;
	text-align: start;
}
.user-profile-modal-form-input-country {
	padding-top: 7px;
	cursor: pointer;
}

.user-profile-modal-form-input-btn {
	background-color: var(--greendark-theme-color);
	border-radius: 10px;
	justify-self: start;
	width: 70px;
	font: normal normal bold 16px Charter;
	letter-spacing: -0.24px;
	color: white;
	border: none;
	cursor: pointer;
	text-align: center;
}

.user-profile-modal-form-delete-btn-container {
	padding-top: 3px;
	grid-column: 2;
	border-radius: 10px;
	width: 100%;
	border: none;
	text-align: start;
}

.user-profile-modal-form-delete-btn {
	background-color: #d11a2a;
	border-radius: 10px;
	justify-self: end;
	width: 100px;
	color: white;
	border: none;
	cursor: pointer;
	text-align: center;
	letter-spacing: 2px;
	padding: 3px;
}
.user-profile-modal-back-btn {
	height: 26px;
	border: 1px solid #ff686c;
	border-radius: 10px;
	color: #f27475;
	text-align: center;
	grid-column: 1;
	cursor: pointer;

	padding-top: 3px;
	font: normal normal 15px Charter;
	letter-spacing: -0.21px;
}
.user-profile-modal-save-btn {
	height: 26px;
	background: var(--greendark-theme-color);
	border-radius: 10px;
	color: white;
	text-align: center;
	grid-column: 2;
	cursor: pointer;

	padding-top: 3px;
	font: normal normal 15px Charter;
	letter-spacing: -0.21px;
}
