.customer-suport-sidebar-icon {
	position: relative;
}
.customer-suport-sidebar-text {
	grid-column: 2;
	padding-left: 10px;

	align-self: center;
}

.customer-support-icon {
	position: relative;
}

.customer-support-container {
	display: grid;
	grid-template-columns: repeat(auto-fit, auto);

	padding: 10px;
	background: #ffffff;
	border-style: none;
	border-radius: 30px;

	overflow-y: auto;

	font-family: "Poppins";
	font-size: 17px;
}

.customer-support-text {
	align-self: center;
	justify-self: center;
	width: 100%;
}
.customer-support-text-area {
	resize: none;
	padding-left: 7px;
	border-radius: 7px;
	width: 100%;
}
.customer-support-title {
	font-weight: 600;
	padding-bottom: 5px;
	width: 100%;
}
.customer-support-close-btn {
	font-family: "Roboto";
	padding-right: 10px;
	color: var(--pinki-theme-color);
	background: transparent;
	border-style: none;

	font-size: 22px;
	font-weight: bold;
	text-align: center;
	cursor: pointer;

	align-self: flex-start;
	justify-self: flex-end;
}

.customer-support-send-btn {
	background-color: var(--green-theme-color);

	height: 23px;
	width: 70px;

	color: #ffffff;
	border-style: none;
	border-radius: 10px;
	border-width: thin;

	font-size: small;
	text-align: center;
	cursor: pointer;

	align-self: center;
	justify-self: center;
}

.customer-suport-sidebar-icon-container {
	display: grid;
	grid-template-columns: repeat(auto-fill, auto);
	justify-content: start;

	gap: 5px;

	cursor: pointer;
}
.customer-suport-sidebar-icon-container:hover {
	color: var(--green-theme-color);
}

.customer-suport-sidebar-icon-container-icon {
	grid-column: 1;
	grid-row: 1;
	color: var(--green-theme-color);
	font-weight: bolder;
	font-size: 36px;
}

.customer-suport-sidebar-icon-container-text {
	grid-column: 2;
	grid-row: 1;
	padding-left: 10px;

	align-self: center;
}
