.plan-activity-from-schedule-container {
	display: grid;
	gap: 7px;
	padding-left: 35px;
	padding-right: 20px;
	overflow-y: auto;
	overflow-x: hidden;
	border-radius: 10px;
}
.plan-activity-from-schedule-close-btn {
	font-family: "Roboto";
	padding-right: 10px;
	color: var(--pinki-theme-color);
	background: transparent;
	border-style: none;

	font-size: 22px;
	font-weight: bold;
	text-align: center;
	cursor: pointer;

	align-self: flex-end;
	justify-self: flex-end;
	padding-bottom: 20px;
}

.plan-activity-from-schedule-activities-selected {
	font: normal normal normal 18px/18px Vidaloka;
	letter-spacing: -0.36px;
	padding-bottom: 10px;
	grid-column: 1;
	grid-row: 2;
	color: rgb(102, 101, 101);
}

.plan-activity-from-schedule-plan-btn-container {
	padding-top: 20px;
	display: grid;
	gap: 5px;
}

.plan-activity-from-schedule-cancel-btn {
	height: 26px;
	border: 1px solid #ff686c;
	border-radius: 10px;
	color: #f27475;
	text-align: center;
	grid-column: 1;
	cursor: pointer;

	padding-top: 3px;
	font: normal normal 15px Charter;
	letter-spacing: -0.21px;
}

.plan-activity-from-schedule-plan-btn {
	height: 26px;
	background: var(--greendark-theme-color);
	border-radius: 10px;
	color: white;
	text-align: center;
	grid-column: 2;
	cursor: pointer;

	padding-top: 3px;
	font: normal normal 15px Charter;
	letter-spacing: -0.21px;
}

.plan-activity-from-schedule-children-btn {
	background-color: var(--greendark-theme-color);
	border-radius: 10px;
	justify-self: end;
	width: 70px;
	font: normal normal bold 26px Charter;
	letter-spacing: -0.24px;
	color: white;
	border: none;
	cursor: pointer;
	text-align: center;
}

.plan-activity-from-schedule-activity-btn {
	background-color: var(--greendark-theme-color);
	border-radius: 10px;
	justify-self: end;
	width: 70px;
	font: normal normal bold 26px Charter;
	letter-spacing: -0.24px;
	color: white;
	border: none;
	cursor: pointer;
	text-align: center;
}

.plan-activity-from-schedule-form-row {
	display: grid;
	gap: 7px;
	grid-template-columns: repeat(auto-fit, minmax(auto, 1fr));
	height: 30px;
}
.plan-activity-from-schedule-selected-activity {
	grid-column: 1 / 2;
}
.plan-activity-from-schedule-form-label {
	grid-row: 1;
	grid-column: 1;

	justify-self: start;
	grid-column: 1;
	text-align: left;
	font: normal normal bold 16px/14px Charter;
	letter-spacing: -0.24px;
	color: #191919;
	padding-top: 10px;
	align-self: center;
}
.plan-activity-from-schedule-form-label-input {
	grid-row: 1;
	grid-column: 2;
	align-self: flex-end;
}
