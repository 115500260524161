.automated-planning-container {
	display: grid;
	gap: 5px;

	padding-left: 35px;
	padding-right: 20px;
	/* padding-top: 3px; */
	padding-bottom: 5px;

	border-radius: 10px;
}
.automated-planning-children-btn {
	background-color: var(--greendark-theme-color);
	border-radius: 10px;
	justify-self: start;
	width: 70px;
	font: normal normal bold 26px Charter;
	letter-spacing: -0.24px;
	color: white;
	border: none;
	cursor: pointer;
	text-align: center;
}
.automated-planning-close-btn {
	font-family: "Roboto";
	color: var(--pinki-theme-color);
	background: transparent;
	border-style: none;

	font-size: 22px;
	font-weight: bold;
	text-align: center;
	cursor: pointer;

	align-self: flex-end;
	justify-self: flex-end;
}
.automated-planning-from-date {
	display: flex;
	gap: 5px;
}
.automated-planning-weeks-select {
	display: flex;
	gap: 5px;
}
table {
	width: 100%;
	border-collapse: collapse;
}

thead th {
	position: sticky;
	top: 0;
	background-color: white;
	z-index: 1;
	text-align: center;
	vertical-align: middle;
	padding: 1px;
	border-bottom: 2px solid var(--green-theme-color);
}
table td {
	text-align: center;
	vertical-align: middle;
}
table td:first-child {
	text-align: left;
}

.automated-planning-save-btn {
	height: 26px;
	background: var(--greendark-theme-color) 0% 0% no-repeat padding-box;
	border-radius: 10px;
	color: white;
	text-align: center;
	cursor: pointer;

	padding-top: 3px;
	font: normal normal 15px Charter;
	letter-spacing: -0.21px;
}
.automated-planning-save-btn.disabled {
	pointer-events: none;
	opacity: 0.5;
}
.automated-planning-clear-btn-container {
	width: 100%;
	text-align: end;
}

.automated-planning-clear-btn {
	height: 26px;
	background: #98b5a4 0% 0% no-repeat padding-box;
	border-radius: 10px;
	color: white;
	text-align: center;
	cursor: pointer;

	padding-top: 3px;
	font: normal normal 15px Charter;
	letter-spacing: -0.21px;
	width: auto;
	align-self: flex-end;
	padding: 5px;
}
.automated-planning-input-text {
	font-size: 15px;
}
.automated-planning-date-input {
	cursor: pointer;
}
.automated-planning-weeks-select select {
	cursor: pointer;
	background-color: var(--greendark-theme-color);
	font-size: 15px;
}
table tr td input {
	cursor: pointer;
}

.automated-planning-date-input {
	cursor: pointer;
	padding: 5px 10px;
	border: 1px solid var(--green-theme-color);
	border-radius: 5px;
	background-color: #f9f9f9;
	color: #333;
	font-family: "Roboto", sans-serif;
	transition: background-color 0.3s, border-color 0.3s;
}

.automated-planning-date-input:hover {
	background-color: var(--green-theme-color);
	color: white;
}

.automated-planning-weeks-select {
	display: flex;
	gap: 5px;
	align-items: center;
}

.automated-planning-weeks-select select {
	padding: 5px;
	border-radius: 5px;
	border: 1px solid var(--green-theme-color);
	background-color: #fff;
	color: #333;
	font-family: "Roboto", sans-serif;
	cursor: pointer;
	outline: none;
	transition: background-color 0.3s, border-color 0.3s;
}

.automated-planning-weeks-select select:hover {
	background-color: var(--green-theme-color);
	color: white;
}
