.weekly-schedule-calendar-container {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  gap: 3px;
  background-color: var(--white-smoke-home);
}
.weekly-schedule-calendar-sidebar {
  grid-column: 1 / 3;
}
.weekly-schedule-calendar-calendar {
  grid-column: 3 / 12;
  padding-left: 25px;
}
.weekly-schedule-calendar-calendar-print {
  padding-left: 25px;
  grid-column: 1 / 13;
}

@media screen and (max-width: 1211px) {
  .weekly-schedule-calendar-sidebar {
    display: none;
  }
  .weekly-schedule-calendar-calendar {
    grid-column: 1 / 13;
  }
}
