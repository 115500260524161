.calendar-period-weeks-container {
	display: grid;
	grid-template-columns: repeat(auto-fill, minmax(70px, 1fr));
	gap: 10px;
	padding: 10px;
}

.calendar-period-weeks-card {
	background-color: var(--greensoft-theme-color);
	border: 1px solid #ccc;
	border-radius: 5px;
	padding: 10px;
	box-sizing: border-box;
	text-align: center;
	cursor: pointer;
	transition: transform 0.2s;
	width: 100%;
}
.calendar-period-weeks-skip-card {
	background-color: var(--grey-text-color);
	border: 1px solid #ccc;
	border-radius: 5px;
	padding: 10px;
	box-sizing: border-box;
	text-align: center;
	cursor: pointer;
	transition: transform 0.2s;
	width: 100%;
}

.calendar-period-weeks-card:hover {
	transform: scale(1.05);
}
.calendar-period-weeks-remarks {
	color: gray;
}
.calendar-periods-skiped-week-container {
	display: grid;
	width: 100%;
	text-align: center;
	color: var(--greensoft-theme-color);
}
.calendar-periods-skiped-week-undo {
	grid-row: 1;
}
.calendar-periods-skiped-week-edit {
	grid-row: 1;
}
