.cart-item-card-container-outter{
    padding-top: 10px;
}
.cart-item-card-container{
    display: grid;
    gap: 5px;
    grid-template-columns: repeat(auto, 1fr);

    padding-top: 10px;
    padding-right: 10px;
    padding-left:  10px;
    background-color: var(--greensoft-theme-color);
    border-radius: 15px;

}
.cart-item-card-product-picture{
    grid-column: 1;
    grid-row: 1/3;
    align-self:center;
    padding-left: 3px;
    
}
.cart-item-card-body{
    grid-column: 2;
    grid-row: 1;

}
.cart-item-card-footer{
    grid-column: 1/3;
    grid-row: 3;    
    text-align: end;
}
.cart-item-card-footer-delete-btn{
    text-align: end;
    cursor: pointer;
    color: var(--pinki-theme-color); ;
}