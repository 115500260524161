.profile-picture-uploader-container {
  display: grid;
  align-items: center;
  text-align: center;
}
.profile-picture-uploader-image-preview,
.profile-picture-uploader-image-profile {
  text-align: center;
  border-radius: 30px;
}
.profile-picture-overlay-text {
  position: absolute;
  top: 93%; 
  left: 50%; 
  transform: translate(-50%, -50%); 
  color: white; 
  font-size: 20px; 
  z-index: 10; 
  opacity: 95%;
}

.profile-picture-uploader-input-container {
  display: grid;
  padding-bottom: 10px;
  color: transparent;
}
.profile-picture-uploader-img {
  cursor: pointer;
  position: relative;
}

.profile-picture-uploader-input {
  width: 0%;
  font-size: small;
  grid-column: 1;
  grid-row: 1;
  font-size: 0px;
}
.profile-picture-uploader-upload-btn {
  grid-column: 2;
  grid-row: 1;
  cursor: pointer;
  font-size: 22px;
  color: orange;
}
.profile-picture-uploader-upload-btn:hover {
  color: green;
}
.profile-picture-image-camera-container {
}
.profile-picture-image-camera {
  font-size: 45px;
  color: grey;
}
