.activities-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(279px, 1fr));
  gap: 20px;
  grid-auto-flow: dense;

  justify-items: center;
}
.activities-container-datatable {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(279px, 1fr));
  gap: 20px;
  grid-auto-flow: dense;

  justify-items: center;
}

.activity-card {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  justify-self: center;
  height: 130px;
  width: 279px;
  padding: 10px;
  background: #ffffff;
  border-radius: 30px;
  border-bottom-style: solid;
  border-top-style: solid;
  border-color: var(--green-theme-color);
}

.change-view-btn {
  cursor: pointer;
  font-size: 40px;
  color: var(--green-theme-color);
}
.activities-datatable {
  border-radius: 20px;
}
.activity-title {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 28px;
  display: flex;
  align-items: center;

  color: #000000;

  grid-column: 1 / 3;
  grid-row: 2;
}

.activity-label {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 17px;

  color: #000000;
}
.activity-subject {
  grid-column: 1;
  grid-row: 5;
}
.subject-content {
  text-indent: 10px;
  grid-column: 2;
  grid-row: 5;
}
.activity-frequency {
  grid-column: 1;
  grid-row: 6;
}
.frequency-content {
  text-indent: 10px;
  grid-column: 2;
  grid-row: 6;
}
.activity-edit-btn {
  height: 23px;
  left: 12px;
  right: 188px;
  width: 70px;
  bottom: 27px;

  color: #1762a7;

  cursor: pointer;

  grid-column: 1;
  grid-row: 8;
}

.activities-filters-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 20px;
  grid-auto-flow: dense;
  justify-items: center;

  text-align: center;
  width: 100%;
  bottom: 5%;
  padding-bottom: 20px;

  color: #d4d4d4;
}

.filter-input {
  font-size: 16px;
  border: none;

  border-radius: 20px;
  border-bottom: 1px;
  border-bottom-style: solid;
  border-bottom-color: var(--green-theme-color);
}

.add-button-btn {
  cursor: pointer;
  font-size: 40px;
  color: var(--orange-theme-color);
}
.add-button-btn-green {
  cursor: pointer;
  font-size: 30px;
  color: var(--green-theme-color);
  align-self: center;
}
.add-button-btn-blue {
  cursor: pointer;
  font-size: 30px;
  color: var(--bluemid-theme-color);
  align-self: center;
}

@media only screen and (max-width: 700px) {
  .change-view-btn {
    display: none;
  }
}
