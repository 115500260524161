.cart-container {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  gap: 3px;
  background-color: var(--white-smoke-home);
}
.cart-middle-container {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-template-rows: repeat(12, 1fr);
  height: 100vh; 
  justify-items: center;
  gap: 3px;
}

.cart-sidebar {
  grid-column: 1 / 3;
}

.cart-middle-container {
  grid-column: 3 / 10;

  padding-left: 25px;
  padding-right: 25px;
  padding-top: 10px;
}
.cart-right-container {
  grid-column: 10 / 13;
}

.cart-summary{
    grid-row: 1;
    grid-column: 1 / 12;
    width: 100%;

}
.cart-items-list{
    padding-top: 25px;
    grid-row: 2;
    grid-column: 1 / 12;
    width: 100%;
    gap: 7px;
    display: flex;    
}
@media screen and (max-width: 450px){
  
  .cart-items-list{
    display:inline-block; 
  }
}

@media screen and (max-width: 639px) {
  .cart-middle-container {
    display: inline;
  }

}

@media screen and (max-width: 1211px) {
  .cart-sidebar {
    display: none;
  }
  .cart-right-container {
    display: none;
  }

  .cart-middle-container {
    grid-column: 1 / 13;
  }
}