.children-selector-pop-up-table tr,
.children-selector-pop-up-table th,
.children-selector-pop-up-table td {
	padding: 10px;
	margin: auto;
	border: none;
	font-family: "Newsreader";
	padding-bottom: 5px;
}
.children-selector-pop-up-table td {
	font-size: 15px;
}

.children-selector-pop-up-container {
	border-collapse: collapse;
	border-radius: 20px;
	padding-left: 10px;
	padding-right: 10px;
	padding-bottom: 10px;
	overflow: hidden;
}

.children-selector-pop-up-card-btn-text {
	padding-top: 3px;
}

.children-selector-pop-up-close-btn {
	font-family: "Roboto";
	padding-right: 10px;
	color: var(--pinki-theme-color);
	background: transparent;
	border-style: none;

	font-size: 22px;
	font-weight: bold;
	text-align: end;
	cursor: pointer;

	align-self: flex-end;
	justify-self: flex-end;
}

.children-selector-pop-up-card-name {
	display: grid;
	grid-template-columns: repeat(auto-fit, auto);
	align-items: center;
	justify-content: start;
}
.children-selector-pop-up-card-name-avatar {
	grid-column: 1;
	grid-row: 1;
}

.children-selector-pop-up-card-name-text {
	grid-column: 2;
	grid-row: 1;
	padding-left: 10px;
	color: var(--grey-text-color);
}

.children-selector-pop-up-card-selection-enabled {
	cursor: pointer;
}
.children-selector-pop-up-card-selected-check {
	color: var(--greendark-theme-color);
	font-size: 22px;
}
