.delete-plan-by-dat-range-container {
	display: grid;
	padding-left: 35px;
	padding-right: 20px;
	border-radius: 10px;
}

.delete-plan-by-dat-range-close-btn {
	font-family: "Roboto";
	padding-right: 10px;
	color: var(--pinki-theme-color);
	background: transparent;
	border-style: none;
	font-size: 22px;
	font-weight: bold;
	text-align: end;
	cursor: pointer;

	align-self: flex-end;
	justify-self: flex-end;
}

.delete-plan-by-dat-range-read-only-name {
	font: normal normal normal 20px/20px Vidaloka;
	letter-spacing: -0.36px;
	font-weight: bolder;
}
.delete-plan-by-dat-range-read-only-hint {
	font: normal normal normal 15px/20px Vidaloka;
	letter-spacing: -0.36px;
	color: gray;
}

.delete-plan-by-dat-range-date-pickers-container {
	display: grid;
	gap: 5px;
	grid-template-columns: repeat(2, minmax(50px, 1fr));
	padding-right: 15px;
	padding-bottom: 10px;
}
.delete-plan-by-dat-range-date-pickers-label {
	grid-column: 1;
	text-align: left;
	font: normal normal bold 16px/14px Charter;
	letter-spacing: -0.24px;
	align-self: center;
}
.delete-plan-by-dat-range-date-pickers-input {
	grid-column: 2;
	justify-self: start;

	padding-left: 10px;
	padding-right: 10px;

	cursor: pointer;
	background: #e0e9e3 0% 0% no-repeat padding-box;
	border-radius: 10px;
	font-size: small;
}

.delete-plan-by-dat-range-btns-container {
	display: grid;
	gap: 5px;
}

.delete-plan-by-dat-range-btns-cancel {
	height: 26px;
	border: 1px solid #ff686c;
	border-radius: 10px;
	color: #f27475;
	text-align: center;
	grid-column: 1;
	cursor: pointer;

	padding-top: 3px;
	font: normal normal 15px Charter;
	letter-spacing: -0.21px;
}
.delete-plan-by-dat-range-btns-delete {
	height: 26px;
	background: #98b5a4 0% 0% no-repeat padding-box;
	border-radius: 10px;
	color: white;
	text-align: center;
	grid-column: 2;
	cursor: pointer;

	padding-top: 3px;
	font: normal normal 15px Charter;
	letter-spacing: -0.21px;
}
