.children-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(279px, 1fr));
  gap: 20px;
  grid-auto-flow: dense;

  justify-items: center;
}
.children-filters-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 10px;
  grid-auto-flow: dense;
  justify-items: center;

  text-align: center;
  width: 100%;
  bottom: 5%;
  padding-bottom: 20px;

  color: #d4d4d4;
}

.add-child-button-btn {
  cursor: pointer;
  font-size: 40px;
  color: var(--green-theme-color);
}

.child-filter-button-btn {
  cursor: pointer;
  font-size: 40px;
  color: var(--green-theme-color);
}

.child-card {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  justify-self: center;
  height: 200px;
  width: 279px;
  padding: 10px;
  background: #ffffff;
  border-radius: 30px;
  border-bottom-style: solid;
  border-top-style: solid;
  border-color: var(--green-theme-color);
}

.child-title {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 28px;
  display: flex;
  align-items: center;

  color: #000000;

  grid-column: 1 / 3;
  grid-row: 2;
}

.child-label {
  font-family: "Newsreader";
  font-style: normal;
  font-weight: 700;
  font-size: 17px;

  color: #000000;
}

.child-id-label {
  grid-column: 1;
  grid-row: 3;
}
.child-id-content {
  grid-column: 2;
  grid-row: 3;
}

.child-dateofbirth-label {
  grid-column: 1;
  grid-row: 4;
}
.child-dateofbirth-content {
  grid-column: 2;
  grid-row: 4;
}

.child-edit-btn {
  height: 23px;
  left: 12px;
  right: 188px;
  width: 70px;
  bottom: 27px;

  color: #1762a7;

  cursor: pointer;

  grid-column: 1;
  grid-row: 8;
}
