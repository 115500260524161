.children-home-table-admin tr,
.children-home-table-admin th,
.children-home-table-admin td {
	padding: 10px;
	margin: auto;
	border: none;
	font-family: "Newsreader";
	padding-bottom: 5px;
}
.children-home-table-admin td {
	font-size: 15px;
}

.children-admin-container {
	border-collapse: collapse;
	border-radius: 20px;
	padding-left: 10px;
	padding-right: 10px;
	overflow: auto;
}

.children-home-card-btn-container {
	display: grid;
	grid-template-columns: repeat(auto-fill, auto);
	justify-content: start;
	gap: 5px;
	padding-bottom: 10px;
}
.children-home-card-btn-edit {
	height: 26px;
	background-color: var(--greendark-theme-color);
	border-radius: 10px;
	color: white;
	text-align: center;
	grid-column: 2;
	cursor: pointer;
	width: 70px;

	grid-row: 1;
	grid-column: 1;
}
.children-home-card-btn-text {
	padding-top: 3px;
}
.children-home-card-btn-edit-view-plan {
	height: 26px;
	background-color: var(--green-theme-color);
	border-radius: 10px;
	color: white;
	text-align: center;
	grid-column: 2;
	cursor: pointer;
	width: 70px;

	grid-row: 1;
	grid-column: 2;
}

.children-admin-close-btn {
	font-family: "Roboto";
	padding-right: 10px;
	color: var(--pinki-theme-color);
	background: transparent;
	border-style: none;

	font-size: 22px;
	font-weight: bold;
	text-align: end;
	cursor: pointer;

	align-self: flex-end;
	justify-self: flex-end;
}
.children-home-card-add {
	color: var(--greendark-theme-color);
	cursor: pointer;
}
