.cart-icon-container {
  display: grid;
  grid-template-columns: repeat(12, 20px);
  grid-template-rows: 25px;
  
  font-weight: bolder;
}

.cart-icon-item-icon {
  font-size: 40px;
  grid-column: 1;
  grid-row: 1;
  align-self: center;
  color: var(--green-theme-color);
}
.cart-icon-item-counter {
  height: 20px;
  width: 13px;
  border-radius: 10px;
  background-color: var(--orange-theme-color);
  color: white;
  font-size: 15px;
  grid-column: 2;
  grid-row: 1;
  align-self: flex-end;
  justify-self: center;
  text-align: center;
  /* padding-left: 1.5px; */
}
.cart-icon-item-text {
  grid-column: 3;
  padding-left: 10px;

  align-self: center;
}
