.weekly-schedule-container table {
  border-collapse: collapse;

  width: 100%;
  table-layout: fixed;
  font-family: "Roboto";
}

.weekly-schedule-container th,
.weekly-schedule-container td,
.weekly-schedule-container tr {
  border-bottom: 1px solid #ddd;
  height: 52px;
  align-self: center;
  text-align: center;
  /* padding: 1px; */
  padding-left: 7px;
  padding-bottom: 1px;

}

.weekly-schedule-container th {
  /* background-color: var(--greensoft-theme-color); */
}
.weekly-schedule-container td {
  font-size: 15px;
}

.weekly-schedule-time {
  font: normal normal normal 12px/15px Vidaloka;
  letter-spacing: -0.36px;
  font-weight: bolder;
}
.weekly-schedule-time-same-from-time {
  padding-left: 5px;
  font-size: 10px;
}

.weekly-schedule-activity-edit-btn {
  color: #1762a7;
  font-weight: 550;
  cursor: pointer;

  grid-column: 1;
  grid-row: 8;
}
.weekly-schedule-activity-edit-single-icon {
  color: var(--green-theme-color);
}
.weekly-schedule-activity-edit-group-icon {
  color: var(--orange-theme-color);
}

.weekschedule-day-header-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(30px, 1fr));
  gap: 15px;
  grid-auto-flow: dense;

  justify-items: center;
}
.weekschedule-day-header {
  font-size: 18px;
  grid-row: 1;
}
.weekschedule-day-header-day {
  font-size: 10px;
  grid-row: 2;
}

.weekschedule-day-header-weekend {
  color: grey;
}
.planned-activity-pending {
  color: red;
}

@media only screen and (max-width: 820px) {
  th {
    font-size: 12px;
  }
  .weekschedule-day-header {
    font-size: 12px;
    grid-row: 1;
  }
  .weekschedule-day-header-day {
    font-size: 8px;
    grid-row: 2;
  }
  td {
    font-size: 11px;
  }
}

.weekly-schedule-empty {
  background-color: blue;
}
