.child-edition-container {
	display: grid;
	gap: 5px;
	padding-left: 35px;
	padding-right: 20px;
	overflow-y: auto;
	overflow-x: hidden;
	border-radius: 10px;
}
.child-edition-close-btn {
	font-family: "Roboto";
	padding-right: 10px;
	color: var(--pinki-theme-color);
	background: transparent;
	border-style: none;

	font-size: 22px;
	font-weight: bold;
	text-align: center;
	cursor: pointer;

	align-self: flex-end;
	justify-self: flex-end;
	grid-column: 1 / 4;
}
.child-edition-title {
	text-align: center;
	font: normal normal normal 30px/30px Vidaloka;
	letter-spacing: -0.45px;
	color: #191919;
	grid-column: 1 / 4;
}

.child-edition-picture-image-person {
	font-size: 70px;
	color: #98b5a4;
}
.child-edition-picture-container {
	text-align: center;
	grid-column: 1 / 4;
	width: 100%;
}
.child-edition-form-container {
	display: grid;
	gap: 10px;
	grid-column: 1 / 4;
	padding-bottom: 10px;
}
.child-edition-form-row {
	display: grid;
	gap: 10px;
	grid-template-columns: repeat(auto-fit, minmax(auto, 1fr));
}
.child-edition-form-label {
	justify-self: start;
	grid-column: 1;
	text-align: left;
	font: normal normal bold 16px/14px Charter;
	letter-spacing: -0.24px;
	color: #191919;
	padding-top: 10px;
}
.child-edition-form-input {
	grid-column: 2;
	background: #e0e9e3 0% 0% no-repeat padding-box;
	border-radius: 10px;
	justify-self: end;
	padding-left: 10px;
	height: 40px;
	width: 240px;
	font: normal normal bold 16px Charter;
	letter-spacing: -0.24px;
	color: rgb(98, 97, 97);
	border: none;
	cursor: pointer;
}
.child-edition-form-input-text {
	padding-top: 10px;
}
.child-edition-back-btn {
	height: 26px;
	border: 1px solid #ff686c;
	border-radius: 10px;
	color: #f27475;
	text-align: center;
	grid-column: 1;
	cursor: pointer;

	padding-top: 3px;
	font: normal normal 15px Charter;
	letter-spacing: -0.21px;
}
.child-edition-delete-btn {
	grid-column: 2;
	justify-self: center;
}

.child-edition-trash-btn {
	height: 23px;
	left: 12px;
	right: 188px;
	width: 70px;
	bottom: 27px;

	color: red;
	background: transparent;
	border-style: none;
	border-radius: 10px;
	border-width: thin;

	cursor: pointer;

	align-self: flex-start;
	justify-self: flex-end;
}
.child-edition-save-btn {
	height: 26px;
	background: #98b5a4 0% 0% no-repeat padding-box;
	border-radius: 10px;
	color: white;
	text-align: center;
	grid-column: 3;
	cursor: pointer;

	padding-top: 3px;
	font: normal normal 15px Charter;
	letter-spacing: -0.21px;
}
