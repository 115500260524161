.mentorship-available-slots-card-title {
}
.mentorship-available-slots-container {
	display: grid;
	grid-template-columns: repeat(12, 1fr);
	grid-template-rows: repeat(12, 1fr);
	height: 100vh;
	justify-items: center;
	gap: 3px;
	overflow: auto;
	border-radius: 20px;
}
.mentorship-available-slots-calendar {
	grid-row: 2;
	grid-column: 1/12;
	width: 280px;
	font-size: 1px;
}
.mentorship-available-slots-card-close-btn {
	font-family: "Roboto";
	grid-row: 1;
	grid-column: 1/12;
	padding-right: 10px;
	color: var(--pinki-theme-color);
	background: transparent;
	border-style: none;

	font-size: 22px;
	font-weight: bold;
	text-align: end;
	cursor: pointer;

	align-self: flex-end;
	justify-self: flex-end;
}
.mentorship-available-slots-card-container {
	display: grid;
	gap: 10px;
	font: normal normal normal 18px/20px Charter;
	letter-spacing: -0.27px;
}
.mentorship-available-slots-card-description {
	grid-column: 1;
	font-weight: bolder;
	align-self: center;
	color: grey;
}
.mentorship-available-slots-card-book-btn {
	grid-column: 2;
	color: white;
	font: normal normal bold 15px/20px Charter;
	cursor: pointer;
	align-self: center;
	justify-self: center;
	width: 100px;
	background: #98b5a4 0% 0% no-repeat padding-box;
	border-radius: 10px;
	text-align: center;
	padding-top: 10px;
	padding-bottom: 10px;
}
.mentorship-available-slots-card-book-btn:hover {
	text-decoration: underline;
	color: var(--link-color);
}

@media screen and (max-width: 785px) {
	.mentorship-available-slots-card-container {
		font: normal normal normal 16px/20px Charter;
		letter-spacing: -0.27px;
	}
}
