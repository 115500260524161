.cart-summary-container{   
    display: grid;
    gap: 3px;
    grid-template-columns: repeat(12, 1fr);
    grid-auto-rows: 25px;

    padding-top: 10px;
     
}
.cart-summary-icon{
    grid-column: 1/2;
    grid-row: 1/2;

    font-size: 72px;
    align-self:center;
    color: var(--orange-theme-color);
}
.cart-summary-total-items{
    grid-column: 2/10;
    grid-row: 1;
    align-self: flex-end;
}
.cart-summary-total-price{
    grid-column: 2/10;
    grid-row: 2;
}
.cart-summary-checkout-btn{
    grid-column: 1/10;
    grid-row: 3;

}
.cart-summary-total-items-number{
    font-size: 25px;
    font-family: 'Newsreader';
    font-style: normal;
    font-weight: 400;
}
.cart-summary-total-price-number{
    font-size: 25px;
    font-family: 'Newsreader';
    font-style: normal;
    font-weight: 400;
}