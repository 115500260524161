.infopopup-container {
	display: grid;
	grid-template-columns: repeat(auto-fit, auto);
	padding-bottom: 20px;
}
.infopopup-row-content {
	display: grid;
	grid-template-columns: repeat(auto-fit, auto);
	padding-bottom: 10px;
}

.infopopup-close-btn {
	font-family: "Roboto";
	padding-right: 10px;
	color: var(--pinki-theme-color);
	background: transparent;
	border-style: none;

	font-size: 22px;
	font-weight: bold;
	text-align: center;
	cursor: pointer;

	align-self: flex-start;
	justify-self: flex-end;
}
.infopopup-close-btn:hover {
	padding-right: 12px;
	color: var(--green-theme-color);
}

.infopopup-icon {
	color: orange;
	width: 100%;
	text-align: center;
	font-size: 40px;

	align-self: flex-start;
}
.infopopup-message {
	width: 100%;
	text-align: center;
	padding-left: 10px;
}
