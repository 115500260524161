.home-page-products-container {
	padding-left: 25px;
	padding-right: 25px;
}

@media screen and (max-width: 639px) {
	.home-page-products-container {
		overflow: scroll;
	}
}
