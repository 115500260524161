.youtube-player {
  width: 100%;
}

.playlist-video-player-container {
  display: grid;
  grid-template-columns: repeat(2, auto);
  gap: 20px;
  grid-auto-flow: dense;

  justify-items: center;
}

@media only screen and (max-width: 1198px) {
  .playlist-video-player-container {
    grid-template-columns: repeat(1, auto);
  }
}
