.forgot-pwd-container {
	display: grid;
	grid-template-columns: repeat(auto-fit, auto);

	grid-auto-flow: dense;
}

.forgot-pwd-close-btn {
	font-family: "Roboto";
	padding-right: 10px;
	color: var(--pinki-theme-color);
	background: transparent;
	border-style: none;

	font-size: 22px;
	font-weight: bold;
	text-align: center;
	cursor: pointer;

	align-self: flex-end;
	justify-self: flex-end;
}

.forgot-pwd-label {
	width: auto;
	height: 59px;

	font-family: "Charter";
	font-style: normal;

	font-size: 24px;
	line-height: 36px;

	color: #000000;
}

.forgot-pwd-input {
	width: 450px;
	height: 45px;
	font-size: 16px;
	padding-left: 5px;
	border: 2px solid var(--green-theme-color);
	border-radius: 10px;
	color: #c66868;
	width: 100%;
}

.forgot-pwd-send-btn {
	font-family: "Roboto";
	font-style: normal;

	font-size: 20px;
	line-height: 28px;

	border-style: none;

	color: #ffffff;
	padding: 0.75rem 1.5rem;
	border-radius: 10px;
	background-color: var(--green-theme-color);
}
.forgot-pwd-send-btn:hover {
	background-image: linear-gradient(
		100deg,
		var(--green-theme-color),
		var(--greensoft-theme-color)
	);

	font-size: 22px;
}
.forgot-pwd-send-btn-text {
	font-family: "Charter";
}

.forgot-pwd-send-btn-container {
	padding-top: 20px;
}
