.new-useryear-plannedactivity-card {
	display: grid;
	grid-template-columns: repeat(auto-fit, auto);

	padding: 10px;
	background: #ffffff;
	border-style: none;
	border-radius: 30px;

	border-bottom-style: solid;
	border-top-style: solid;
}
.new-planned-year-activity-row-container {
	padding-right: 16px;
}
.new-planned-year-activity-footer-btn-container {
	padding-right: 16px;
}

.year-activities-table-add-activity {
	color: white;
	cursor: pointer;
	font-size: 22px;
}
.new-planned-year-activity-activity-close-btn {
	font-family: "Roboto";
	padding-right: 10px;
	color: var(--pinki-theme-color);
	background: transparent;
	border-style: none;

	font-size: 22px;
	font-weight: bold;
	text-align: center;
	cursor: pointer;

	justify-self: flex-end;
}

.new-planned-year-activity-create-btn {
	height: 26px;
	background: #98b5a4 0% 0% no-repeat padding-box;
	border-radius: 10px;
	color: white;
	text-align: center;
	grid-column: 2;
	cursor: pointer;

	padding-top: 3px;
	font: normal normal 15px Charter;
	letter-spacing: -0.21px;
}
.new-planned-year-activity-comment-content {
	border-radius: 7px;
	width: 100%;
	padding-left: 5px;
}
