.membership-need-upgrade-container {
	display: grid;
	gap: 3px;
	text-align: center;
	padding-left: 7px;
	padding-right: 7px;
	border-radius: 20px;
}
.membership-need-upgrade-icon {
	grid-column: 1/2;
	grid-row: 1;
}
.membership-need-upgrade-text {
	grid-column: 1/2;
	grid-row: 2;
}
.membership-need-upgrade-btns-container {
	display: grid;
	gap: 3px;
	align-items: center;
	padding-bottom: 10px;
	width: 100%;
}

.membership-need-upgrade-btns-close {
	grid-column: 1;
	grid-row: 1;

	background-color: var(--pinki-theme-color);
	border-radius: 10px;
	justify-self: center;
	width: 100px;
	color: white;
	border: none;
	cursor: pointer;
	text-align: center;

	padding: 5px;
}
.membership-need-upgrade-btns-ckeck-options {
	grid-column: 2;
	grid-row: 1;

	background-color: var(--greendark-theme-color);
	border-radius: 10px;
	justify-self: center;
	width: 120px;
	color: white;
	border: none;
	cursor: pointer;
	text-align: center;

	padding: 5px;
}
