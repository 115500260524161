.mentorship-appointment-card-container{
    
}
.mentorship-appointment-card-description{
    font: normal normal bold 18px/20px Charter;
    letter-spacing: -0.27px;
    color: grey;
    align-self: center;
}
.mentorship-appointment-card-status{
    font: normal normal bold 18px/20px Charter;
    letter-spacing: -0.27px;
    color: black;
    align-self: center; 
}
.mentorship-appointment-card-link{
    font: normal normal bold 18px/20px Charter;
    letter-spacing: -0.27px;
    color: var(--link-color);
    align-self: center; 
}