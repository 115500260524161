.calendar-period-add-container {
	display: grid;
	gap: 10px;
	grid-column: 1 / 3;
	padding: 10px;
	border-radius: 10px;
}
.calendar-period-add-close-btn {
	font-family: "Roboto";
	padding-right: 10px;
	color: var(--pinki-theme-color);
	background: transparent;
	border-style: none;

	font-size: 22px;
	font-weight: bold;
	text-align: center;
	cursor: pointer;

	align-self: flex-end;
	justify-self: flex-end;
}
.calendar-period-add-row {
	display: grid;
	gap: 10px;
	grid-template-columns: repeat(auto-fit, minmax(auto, 1fr));
	height: 40px;
}
.calendar-period-add-label {
	justify-self: start;
	grid-column: 1;
	text-align: left;
	font: normal normal bold 16px/14px Charter;
	letter-spacing: -0.24px;
	color: #191919;
	padding-top: 10px;
}
.calendar-period-add-input {
	grid-column: 2;
	background: #e0e9e3 0% 0% no-repeat padding-box;
	border-radius: 10px;
	justify-self: end;
	padding-left: 10px;
	width: 150px;
	font: normal normal bold 16px Charter;
	letter-spacing: -0.24px;
	color: rgb(98, 97, 97);
	border: none;
	cursor: pointer;
}
.calendar-period-add-footer-btns {
	display: grid;
	gap: 10px;
	grid-template-columns: repeat(auto-fit, minmax(auto, 1fr));
	height: 40px;
}
.calendar-period-add-footer-btns-back {
	height: 26px;
	border: 1px solid #ff686c;
	border-radius: 10px;
	color: #f27475;
	text-align: center;
	grid-column: 1;
	cursor: pointer;

	padding-top: 3px;
	font: normal normal 15px Charter;
	letter-spacing: -0.21px;
}
.calendar-period-add-footer-btns-save {
	height: 26px;
	background: #98b5a4 0% 0% no-repeat padding-box;
	border-radius: 10px;
	color: white;
	text-align: center;
	grid-column: 2;
	cursor: pointer;

	padding-top: 3px;
	font: normal normal 15px Charter;
	letter-spacing: -0.21px;
}
.calendar-period-add-input-date {
	padding-top: 10px;
}
