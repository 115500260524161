.page-info-container {
  padding-top: 20px;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(50px, 1fr));
  gap: 20px;
  grid-auto-flow: dense;

  justify-items: center;
}

.page-info {
  text-align: center;
  padding-bottom: 10px;
  width: 100%;
  bottom: 5%;

  color: #d4d4d4;
}

.page-back {
  cursor: pointer;
  font-size: 24px;
  font-weight: bold;
}
.page-forward {
  cursor: pointer;
  font-size: 24px;
  font-weight: bold;
}

.page-back:hover {
  color: var(--green-theme-color);
  font-size: 27px;
}
.page-forward:hover {
  color: var(--green-theme-color);
  font-size: 27px;
}
