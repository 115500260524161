.planner-home-card-container {
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 10px;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(12, 1fr));

  justify-items: start;
  font-family: "Newsreader";
  background-color: white;

  border-style: groove;
  border-width: thin;
  border-color: black;
  border-radius: 17px;
}
.planner-home-card-container:hover {
  background-color: var(--green-theme-color);
  color: white;
}
.planner-home-card-activity-date {
  grid-column: 1 / 2;
  grid-row: 1 / 3;
  font-size: 25px;
}
.planner-home-card-activity-details-container {
  grid-column: 2 / 12;
  grid-row: 1;
}

.planner-home-card-activity-description-comment {
  padding-left: 3px;
  font-size: 12px;
  color: gray;
}

.planner-home-card-activity-date-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(auto, 1fr));
}
.planner-home-card-activity-description {
  grid-column: 2 / 12;
  grid-row: 1;
  /* font-size: 15px; */
  /* font-weight: bolder; */
}
.planner-home-card-activity-comment {
  grid-column: 2 / 12;
  grid-row: 2;
}
.planner-home-card-activity-times {
  grid-column: 2 / 12;
  grid-row: 3;
  font-size: 12px;
}

.planner-home-card-activity-date-text {
  grid-column: 1;
  padding-right: 5px;
}
.planner-home-card-activity-date-line {
  border-left: 2px solid;
  height: 100%;
  grid-column: 2;
}
