.world-map-users-container {
	border-radius: 10px;
}
.world-map-users-title {
	text-align: center;
	font: normal normal bold 17px/45px Charter;
	letter-spacing: -0.26px;
	line-height: 20px;
}
.world-map-users-close-btn {
	font-family: "Roboto";
	padding-right: 10px;
	color: var(--pinki-theme-color);
	background: transparent;
	border-style: none;

	font-size: 22px;
	font-weight: bold;
	text-align: end;
	cursor: pointer;

	align-self: flex-end;
	justify-self: flex-end;
}
