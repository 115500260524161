.product-home-card-container {
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(279px, 1fr));

	background-color: white;
	border-radius: 20px;

	font-family: "Newsreader";
	padding: 10px;
}
.product-home-card-container:hover {
	border-style: solid;
	border-color: var(--green-theme-color);
}
.product-home-card-picture {
	grid-column: 1;
	grid-row: 1 / 3;
	cursor: pointer;
	align-self: center;
}
.product-home-card-picture img {
	display: block;
	margin-left: auto;
	margin-right: auto;
	width: 150px;
	border-radius: 5%;
}
.product-home-card-picture img:hover {
	border-style: solid;
	border-color: var(--orange-theme-color);
}

.product-home-card-title {
	grid-column: 2 / 3;
	grid-row: 1;
}
.product-home-card-desciption {
	padding: 5px;
	grid-column: 2 / 4;
	grid-row: 2;
	color: rgb(160, 158, 158);
	cursor: pointer;
}
.show-more-btn {
	color: var(--link-color);
	font: normal normal normal 15px Vidaloka;
	cursor: pointer;
}
.show-more-btn:hover {
	text-decoration: underline;
	font-weight: bold;
}
.product-home-card-tags {
	padding-left: 10px;
	grid-column: 2;
	grid-row: 3;
}
.product-home-card-status-container {
	padding-top: 10px;
	padding-right: 10px;
	grid-column: 3;
	grid-row: 1;
	justify-self: end;
}
.product-home-card-status {
	padding-left: 10px;
	padding-right: 10px;

	background-color: greenyellow;
	border-radius: 10px;
	text-align: center;
	font-weight: bolder;
}

.active-product-status {
	width: 79px;
	height: 23px;
	border-radius: 10px;
	background-color: var(--orange-theme-color);
	color: #ffffff;
	text-align: center;
	/* font-size: 16px; */

	grid-column: 12;
}
.active-product-status:hover {
	background-color: var(--green-theme-color);
}

.soon-product-status {
	width: 79px;
	height: 23px;
	border-radius: 10px;
	background-color: grey;
	color: #ffffff;
	text-align: center;
	font-size: 16px;

	grid-column: 12;
}

@media screen and (max-width: 639px) {
	.product-home-card-container {
		display: inline;
		padding-bottom: 10px;
	}

	.product-home-card-desciption {
		width: 100%;
	}
}
