.help-item-card {
  display: grid;
  grid-template-columns: repeat(auto-fit, auto);
  cursor: pointer;
  font-family: "Roboto";
}
.help-item-card :hover {
  font-weight: bolder;
  color: blue;
}

.help-item-card-icon {
  font-size: 40px;
}

.help-item-card-hide {
  font-weight: lighter;
  color: red;
}
.help-item-card-hide:hover {
  color: red;
}

.help-item-card-text {
  font-weight: 400;
}
