.duplicate-week-container {
	display: grid;
	gap: 10px;
	padding: 20px;

	overflow-y: auto;
	overflow-x: hidden;
}
.duplicate-week-close-btn {
	font-family: "Roboto";
	padding-right: 10px;
	color: var(--pinki-theme-color);
	background: transparent;
	border-style: none;

	font-size: 22px;
	font-weight: bold;
	text-align: center;
	cursor: pointer;

	align-self: flex-end;
	justify-self: flex-end;
	grid-column: 1 / 3;
}
.duplicate-week-body {
	grid-column: 1 / 3;
	grid-row: 2;
	text-align: center;

	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}
.duplicate-week-btn-container {
	grid-column: 1 / 3;
	grid-row: 3;
	width: 100%;
	text-align: center;

	display: flex;
	justify-content: center;
	align-items: center;
}

.duplicate-week-btn {
	width: 80%;
	background: var(--greendark-theme-color);
	border-radius: 10px;
	color: white;
	text-align: center;
	cursor: pointer;

	padding: 3px;

	letter-spacing: 0.21px;
}
.duplicate-week-text {
	padding-bottom: 10px;
}
.duplicate-week-date-input {
	padding: 8px;
	background: #e0e9e3 0% 0% no-repeat padding-box;
	border-radius: 10px;
	height: 40px;
	width: auto;
	letter-spacing: 0.24px;
	color: rgb(98, 97, 97);
	border: none;

	justify-self: center;
	cursor: pointer;

	background-color: var(--greensoft-theme-color);
}
