.home-page-activities-container {
  font-family: "Roboto";
  padding-right: 25px;
}
.home-page-activities-title {
  font: normal normal normal 20px/53px Vidaloka;
  letter-spacing: -0.36px;
  font-weight: bolder;
}
.home-page-activities-card-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, auto);
  border-radius: 10px;

  background-color: var(--green-theme-color);
  height: 130px;
}
.home-page-activities-card-text {
  padding-left: 20px;
  color: #ffffff;
  align-self: center;
}
.home-page-activities-card-btn-container {
  padding-left: 20px;
  text-align: center;
}
.home-page-activities-card-btn {
  cursor: pointer;
  padding-top: 10px;
  border-radius: 10px;
  background-color: whitesmoke;
  width: 80%;
  height: 48px;
  color: var(--link-color);
}
.home-page-activities-card-btn:hover {
  
}
