.third-party-planning-mode-banner {
	display: grid;
	background: var(--orange-theme-color);
	color: white;
	text-align: center;
	padding-left: 5px;
	padding-right: 5px;
}
.third-party-planning-mode-banner-text {
	padding: 8px 15px;
	text-align: start;
	grid-row: 1;
}
.third-party-planning-mode-banner-btn {
	grid-row: 1;
	text-align: end;
}
.third-party-planning-mode-banner-btn-text {
	cursor: pointer;
	display: inline-block;
	padding: 8px 15px;
	background-color: var(--blue-theme-color);
	color: white;
	border: none;
	border-radius: 5px;
	text-align: center;
	box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
	transition: all 0.3s ease;
}

.third-party-planning-mode-banner-btn-text:hover {
	background-color: var(--dark-blue-theme-color);
	box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
	transform: translateY(-2px);
}

.third-party-planning-mode-banner-btn-text:active {
	background-color: var(--dark-blue-theme-color);
	box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
	transform: translateY(0);
}
.nav-bar-home-container {
	background: var(--white-smoke-home);
	position: sticky;
	top: 0;
	width: 100%;
	padding-top: 0%;
	z-index: 1000;
	gap: 3px;

	display: grid;
	grid-template-columns: repeat(12, 1fr);

	font-family: "Popping";
	font-weight: bolder;
}

.white-background {
	background: white;
}
.nav-bar-home-brand-logo {
	cursor: pointer;
}
.nav-bar-home-left {
	grid-column: 1 / 3;
}
.nav-bar-home-middle {
	grid-column: 3 / 10;
}

.nav-bar-home-right {
	grid-column: 10 / 13;
}

.nav-bar-home-middle {
	display: grid;
	grid-template-columns: repeat(12, 1fr);
	white-space: nowrap;
	background: white;
	font: normal normal normal 18px Vidaloka;
	letter-spacing: -0.36px;
	font-weight: bolder;
	padding-left: 25px;
	border-radius: 30px;
}
.nav-bar-home-instagram-text {
	padding-left: 10px;
}
.nav-bar-home-middle-left {
	display: grid;
	grid-template-columns: repeat(12, 1fr);
	gap: 3px;
	align-items: center;
	grid-column: 1 / 8;
}

.nav-bar-home-middle-right {
	grid-column: 9 / 12;
	padding-right: 25px;
}
.nav-bar-home-middle-left-item {
	grid-row: 1;
	padding-right: 40px;
	align-self: center;
	cursor: pointer;
}
.nav-bar-home-middle-left-item:hover {
	color: var(--green-theme-color);
}

.nav-bar-home-middle-right {
	cursor: pointer;
}
.nav-bar-home-middle-right:hover {
	color: var(--green-theme-color);
}

.nav-bar-home-middle-right {
	display: grid;
	grid-template-columns: repeat(12, 1fr);
	gap: 3px;
	justify-items: end;
	align-items: center;
}

.nav-bar-home-instagram {
	font-size: 25px;
	padding-right: 5px;
}

.nav-bar-home-user {
	display: grid;
	grid-template-columns: repeat(auto-fit, auto);
}
.nav-bar-home-user-picture {
	grid-column: 1;
	grid-row: 1 / 3;
	align-self: center;
	justify-self: end;
	text-align: center;
	padding-right: 5px;
}
.nav-bar-home-user-name {
	grid-column: 2;
	grid-row: 1;
	font-size: 17px;
}

.nav-bar-home-user-profession {
	grid-column: 2;
	grid-row: 2;
	font-size: 15px;
	font-weight: lighter;
}

.nav-bar-home-user-profile {
	grid-column: 2;
	grid-row: 2;
	font-size: 15px;
	font-weight: lighter;
	color: var(--link-color);
	cursor: pointer;
	width: fit-content;
}
.nav-bar-home-user-profile:hover {
	font-weight: bolder;
}
.nav-bar-home-user-free {
	font-size: 13px;
	font-weight: bold;
	color: white;
	background-color: var(--orange-theme-color);
	padding: 5px 10px;
	border-radius: 15px;
	width: fit-content;
	text-align: center;
	box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.nav-bar-home-toggle {
	align-self: center;
	width: fit-content;
}
.nav-bar-home-left-container {
	display: flex;
	align-items: center;
}
.nav-bar-home-toggle {
	color: var(--orange-theme-color);
	padding-left: 5px;
	cursor: pointer;
	font-size: 23px;
}
.nav-bar-home-toggle:hover {
	font-size: 25px;
}
@media screen and (max-width: 800px) {
	.nav-bar-home-left {
		grid-column: 1 / 3;
	}
	.nav-bar-home-middle {
		grid-column: 4 / 10;
	}

	.nav-bar-home-right {
		grid-column: 10 / 13;
	}
}

@media screen and (max-width: 560px) {
	.nav-bar-home-left {
		grid-column: 1 / 3;
	}
	.nav-bar-home-middle {
		display: none;
	}

	.nav-bar-home-right {
		grid-column: 10 / 13;
	}
}
