.planned-activities-per-child-per-year-card-container {
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 10px;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(12, 1fr));

  justify-items: start;
  font-family: "Newsreader";
  background-color: white;

  border-style: groove;
  border-width: thin;
  border-color: black;
  border-radius: 17px;
  cursor: pointer;
}
.planned-activities-per-child-per-year-card-container:hover {
  background-color: var(--green-theme-color);
  color: white;
}

.planned-activities-per-child-per-year-card-date {
  grid-column: 1 / 2;
  grid-row: 1 / 3;
  font-size: 25px;
}
.planned-activities-per-child-per-year-card-details {
  display: grid;
  grid-column: 2 / 12;
  grid-row: 1;
  font-family: "Newsreader";
  padding-left: 5px;
  padding-right: 15px;
}
.planned-activities-per-child-per-year-card-details-title{
    font-family: "Newsreader";
    font-weight: bold;
    font-size: 20px;
}
.planned-activities-per-child-per-year-card-details-comment{
    font-family: "Newsreader";
    font-size: 15px;
    color: var(--grey-text-color);
}
.planned-activities-per-child-per-year-card-date-details{
    padding-right: 5px;
    font-size: 20px;
}
.planned-activities-per-child-per-year-card-date-line {
  border-left: 2px solid;
  height: 100%;
}