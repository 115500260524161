h1,
p {
  font-family: Lato;
}

.player-wrapper {
  position: relative;
}

.player-overlay-all {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  bottom: 0;
  z-index: 1;
  opacity: 80%;
}

.player-overlay-all-btn {
  position: absolute;
  width: 100%;
  left: 0;
  top: 45%;
  z-index: 2;
  opacity: 80%;

  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  gap: 20px;
  grid-auto-flow: dense;

  justify-items: center;

  color: transparent;
  background-color: transparent;
  font-size: 50px;
}
.player-overlay-all-btn:hover {
  color: white;
}

.player-overlay-all-btn-back {
  grid-column: 1;
  justify-self: flex-end;
  cursor: pointer;
}
.player-overlay-all-btn-fwd {
  grid-column: 3;
  justify-self: flex-start;
  cursor: pointer;
  width: 100px;
}
.player-overlay-all-btn-play {
  grid-column: 2;

  justify-self: center;

  color: transparent;
  background-color: transparent;
  width: 100px;
  cursor: pointer;
}

.progress-bar-slider {
  position: relative;
  width: 100%;
  left: 0;
  top: 45%;
  z-index: 2;
  opacity: 80%;
}

@media only screen and (max-width: 550px) {
  .player-overlay-all-btn {
    font-size: 30px;
  }
  .player-overlay-all-btn-play {
    justify-self: flex-start;
  }
  .player-overlay-all-btn-back {
    justify-self: flex-start;
  }
  .player-overlay-all-btn-fwd {
    justify-self: flex-start;
  }
}
