.third-party-admin-module-container {
	display: grid;
	grid-template-columns: repeat(12, 1fr);
	gap: 3px;
	background-color: var(--white-smoke-home);
}
.third-party-admin-module-sidebar {
	grid-column: 1 / 3;
}
.third-party-admin-module-center {
	grid-column: 3 / 10;
	padding-left: 25px;
}
.third-party-admin-module-details-container {
	grid-column: 10 / 13;

	padding-left: 30px;
	padding-right: 30px;
	background-color: white;
	height: 100%;
	border-radius: 15px;
}
