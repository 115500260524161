.landing-mobile-container {
  display: grid;
  grid-template-rows: repeat(12, auto);

  background: transparent linear-gradient(180deg, #98b5a4 0%, #4c5b52 100%) 0%
    0% no-repeat padding-box;
  border: 1px solid #707070;
  opacity: 0.95;
}
.landing-mobile-background-picture {
  grid-column: 1 / 12;
  grid-row: 1 / 12;
  justify-self: center;
  opacity: 10%;
  z-index: -1;
}

.landing-mobile-logo {
  grid-column: 1 / 12;
  grid-row: 1;
}
.landing-mobile-title-text {
  padding-left: 23px;
  padding-right: 23px;

  grid-column: 1 / 12;
  grid-row: 6;
}
.landing-mobile-text-title {
  text-align: center;
  font: normal normal normal 35px/30px Vidaloka;
  letter-spacing: -0.45px;
  color: #ffffff;
}
.landing-mobile-text-paragraph {
  padding-top: 20px;
  text-align: center;
  font: normal normal normal 20px/20px Charter;
  letter-spacing: -0.27px;
  color: #ffffff;
}
.landing-mobile-toggle-container {
  padding-left: 50px;
  padding-right: 50px;
  grid-column: 1 / 12;
  grid-row: 10;
  height: 66px;
}

.landing-mobile-toggle-container-background {
  text-align: center;

  background: #98b5a4 0% 0% no-repeat padding-box;
  border-radius: 20px;
  display: grid;
  height: 100%;
}
.landing-mobile-toggle-register {
  grid-column: 1;
  padding-top: 20px;
  background: #e0e9e3 0% 0% no-repeat padding-box;
  border-radius: 20px;
}

.landing-mobile-toggle-login {
  grid-column: 2;
  padding-top: 20px;
  color: #ffffff;
  background: #98b5a4 0% 0% no-repeat padding-box;
  border-radius: 20px;
}
