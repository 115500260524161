.pwd-reset-container {
	display: grid;
	grid-template-columns: repeat(auto-fit, auto);

	grid-auto-flow: dense;
}

.pwd-reset-label {
	text-align: left;
	font: normal normal 17px/45px Charter;
	letter-spacing: -0.26px;
	color: #000000de;
	opacity: 1;
}

.pwd-reset-new-pwd-input {
	width: 450px;
	height: 45px;
	font-size: 17px;
	border: 2px solid #1762a7;
	border-radius: 10px;
	color: #c66868;
	width: 100%;
	padding-left: 10px;
}

.pwd-reset-send-btn {
	cursor: pointer;
	font-family: "Roboto";
	font-style: normal;

	font-size: 20px;
	line-height: 28px;

	border-style: none;

	color: #ffffff;
	padding: 0.75rem 1.5rem;
	border-radius: 10px;
	background-color: var(--green-theme-color);
}

.pwd-reset-send-btn-container {
	padding-top: 30px;
	text-align: center;
}

.pwd-reset-show-pwd-btn {
	cursor: pointer;
	font-size: 24px;
	color: var(--pinki-theme-color);
}

.pwd-reset-close-btn {
	font-family: "Roboto";
	padding-right: 10px;
	color: var(--pinki-theme-color);
	background: transparent;
	border-style: none;

	font-size: 22px;
	font-weight: bold;
	text-align: center;
	cursor: pointer;

	align-self: flex-end;
	justify-self: flex-end;
}
