.mark-activity-as-pendingI-icon-container {
	align-items: center;
	cursor: pointer;
}
.mark-activity-as-pendingI-icon-none {
	color: gray;
}
.mark-activity-as-pendingI-icon-pending {
	color: red;
}
