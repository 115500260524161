.playlist-video-card-container {
  display: grid;
  padding-bottom: 5px;

  width: 400px;
  padding-bottom: 10px;
  cursor: pointer;
  border-radius: 10px;
}
.playlist-video-card-container:hover {
  border-style: double;
  border-color: var(--green-theme-color);
}

.playlist-video-card-thumbnail {
  grid-column: 1;
  align-self: center;
}

.playlist-video-card-thumbnail img {
  width: 100%;
  border-radius: 3%;
}
.playlist-video-card-details {
  grid-column: 2;
}

.playlist-video-card-details {
  display: grid;
  /* grid-template-columns: repeat(auto-fit, auto); */
  grid-template-columns: repeat(auto-fill, minmax(279px, 1fr));
  padding-left: 5px;
}
.playlist-video-card-title {
  grid-row: 2;

  font-size: 15px;
  font-family: "Roboto", "Arial", sans-serif;
  font-weight: bold;
}
.playlist-video-card-description {
  grid-row: 3;

  font-weight: lighter;
  font-size: 12px;
  font-family: "Roboto", "Arial", sans-serif;
}

@media only screen and (max-width: 1200px) {
  .playlist-video-card-container {
    width: 400px;
  }
}
