.year-selector-grid-overall-container {
  display: grid;
  padding-left: 25px;
  text-align: center;
}

.year-selector-grid-title {
  font: normal normal normal 20px/53px Vidaloka;
  letter-spacing: -0.36px;
  font-weight: bolder;
}

.year-selector-grid-container {
  display: grid;
  grid-template-columns: repeat(3, 20px);
  padding: 10px;
  gap: 20px;
  justify-self: center;
}
.year-selector-grid-item-container {
  border-style: solid;
  border-width: 1px;
  border-radius: 20px;
  width: 30px;
  height: 30px;
  text-align: center;
  cursor: pointer;
}
.year-selector-grid-item-text {
  font: normal normal normal 16px/16px Vidaloka;
  letter-spacing: -0.27px;
  padding-top: 4px;
}
.selected-year {
  background-color: var(--green-theme-color);
  border-radius: 20px;
  font: normal normal bold 18px/20px Charter;
  letter-spacing: -0.27px;
  color: #ffffff;
}

@media screen and (max-width: 512px) {
  .year-selector-grid-container {
    display: grid;
    grid-template-columns: repeat(6, 20px);
    padding: 10px;
    gap: 20px;
    justify-self: center;
  }
}
