.year-plan-activities-selector-card-container {
  cursor: pointer;
  border-style: none;
  border-radius: 10px;
  padding-bottom: 5px;
  font-family: "Roboto";
}
.year-plan-activities-selector-card-container:hover {
  border-bottom-style: solid;
  border-top-style: solid;
  border-color: var(--green-theme-color);
}
.year-plan-activities-selector-card-description {
  font-size: 18px;
  font-weight: bolder;
}
.year-plan-activities-selector-card-description-hint {
  /* font-size: 12px;
  font-weight: bold; */
  color: grey;
}
