.products-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(279px, 1fr));
  gap: 20px;
  grid-auto-flow: dense;

  justify-items: center;
}

.product {
  cursor: pointer;
  width: 279px;
  height: 450px;
  padding: 10px;
  background: transparent;
  border-style: none;
  border-radius: 30px;
}
.product:hover {
  border-style: double;
  border-color: var(--green-theme-color);
}

.product {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  gap: 10px;
  grid-template-rows: repeat(12, 1fr);
}

.product-header {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 35px;

  grid-column: 1 / 12;
  grid-row: 1 / 2;
}
.product-picture {
  grid-column: 1 / 12;
  grid-row: 3;
}
.product-picture img {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
  border-radius: 30%;
}

.product-body {
  font-family: "poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  line-height: 26px;

  grid-column: 1 / 12;
  grid-row: 6/10;
}
.product-footer {
  grid-column: 1 / 12;
  grid-row: 12;

  display: grid;
  grid-template-columns: repeat(12, 1fr);
}

.product-status {
  width: auto;
  height: 23px;
  border-radius: 10px;
  background-color: var(--pinki-theme-color);
  color: #ffffff;
  text-align: center;
  font-size: 16px;

  grid-column: 12;
}

.active-product-status {
  width: 79px;
  height: 23px;
  border-radius: 10px;
  background-color: var(--green-theme-color);
  color: #ffffff;
  text-align: center;
  font-size: 16px;

  grid-column: 12;
}

.available-product-status {
  width: 79px;
  height: 23px;
  border-radius: 10px;
  background-color: var(--orange-theme-color);
  color: #ffffff;
  text-align: center;
  font-size: 16px;

  grid-column: 12;
}
.available-product-status:hover {
  background-color: var(--green-theme-color);
}
