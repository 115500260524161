.log-out-link {
	position: relative;
}

.log-out-link-home-container {
	display: grid;
	grid-template-columns: repeat(auto-fill, auto);
	justify-content: start;

	gap: 5px;
	padding-left: 10px;
	cursor: pointer;
}
.log-out-link-home-container:hover {
	color: var(--green-theme-color);
}

.log-out-link-home-icon {
	grid-column: 1;
	grid-row: 1;
	color: var(--green-theme-color);
	font-weight: bolder;
	font-size: 36px;
}

.log-out-link-home-text {
	grid-column: 2;
	grid-row: 1;
	padding-left: 10px;

	/* font: normal normal normal 17px Charter;
  letter-spacing: -0.34px; */
	align-self: center;
}
