@import url("https://fonts.googleapis.com/css2?family=Amaranth&display=swap");
@import url("https://fonts.googleapis.com/css2?family=ABeeZee&family=Amaranth&display=swap");
@import url("https://fonts.googleapis.com/css2?family=ABeeZee&family=Amaranth&family=Fasthand&display=swap");
@import url("https://fonts.googleapis.com/css2?family=ABeeZee&family=Alex+Brush&family=Amaranth&family=Fasthand&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;400&family=Roboto:wght@100;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;400&family=Roboto:wght@100&display=swap");

.footer {
	position: fixed;
	width: 100%;
	padding: 10px 10px 0px 10px;
	bottom: 0%;
	background: #d4d4d4;
}

.spinner {
	animation: spin infinite 5s linear;
}

.basic-page-title {
	font-family: "Alex Brush";
	font-style: normal;
	font-weight: 400;
	font-size: 46px;

	align-items: center;

	color: #000000;

	text-align: center;
}
@keyframes spin {
	from {
		transform: rotate(0deg);
	}

	to {
		transform: rotate(360deg);
	}
}

.datatable-container {
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(279px, 1fr));
	gap: 20px;
	grid-auto-flow: dense;

	justify-items: center;
}

.datatable-edit-btn {
	height: 23px;
	left: 12px;
	right: 188px;
	width: 70px;
	bottom: 27px;

	color: #1762a7;

	cursor: pointer;

	grid-column: 1;
	grid-row: 8;
}

.datatable {
	border-radius: 20px;
}

.filter-button-btn {
	cursor: pointer;
	color: var(--orange-theme-color);
	text-decoration: underline;
}
.show-filter-button-btn {
	cursor: pointer;
	font-size: 40px;
	color: var(--green-theme-color);
}

.page-menu-container {
	display: grid;
	grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
	justify-items: center;
	gap: 10px;

	padding-bottom: 15px;
}
.page-menu-container {
	display: grid;
	grid-template-columns: repeat(auto-fill, minmax(50px, 1fr));
	justify-items: center;
	gap: 10px;

	padding-bottom: 15px;
}

.no-results-found {
}
