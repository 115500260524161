.planned-activity-plan-container {
	display: grid;
	grid-template-columns: repeat(auto-fit, auto);

	padding: 10px;
	background: #ffffff;
	border-style: none;
	border-radius: 30px;

	border-bottom-style: solid;
	border-top-style: solid;
	border-color: var(--green-theme-color);
	overflow-y: auto;
}

.planned-activity-plan-card {
	display: grid;
	grid-template-columns: repeat(auto-fill, auto);

	font-family: "Poppins";
	font-size: 17px;
}
.planned-activity-plan-card {
	border-style: none;
	border-radius: 10px;

	border-style: none;
}
.planned-activity-plan-card:hover {
	border-bottom-style: solid;
	border-top-style: solid;
	border-color: var(--green-theme-color);
}

.planned-activity-plan-card-btn-container {
	display: grid;
	grid-template-columns: repeat(auto-fill, auto);
}
.planned-activity-plan-card-btn-edit {
	color: blue;
	justify-self: end;
	cursor: pointer;
}
.planned-activity-plan-card-btn-edit:hover {
	font-weight: bold;
}

.planned-activity-plan-close-btn {
	font-family: "Roboto";
	padding-right: 10px;
	color: var(--pinki-theme-color);
	background: transparent;
	border-style: none;

	font-size: 22px;
	font-weight: bold;
	text-align: center;
	cursor: pointer;

	justify-self: flex-end;
}

.planned-activity-plan-card-date {
	font-weight: bold;
	height: 10px;
}
.planned-activity-plan-card-time {
	font-size: 12px;
}
.planned-activity-plan-card-comment {
	padding-top: 5px;
	font-size: 14px;
}
