.user-profile-icon {
	position: relative;
}

.user-profile-container {
	display: grid;
	grid-template-columns: repeat(auto-fit, auto);

	padding: 10px;
	background: #ffffff;
	border-style: none;
	border-radius: 30px;

	border-bottom-style: solid;
	border-top-style: solid;
	border-color: var(--green-theme-color);
	overflow-y: auto;

	font-family: "Poppins";
	font-size: 17px;
}

.user-profile-row {
	display: grid;
	grid-template-columns: repeat(auto-fit, auto);
	padding-bottom: 10px;
}
.user-profile-row input {
	border-radius: 5px;
	border-style: none;
	border-bottom-style: solid;
	border-width: thin;
}
.user-profile-label {
	font-style: normal;
	font-weight: 700;

	color: #000000;
}

.user-profile-close-btn {
	font-family: "Roboto";
	padding-right: 10px;
	color: var(--pinki-theme-color);
	background: transparent;
	border-style: none;

	font-size: 22px;
	font-weight: bold;
	text-align: center;
	cursor: pointer;

	align-self: flex-end;
	justify-self: flex-end;
}

.user-profile-btn-container {
	display: grid;
	grid-template-columns: repeat(auto-fit, auto);
	padding-bottom: 10px;
}
.user-profile-edit-btn {
	background-color: var(--green-theme-color);

	height: 23px;
	width: 70px;

	color: #ffffff;
	border-style: none;
	border-radius: 10px;
	border-width: thin;

	font-size: small;
	text-align: center;
	cursor: pointer;

	align-self: center;
	justify-self: center;
}
.user-profile-edit-btn:hover {
	background-image: linear-gradient(
		100deg,
		var(--green-theme-color),
		var(--greensoft-theme-color)
	);
}

.user-profile-save-btn {
	background-color: var(--green-theme-color);

	height: 23px;
	width: 70px;

	color: #ffffff;
	border-style: none;
	border-radius: 10px;
	border-width: thin;

	font-size: small;
	text-align: center;
	cursor: pointer;

	align-self: center;
	justify-self: flex-end;
	grid-row: 1;
}
.user-profile-save-btn:hover {
	background-image: linear-gradient(
		100deg,
		var(--green-theme-color),
		var(--greensoft-theme-color)
	);
}

.user-profile-cancel-btn {
	background-color: var(--pinki-theme-color);

	height: 23px;
	width: 70px;

	color: #ffffff;
	border-style: none;
	border-radius: 10px;
	border-width: thin;

	font-size: small;
	text-align: center;
	cursor: pointer;

	align-self: center;
	justify-self: flex-start;
	grid-row: 1;
}
.user-profile-cancel-btn:hover {
	background-image: linear-gradient(
		100deg,
		var(--pinki-theme-color),
		var(--pinkisoft-theme-color)
	);
}

.user-profile-home-icon-container {
	display: grid;
	grid-template-columns: repeat(auto-fill, auto);
	justify-content: start;

	gap: 5px;
	padding-left: 10px;
	cursor: pointer;
}
.user-profile-home-icon-container:hover {
	color: var(--green-theme-color);
}

.user-profile-home-icon-container-icon {
	grid-column: 1;
	grid-row: 1;
	color: var(--green-theme-color);
	font-weight: bolder;
	font-size: 36px;
}

.user-profile-home-icon-container-text {
	grid-column: 2;
	grid-row: 1;
	padding-left: 10px;

	align-self: center;
}
