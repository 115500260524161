.mentorship-container {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  gap: 3px;
  background-color: var(--white-smoke-home);
}
.mentorship-left-container {
  grid-column: 1 / 3;
}
.mentorship-middle-container {
  grid-column: 3 / 10;
  padding-left: 25px;
}
.mentorship-right-container {
  grid-column: 10 / 13;

  padding-left: 30px;
  padding-right: 30px;
  background-color: white;
  height: 100%;
  border-radius: 15px;
}

.mentorship-middle-container{

  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-template-rows: repeat(12, 1fr);
  height: 100vh; 
  justify-items: center;
  gap: 3px;
  padding-top: 10px;
}

.mentorship-middle-header{
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;

  grid-row: 1 / 6;
  grid-column: 1 / 12;
  overflow: auto;
}
.mentorship-middle-bottom{
  grid-row: 6 / 12;
  grid-column: 1 / 12;
  
  overflow: auto;
}

@media screen and (max-width: 785px) {
  .mentorship-left-container,
  .mentorship-right-container {
    display: none;
  }
  .mentorship-middle-container {
    grid-column: 1 / 13;
    padding-left: 25px;
  }
}

@media screen and (max-width: 1300px) {

.mentorship-middle-header{
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 10px;

}
}