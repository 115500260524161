.planned-activity-brief-card-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, auto);

  padding: 10px;
  background: #ffffff;
  border-style: none;
  border-radius: 30px;

  border-bottom-style: solid;
  border-top-style: solid;
  border-color: var(--green-theme-color);
  overflow-y: auto;

  height: auto;
  width: 300px;
}

.planned-activity-brief-card-edit-btn {
  background-color: var(--green-theme-color);
  font-size: 15px;
  height: 23px;
  width: 70px;

  color: #ffffff;
  border-style: none;
  border-radius: 10px;
  border-width: thin;

  font-size: small;
  text-align: center;
  cursor: pointer;
}
.planned-activity-brief-card-title {
  /* font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 17px;
  line-height: 28px; */
  display: flex;
  align-items: flex-start;

  color: #605d5d;
}
