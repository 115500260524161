.weekly-schedule-ordered {
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(60px, 1fr));
	grid-auto-flow: dense;
	justify-items: center;
}
.weekly-schedule-ordered-day-column {
	width: 100%;
	padding-left: 7px;
}

.weekschedule-day-header-container {
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(60px, 1fr));
	justify-items: center;
	padding-bottom: 35px;
}

.weekly-schedule-container table th {
	position: sticky;
	top: 60px;
	background-color: var(--white-smoke-home);
	z-index: 1;
	text-align: center;
	vertical-align: middle;
}

.weekschedule-day-header-container {
	position: sticky;
	top: 60px;
	background-color: var(--white-smoke-home);
	z-index: 1;
	text-align: center;
	vertical-align: middle;
}

.weekschedule-day-header {
	font: normal normal normal 18px/20px Vidaloka;
	letter-spacing: -0.36px;
	font-weight: bolder;
	grid-row: 1;
}
.weekschedule-day-header-day {
	display: grid;
	gap: 7px;
	font: normal normal normal 13px/10px Vidaloka;
	letter-spacing: -0.36px;
	grid-row: 2;
	text-align: center;
}

.weekschedule-day-header-day-number-container {
	padding-left: 7px;
}
.weekschedule-day-header-day-number {
	border-style: solid;
	border-width: 1px;
	border-radius: 20px;
	width: 36px;
	height: 36px;
	grid-row: 1;

	background-color: var(--green-theme-color);
	color: #ffffff;
}
.weekschedule-day-header-day-number-text {
	font: normal normal normal 24px/16px Vidaloka;
	letter-spacing: -0.27px;
	padding-top: 8px;
}
.weekschedule-day-header-day-monthyear {
	grid-row: 2;
}
.weekschedule-day-header-weekend {
	color: grey;
	font: normal normal normal 18px/20px Vidaloka;
	letter-spacing: -0.36px;
	font-weight: bolder;
	grid-row: 1;
}

@media only screen and (max-width: 473px) {
	.weekly-schedule-ordered {
		display: grid;
		grid-template-columns: repeat(auto-fit, minmax(30px, 1fr));
		grid-auto-flow: dense;
		justify-items: center;
	}
}
