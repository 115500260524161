.move-week-container {
	display: grid;
	grid-template-rows: auto auto auto 1fr;
	grid-template-columns: 1fr;
	gap: 10px;
	background-color: #fff;
	padding: 20px;
	border-radius: 8px;
	box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
	max-width: 400px;
	margin: auto;
	position: relative;
}

.move-week-close-btn {
	font-family: "Roboto";
	grid-row: 1;
	justify-self: end;
	cursor: pointer;
	font-size: 1.5rem;
	color: var(--pinki-theme-color);
	font-weight: bold;
}

.move-week-btns {
	display: grid;
	text-align: center;
}
.move-week-text {
	text-align: center;
	padding-bottom: 5px;
}
.move-weekly-schedule {
	grid-row: 1;
	background-color: var(--green-theme-color);
	border-radius: 10px;
	width: 100px;
	font: normal normal bold 16px Charter;
	letter-spacing: -0.24px;
	color: white;
	border: none;
	cursor: pointer;
	text-align: center;
	padding-top: 2px;
	justify-self: center;
}
