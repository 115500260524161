.planned-activities-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(279px, 1fr));
  gap: 20px;
  grid-auto-flow: dense;

  justify-items: center;
}
.planner-menu-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  justify-items: center;
  gap: 10px;

  padding-bottom: 15px;
}
.planner-menu-activities {
  color: var(--orange-theme-color);
}
.planner-menu-schoolsubjects {
  color: var(--orange-theme-color);
}

.planned-activity-card {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  justify-self: center;
  height: auto;
  width: 279px;
  padding: 10px;
  background: #ffffff;
  border-radius: 30px;
  border-bottom-style: solid;
  border-top-style: solid;
  border-color: var(--green-theme-color);
}

.planned-activity-title {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 28px;
  display: flex;
  align-items: center;

  color: #000000;

  grid-column: 1 / 3;
  grid-row: 1;
}

.planned-card-label {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
}

.planned-child-title {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 17px;
  line-height: 28px;
  display: flex;
  align-items: flex-start;

  color: #605d5d;

  grid-column: 1 / 3;
  grid-row: 2;
}

.planned-comment-title {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 17px;
  line-height: 28px;
  display: flex;
  align-items: flex-start;

  color: #605d5d;

  grid-column: 1 / 3;
  grid-row: 3;
}

.planned-activity-date-label {
  grid-column: 1;
  grid-row: 4;
}
.planned-activity-date {
  font-size: 14px;
  padding-left: 7px;
  grid-column: 2;
  grid-row: 4;
}
.planned-activity-from-time-label {
  grid-column: 1;
  grid-row: 5;
}
.planned-activity-from-time {
  font-size: 14px;
  padding-left: 7px;
  grid-column: 2;
  grid-row: 5;
}

.planned-activity-to-time-label {
  grid-column: 1;
  grid-row: 6;
}
.planned-activity-to-time {
  font-size: 14px;
  padding-left: 7px;
  grid-column: 2;
  grid-row: 6;
}

.planned-activity-edit-btn {
  height: 23px;
  left: 12px;
  right: 188px;
  width: 70px;
  bottom: 27px;

  color: #ffffff;
  border-style: none;
  border-radius: 10px;
  border-width: thin;

  font-size: small;
  text-align: center;
  cursor: pointer;

  align-self: flex-end;
  justify-self: flex-end;

  grid-column: 1;
  grid-row: 7;
}

.planned-activity-delete-btn {
  height: 23px;
  left: 12px;
  right: 188px;
  width: 70px;
  bottom: 27px;

  color: red;
  background: transparent;
  border-style: none;
  border-radius: 10px;
  border-width: thin;

  cursor: pointer;

  align-self: flex-start;
  justify-self: center;

  grid-column: 3;
  grid-row: 5;
}

.planner-container {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  gap: 20px;
}
.planner-menu {
  text-align: center;
}

.react-calendar {
  width: 790px;
  border-radius: 30px;
  text-align: center;
}
.planner-list {
  justify-items: start;
}
.calendar-container {
  padding-bottom: 12px;
  grid-column: 1 / 12;
  place-self: center;
}

@media only screen and (max-width: 860px) {
  .react-calendar {
    width: 400px;
  }
}
