.schoolSubjects-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(279px, 1fr));
  gap: 20px;
  grid-auto-flow: dense;

  justify-items: center;
}

.schoolSubject-card {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  justify-self: center;
  height: 100px;
  width: 279px;
  padding: 10px;
  background: #ffffff;
  border-radius: 15px;
  border-bottom-style: solid;
  border-top-style: solid;
  border-color: var(--green-theme-color);
}

.schoolSubject-title {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 28px;
  display: flex;
  align-items: center;

  color: #000000;

  grid-column: 1 / 3;
  grid-row: 2;
}

.schoolSubject-label {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 17px;

  color: #000000;
}
.schoolSubject-subject {
  grid-column: 1;
  grid-row: 5;
}

.schoolSubject-edit-btn {
  height: 23px;
  left: 12px;
  right: 188px;
  width: 70px;
  bottom: 27px;

  color: #1762a7;

  cursor: pointer;

  grid-column: 1;
  grid-row: 8;
}

.schoolSubjects-filters-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  gap: 20px;
  grid-auto-flow: dense;
  justify-items: center;

  text-align: center;
  width: 100%;
  bottom: 5%;
  padding-bottom: 20px;

  color: #d4d4d4;
}

.filter-input {
  font-size: 16px;
  border: none;

  border-radius: 20px;
  border-bottom: 1px;
  border-bottom-style: solid;
  border-bottom-color: var(--green-theme-color);
}

.add-button-btn {
  width: 100%;
  text-align: center;
  cursor: pointer;
  color: var(--orange-theme-color);
  text-decoration: underline;
  padding-bottom: 10px;
}
