.mark-activity-as-completed-icon-container {
	align-items: center;
	cursor: pointer;
}
.mark-activity-as-completed-icon-none {
	color: gray;
	font-weight: bolder;
}
.mark-activity-as-completed-icon-completed {
	color: green;
	font-weight: bolder;
}
