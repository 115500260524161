.year-planner-container {
	display: grid;
	grid-template-columns: repeat(12, 1fr);
	grid-template-rows: repeat(12, 1fr);
	height: 100vh;
	justify-items: center;
	gap: 3px;
}
.year-planner-upper-container {
	grid-row: 1;
	width: 100%;
}
.year-planner-lower-container {
	grid-row: 2 / 12;
	grid-column: 1 / 12;
	width: 100%;
}

.year-planner-upper-children {
	grid-column: 1;
	grid-row: 1;
	padding-top: 40px;
}
.year-planner-upper-select-student-label {
	width: 200px;
	padding-bottom: 10px;
}

.year-planner-upper-form-row {
	display: grid;
	gap: 7px;
	grid-template-columns: repeat(12, 1fr);
	width: 400px;
	padding-top: 10px;
}
.year-planner-upper-form-label {
	grid-row: 1;
	grid-column: 1;

	justify-self: start;
	grid-column: 1;
	text-align: left;
	font: normal normal bold 16px/14px Charter;
	letter-spacing: -0.24px;
	color: #191919;
	padding-top: 10px;
	align-self: center;
}
.year-planner-upper-form-label-input {
	grid-row: 1;
	grid-column: 2 / 11;
	/* align-self: flex-end; */
}

.year-planner-upper-children-btn {
	background-color: var(--greendark-theme-color);
	border-radius: 10px;
	justify-self: end;
	width: 70px;
	font: normal normal bold 26px Charter;
	letter-spacing: -0.24px;
	color: white;
	border: none;
	cursor: pointer;
	text-align: center;
}

.year-planner-upper-select-student {
	grid-row: 2;
	grid-column: 1 / 12;
}

.year-planner-upper-select-student-selected {
	font: normal normal normal 18px/18px Vidaloka;
	letter-spacing: -0.36px;
	padding-bottom: 10px;
	grid-column: 1;
	grid-row: 2;
	color: rgb(102, 101, 101);
}

@media screen and (max-width: 512px) {
	.year-planner-upper-form-row {
		width: 100%;
	}
	.year-planner-lower-container {
		grid-row: 2;
		grid-column: 1 / 12;
		width: 100%;
		overflow: unset;
	}

	/* .year-planner-upper-children {
    grid-column: 1;
    grid-row: 2;
  }
  .year-planner-upper-years {
    grid-column: 1;
    grid-row: 1;
  } */
}
