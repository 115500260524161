.third-party-user-edition-container {
	padding-top: 7px;
}
.third-party-create-user-container {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: 20px;
	background-color: #f8f9fa;
	border-radius: 8px;
	box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
	width: 100%;
	max-width: 400px;
	margin: auto;
}

.third-party-create-user-form {
	display: flex;
	flex-direction: column;
	gap: 15px;
	width: 100%;
}

.third-party-create-user-form-input {
	font-size: 14px;
	padding: 10px;
	border: 1px solid #ced4da;
	border-radius: 4px;
	width: 100%;
	box-sizing: border-box;
}

.third-party-create-user-form-input:focus {
	outline: none;
	border-color: #007bff;
	box-shadow: 0 0 4px rgba(0, 123, 255, 0.5);
}

.third-party-create-user-form-save-btn-container {
	display: flex;
	justify-content: flex-end; /* Align the button to the right */
	margin-top: 20px;
}
.third-party-create-user-form-save-btn-container {
	display: flexbox;
	gap: 10px;
}

/* Save button */
.third-party-create-user-form-save-btn {
	background: var(--greendark-theme-color) 0% 0% no-repeat padding-box;
	color: white;
	border: none;
	padding: 10px 20px;
	border-radius: 4px;
	font-size: 14px;
	cursor: pointer;
	transition: background-color 0.3s ease;
}
.third-party-create-user-form-cancel-btn {
	background: grey 0% 0% no-repeat padding-box;
	color: white;
	border: none;
	padding: 10px 20px;
	border-radius: 4px;
	font-size: 14px;
	cursor: pointer;
	transition: background-color 0.3s ease;
}
