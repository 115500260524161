.playlist-card-container {
  display: grid;
  padding-bottom: 5px;

  width: 279px;
  height: 320px;
  border-radius: 10px;
}
.playlist-card-container:hover {
  border-style: double;
  border-color: var(--green-theme-color);
}

.playlist-card-thumbnail {
  grid-row: 1;
}

.playlist-card-thumbnail img {
  width: 100%;
  border-radius: 3%;
}

.playlist-card-title {
  grid-row: 2;

  font-size: 15px;
  font-family: "Roboto", "Arial", sans-serif;
  font-weight: bold;
}
.playlist-card-description {
  grid-row: 3;

  font-weight: lighter;
  font-size: 15px;
  font-family: "Roboto", "Arial", sans-serif;
}

.playlist-card-cart {
  grid-row: 4;
  align-self: flex-end;
  justify-self: start;
  background-color: var(--green-theme-color);
}

.playlist-card-watch {
  grid-row: 4;
  align-self: flex-end;
  justify-self: end;
  background-color: var(--orange-theme-color);
}

.playlist-btn {
  height: 23px;
  left: 12px;
  right: 188px;
  width: 80px;
  bottom: 27px;
  font-size: 15px;
  font-family: "Roboto", "Arial", sans-serif;
  color: #ffffff;
  border-style: none;
  border-radius: 10px;
  border-width: thin;

  cursor: pointer;
}
