.edit-activities-container {
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(279px, 1fr));
	gap: 20px;
	grid-auto-flow: dense;

	justify-items: center;
}

.edit-activity-card {
	display: grid;
	grid-template-columns: repeat(12, 1fr);
	grid-template-rows: repeat(12, 22px);
	justify-self: center;

	width: 279px;
	padding: 10px;
	background: #ffffff;
	border-radius: 30px;
}

.edit-activity-title {
	font-family: "Roboto";
	font-style: normal;
	font-weight: 700;
	font-size: 24px;
	line-height: 28px;
	display: flex;
	align-items: center;

	color: #000000;

	grid-column: 1 / 3;
	grid-row: 1;
}

.edit-new-activity-title {
	width: 100%;
	border-radius: 8px;
	background-color: rgba(255, 166, 0, 0.683);
	border-style: none;
	text-indent: 10px;
	font-family: "Roboto";
	font-style: normal;
	font-weight: 700;
	font-size: 21px;
	line-height: 28px;
	display: flex;
	align-items: center;

	color: #000000;

	grid-column: 2;
	grid-row: 1;
}

.edit-activity-label {
	font-family: "Poppins";
	font-style: normal;
	font-weight: 700;
	font-size: 17px;

	color: #000000;
}

.edit-activity-activity {
	padding-right: 10px;
	grid-column: 1;
	grid-row: 1;
}

.edit-activity-subject {
	padding-right: 10px;
	grid-column: 1;
	grid-row: 4;
}

.edit-subject-content {
	border-radius: 8px;
	background-color: rgba(255, 166, 0, 0.683);
	border-style: none;
	text-indent: 10px;

	width: 180px;
	grid-column: 2;
	grid-row: 4;
}

.edit-activity-frequency {
	padding-right: 10px;
	grid-column: 1;
	grid-row: 6;
}

.edit-frequency-content {
	border-radius: 8px;
	background-color: rgba(255, 166, 0, 0.683);
	border-style: none;
	text-indent: 10px;

	width: 180px;
	grid-column: 2;
	grid-row: 6;
}

.edit-activity-create-btn {
	height: 23px;
	left: 12px;
	right: 188px;
	width: 70px;
	bottom: 27px;

	color: white;
	border-style: none;
	border-radius: 10px;
	border-width: thin;

	cursor: pointer;

	grid-column: 2;
	grid-row: 8;
	align-self: flex-end;
	justify-self: end;
}

.edit-activity-save-btn {
	height: 23px;
	left: 12px;
	right: 188px;
	width: 70px;
	bottom: 27px;

	color: #ffffff;
	border-style: none;
	border-radius: 10px;
	border-width: thin;

	cursor: pointer;

	grid-column: 2;
	grid-row: 8;
	align-self: flex-end;
	justify-self: flex-end;
}

.edit-activity-delete-btn {
	height: 23px;
	left: 12px;
	right: 188px;
	width: 70px;
	bottom: 27px;

	color: red;
	background: transparent;
	border-style: none;
	border-radius: 10px;
	border-width: thin;

	cursor: pointer;

	grid-column: 2;
	grid-row: 8;
	align-self: flex-start;
	justify-self: flex-start;
}

.edit-activity-close-btn {
	font-family: "Roboto";
	height: 23px;
	left: 12px;
	right: 188px;
	width: 70px;
	bottom: 27px;

	color: #ffffff;
	border-style: none;
	border-radius: 10px;
	border-width: thin;

	cursor: pointer;

	grid-column: 1;
	grid-row: 8;
	align-self: flex-end;
	justify-self: start;
}
