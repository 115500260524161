.coupon-component-container {
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 20px;
}

.coupon-component-close-btn {
	font-family: "Roboto";
	padding-right: 10px;
	color: var(--pinki-theme-color);
	background: transparent;
	border-style: none;

	font-size: 22px;
	font-weight: bold;
	text-align: center;
	cursor: pointer;

	align-self: flex-end;
	justify-self: flex-end;
}

.coupon-component-close-btn:hover {
	color: #444;
}

.coupon-input-section {
	display: flex;
	margin-top: 15px;
	width: 100%;
	justify-content: center;
	align-items: center;
	gap: 10px;
}

.coupon-input {
	padding: 10px;

	width: 100%;
	border: 1px solid #ccc;
	border-radius: 4px;
	margin-right: 10px;
}

.apply-coupon-btn {
	padding: 10px 15px;
	font-size: 14px;
	background-color: var(--greendark-theme-color);
	color: white;
	border: none;
	border-radius: 4px;
	cursor: pointer;
	transition: background-color 0.3s;
}
