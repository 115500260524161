.share-btns-div {
	cursor: pointer;
	display: flex;
	height: 50px;
	align-items: center;
	font-size: 1.25rem;
	text-transform: capitalize;
	color: var(--clr-grey-3);
	transition: var(--transition);
	letter-spacing: var(--spacing);
	text-decoration: none;

	padding-left: 20px;
}

.sidebar-share-div {
	padding-bottom: 20px;
}
.share-btns-div {
	display: grid;
	grid-template-columns: repeat(auto-fit, auto);
}
.share-btns-div-text {
	grid-row: 1;
}
.share-btns-div-text-icon {
	padding-left: 10px;
}
.share-btns-div-text-share {
	padding-left: 18px;
}

.share-btns-div-btns {
	grid-row: 2;
}
