@import url("https://fonts.googleapis.com/css?family=Poppins:200,300,400,500,,600,700");
@import url("https://fonts.googleapis.com/css2?family=Newsreader:200,300,400,500,,600,700");
@import url("https://fonts.googleapis.com/css2?family=Alegreya:ital,wght@0,400..900;1,400..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lora:ital,wght@0,400..700;1,400..700&display=swap");

*,
*::before,
*::after {
	box-sizing: border-box;
}

:root {
	/* dark shades of primary color*/
	--clr-primary-1: hsl(205, 86%, 17%);
	--clr-primary-2: hsl(205, 77%, 27%);
	--clr-primary-3: hsl(205, 72%, 37%);
	--clr-primary-4: hsl(205, 63%, 48%);
	/* primary/main color */
	--clr-primary-5: orange;
	/* lighter shades of primary color */
	--clr-primary-6: hsl(205, 89%, 70%);
	--clr-primary-7: hsl(205, 90%, 76%);
	--clr-primary-8: hsl(205, 86%, 81%);
	--clr-primary-9: hsl(205, 90%, 88%);
	--clr-primary-10: hsl(205, 100%, 96%);
	/* darkest grey - used for headings */
	--clr-grey-1: hsl(209, 61%, 16%);
	--clr-grey-2: hsl(211, 39%, 23%);
	--clr-grey-3: hsl(209, 34%, 30%);
	--clr-grey-4: hsl(209, 28%, 39%);
	/* grey used for paragraphs */
	--clr-grey-5: hsl(210, 22%, 49%);
	--clr-grey-6: hsl(209, 23%, 60%);
	--clr-grey-7: hsl(211, 27%, 70%);
	--clr-grey-8: hsl(210, 31%, 80%);
	--clr-grey-9: hsl(212, 33%, 89%);
	--clr-grey-10: hsl(210, 36%, 96%);
	--clr-white: #fff;
	--clr-red-dark: #bb2525;
	--clr-red-light: hsl(360, 71%, 66%);
	--clr-green-dark: hsl(125, 67%, 44%);
	--clr-green-light: hsl(125, 71%, 66%);
	--clr-black: #222;
	--transition: all 0.3s linear;
	--spacing: 0.1rem;
	--radius: 0.25rem;
	--light-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
	--dark-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
	--max-width: 1170px;
	--fixed-width: 620px;
	--pinki-theme-color: #f27475;
	--pinkisoft-theme-color: #eed2d2;
	--orange-theme-color: #ffa500;
	--orangesoft-theme-color: #ffc04d;
	--green-theme-color: #98b5a4;
	--greensoft-theme-color: #cddfd7;
	--greendark-theme-color: #517b69;
	--bluemid-theme-color: #3366ff;
	--white-smoke-home: rgb(240, 238, 238);
	--link-color: rgb(5, 99, 193);
	--grey-text-color: rgba(131, 133, 136, 0.877);
}

.safe-html-component {
	margin: 0;
	padding: 0;
}

.safe-html-component p {
	margin: 0;
	padding: 0;
}

.ql-container {
	margin: 0;
	padding-top: 0;
	padding-bottom: 0;
}

.ql-editor {
	margin: 0;
	padding-top: 0;
	padding-bottom: 0;
}

body {
	padding: 0;
	margin: 0;
	background-color: #ffffff;
	font-family: Poppins, sans-serif;
	font-size: 1.2rem;
	color: black;
	min-height: 100vh;
}

.container {
	max-width: 1880px;
	margin: 0 auto;
	padding: 0 25px;
}

.btn {
	color: #ffffff;
	background: #c66868;
	padding: 0.75rem 1.5rem;
	border-radius: 10px;

	font-family: "Roboto";
	font-style: normal;
	font-weight: 500;
	font-size: 24px;
	line-height: 28px;
}

.btn:hover {
	background: #9bbeaf;
	cursor: pointer;
}

.display-none {
	display: none;
}

.basic-btn {
	color: #ffffff;
	background: #c66868;
	padding: 0.75rem 1.5rem;
	border-radius: 10px;

	font-size: 15px;
	background-image: linear-gradient(
		100deg,
		var(--pinki-theme-color),
		var(--pinkisoft-theme-color)
	);
}
.basic-btn:hover {
	background-image: linear-gradient(
		100deg,
		var(--green-theme-color),
		var(--greensoft-theme-color)
	);
	font-size: 25px;
}

.basic-edition-btn {
	background-color: var(--green-theme-color);
	background-image: linear-gradient(
		100deg,
		var(--pinki-theme-color),
		var(--greenSoft-theme-color)
	);
	font-size: 15px;
}
.green-btn-hover:hover {
	background-image: linear-gradient(
		100deg,
		var(--green-theme-color),
		var(--greensoft-theme-color)
	);
}
.basic-edition-btn:hover {
	background-image: linear-gradient(
		100deg,
		var(--green-theme-color),
		var(--greensoft-theme-color)
	);
	cursor: pointer;
}
.hover-effect:hover {
	transform: translateY(-5px);
	text-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}
.print-only {
	display: none;
}
.hide-weekend {
	display: none;
}
.sortable-item {
	transition: transform 200ms ease;
	will-change: transform;
}
.borderless-quill .ql-container.ql-snow {
	border: none;
}

.borderless-quill .ql-toolbar.ql-snow {
	border-style: none;
	border-bottom: 1px solid #ccc;
}

@media print {
	.do-not-print {
		display: none;
	}
	.print-only {
		display: inline-block;
	}
}
