

.mentorship-card-container{
   display: grid;
   gap: 10px;
}
.mentorship-card-picture{
  grid-column: 1;
  grid-row: 1 / 3;
  align-self: flex-start;
}
.mentorship-card-picture img {
  width: 120px;
  border-radius: 5%;
 
}
.mentorship-card-description{
  grid-column: 2;
    grid-row: 1 / 2;
}
.mentorship-card-add-btn-container{
    grid-row: 3;
    width: 100%;
    text-align: end;
}
.mentorship-card-description-title{
 
}
.mentorship-card-description-desc{
  font: normal normal bold 18px/20px Charter;
  letter-spacing: -0.27px;
  color: grey;
  align-self: center;
}


.mentorship-card-add-btn{

    color: white;
    font: normal normal bold 15px/20px Charter;
    cursor: pointer;
    align-self: center;
    justify-self: center;
    width: 180px;
    background: #98b5a4 0% 0% no-repeat padding-box;
    border-radius: 10px;
    text-align: center;
    padding-top: 10px;
    padding-bottom: 10px;
}