.signup-grid-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
  gap: 10px;
  grid-auto-flow: row;
}

.signup-title-text {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 75px;
  text-align: left;
  color: #c66868;
}

.signup-paragraph-text {
  font-family: "Roboto";
  font-style: normal;
  text-align: left;
  font-weight: 700;
  font-size: 55px;

  color: #000000;
}

.signup_form {
  width: 450px;
  height: auto;
  background: #e7e7e7;
  border-radius: 20px;
  padding-bottom: 10px;
}

.form-input {
  height: 30px;
  font-size: 20px;

  margin-left: 3%;
  width: 400px;
  border: 2px solid #1762a7;
  border-radius: 10px;
  color: #c66868;
}

.form-label {
  margin-top: 5%;
  margin-left: 3%;
  width: 400px;

  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  display: flex;
  align-items: center;

  color: #000000;
}

.pwd-confirm-error {
  margin-top: 5%;
  margin-left: 3%;
  width: auto;

  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: auto;
  display: flex;

  justify-self: center;
  text-align: center;
  color: red;
}

.signup-btn {
  color: #ffffff;
  background: #c66868;
  padding: 0.75rem 1.5rem;
  border-radius: 10px;

  font-size: 15px;
  background-image: linear-gradient(
    100deg,
    var(--pinki-theme-color),
    var(--pinkisoft-theme-color)
  );
  font-weight: 600;
}

.signup-btn-container {
  padding-top: 20px;
  text-align: center;
}
.signup-btn {
  border-style: none;
  font-family: "Roboto";
  color: #ffffff;
  background: #c66868;
  padding: 0.75rem 1.5rem;
  border-radius: 10px;

  font-size: 24px;
  background-image: linear-gradient(
    100deg,
    var(--pinki-theme-color),
    var(--pinkisoft-theme-color)
  );

  cursor: pointer;
}

.signup-btn:hover {
  background-image: linear-gradient(
    100deg,
    var(--green-theme-color),
    var(--greensoft-theme-color)
  );
  font-size: 25px;
}

.signup-loading-label {
  margin-top: 5%;
  margin-left: 3%;
  width: auto;

  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  align-items: center;
  text-align: center;

  color: #ff0000;

  justify-self: center;
}

/*grid*/
.signup-title-text {
  grid-column: 1 / 6;
  grid-row: 1 / 5;
}

.signup-paragraph-text {
  grid-column: 1 / 6;
  grid-row: 6 / 12;
}

.signup_form {
  grid-column: 7 / 12;
  grid-row: 1 / 12;
  justify-self: end;
}

/*grid*/

@media only screen and (max-width: 1030px) {
  .form-label {
    margin-left: 5%;
  }
  .signup-title-text {
    grid-column: 1 / 12;
    grid-row: 1;
    justify-self: center;
    text-align: center;
  }

  .signup-paragraph-text {
    grid-column: 1 / 12;
    grid-row: 2;
    justify-self: center;
    text-align: start;
  }

  .signup_form {
    grid-column: 1 / 10;
    grid-row: 3;
    justify-self: center;
    text-align: center;
  }
}

@media only screen and (max-width: 589px) {
  .signup-grid-container {
    grid-template-columns: repeat(auto-fill, minmax(50px, 1fr));
  }

  .signup-title-text {
    font-size: 35px;
  }

  .signup-paragraph-text {
    font-size: 30px;
  }

  .form-label {
    margin-left: 5%;
    font-size: 20px;
  }
  .form-input {
    width: 420px;
  }
  .signup-btn {
    font-size: 20px;
  }
}
