.progressbar-section {
  display: grid;
  grid-template-columns: repeat(auto-fill, auto);
  gap: 5px;
  grid-auto-flow: dense;
}

.progress-bar-slider {
  color: transparent;
  border-style: none;
}

.progress-bar-slider input {
  width: 100%;

  color: red;
  border-style: none;
}

.progressbar-section-time {
  grid-column: 1;
  grid-row: 1;
}

.progressbar-section-tracker {
  grid-column: 1;
  grid-row: 2;
}

.progressbar-section-controller {
  grid-column: 1;
  grid-row: 2;
}
