.planner-year-main-component-container {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  gap: 3px;
  background-color: var(--white-smoke-home);
}
.planner-year-main-component-left-container {
  grid-column: 1 / 3;
}
.planner-year-main-component-middle-container {
  grid-column: 3 / 10;
  padding-left: 25px;
}
.planner-year-main-component-right-container {
  grid-column: 10 / 13;

  padding-left: 30px;
  padding-right: 30px;
  background-color: white;
  height: 100%;
  border-radius: 15px;
}

@media screen and (max-width: 785px) {
  .planner-year-main-component-left-container,
  .planner-year-main-component-right-container {
    display: none;
  }
  .planner-year-main-component-middle-container {
    grid-column: 1 / 13;
    padding-left: 25px;
  }
}

