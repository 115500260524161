.children-selector-container {
	display: grid;
	grid-template-columns: repeat(auto-fit, auto);

	padding: 10px;
	background: #ffffff;
	border-style: none;
	border-radius: 30px;

	border-bottom-style: solid;
	border-top-style: solid;
	border-color: var(--green-theme-color);
	overflow-y: auto;
}
.children-selector-icon-container {
	display: grid;
	grid-template-columns: repeat(auto-fit, auto);
	text-align: center;
	padding-left: 30px;
	cursor: pointer;
}
.children-selector-text {
	font-size: 10px;
	font-weight: bold;
}
.activity-options-item {
	display: grid;
	grid-template-columns: repeat(auto-fit, auto);
}

.children-selector-icon {
	font-size: 50px;
	cursor: pointer;
	color: var(--green-theme-color);
}

.children-selector-close-btn {
	font-family: "Roboto";
	padding-right: 10px;
	color: var(--pinki-theme-color);
	background: transparent;
	border-style: none;

	font-size: 22px;
	font-weight: bold;
	text-align: center;
	cursor: pointer;

	align-self: flex-end;
	justify-self: flex-end;
}

.children-selector-card-container {
	display: grid;
	grid-template-columns: repeat(auto-fill, auto);
}
.children-selector-card {
	display: grid;
	grid-template-columns: repeat(auto-fit, auto);
	align-items: center;
	justify-content: start;
	gap: 7px;
	cursor: pointer;
	border-style: none;
	border-radius: 10px;

	border-style: none;
}
.children-selector-card:hover {
	border-bottom-style: solid;
	border-top-style: solid;
	border-color: var(--green-theme-color);
}

.children-selector-card-icon {
	padding-top: 10px;
	grid-row: 1;
}
.children-selector-card-name {
	grid-row: 1;
}
