/*New PlannedActivity*/
.edit-new-planned-activity-frequency-label {
  grid-column: 1;
  grid-row: 3;
}

.edit-new-planned-activity-frequency-content-container {
  padding-left: 14px;
  grid-column: 2;
  grid-row: 3;
}

.edit-new-planned-activity-WeekdaysSelector-content-container {
  padding-top: 7px;
  grid-column: 2;
  grid-row: 4;
}

.edit-new-planned-activity-frequency-dropdown {
  min-width: 100px;
  background-color: var(--orangesoft-theme-color);
  height: 26px;
  border-style: none;
  border-radius: 10px;
}

.edit-new-planned-activity-from-label {
  grid-column: 1;
  grid-row: 5;
}
.edit-new-planned-activity-from-date {
  padding-left: 14px;
  grid-column: 2;
  grid-row: 5;
}

.edit-new-planned-activity-to-label {
  grid-column: 1;
  grid-row: 6;
}
.edit-new-planned-activity-to-date {
  padding-left: 14px;
  grid-column: 2;
  grid-row: 6;
}

.edit-new-planned-activity-from-time-label {
  grid-column: 1;
  grid-row: 7;
}
.edit-new-planned-activity-from-time {
  padding-left: 7px;
  grid-column: 2;
  grid-row: 7;
}

.edit-new-planned-activity-to-time-label {
  grid-column: 1;
  grid-row: 8;
}
.edit-new-planned-activity-to-time {
  padding-left: 7px;
  grid-column: 2;
  grid-row: 8;
}

.edit-planned-activity-create-btn {
  height: 23px;
  left: 12px;
  right: 188px;
  width: 70px;
  bottom: 27px;

  color: #ffffff;
  border-style: none;
  border-radius: 10px;
  border-width: thin;

  font-size: small;
  text-align: center;
  cursor: pointer;

  grid-column: 3;
  grid-row: 9;
  align-self: flex-end;
  justify-self: flex-end;
}

.react-datetime-picker__wrapper {
  border-style: none;
  border-radius: 7px;
  font-size: 14px;
}
.planner-datetime-picker {
  border-radius: 7px;
  background-color: var(--orangesoft-theme-color);
}
.edit-planned-row-container-btn {
  grid-row: 7;
  padding-top: 20px;
}

.edit-new-planned-activity-comment-content {
  width: 170%;
  border-radius: 8px;
  background-color: rgba(255, 166, 0, 0.683);
  border-style: none;
  text-indent: 10px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
  display: flex;
  align-items: center;

  color: #000000;

  grid-column: 2;
  grid-row: 1;
}
.edit-planned-row-container-comments {
  padding-top: 15px;
}
