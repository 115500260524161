.new-year-activity-note-card {
	display: grid;
	grid-template-columns: repeat(auto-fit, auto);

	padding: 10px;
	background: #ffffff;
	border-style: none;
	border-radius: 30px;

	border-bottom-style: solid;
	border-top-style: solid;
	border-color: var(--green-theme-color);
}

.year-activities-table-add-activity {
	color: white;
	cursor: pointer;
	font-size: 22px;
}

.new-year-activity-note-card-text-container {
	align-self: center;
	justify-self: center;
}
.new-year-activity-note-card-text-area {
	resize: none;
	border-radius: 7px;
	font-size: 12px;
}

.new-year-activity-note-card-close-btn {
	font-family: "Roboto";
	padding-right: 10px;
	color: var(--pinki-theme-color);
	background: transparent;
	border-style: none;

	font-size: 22px;
	font-weight: bold;
	text-align: center;
	cursor: pointer;

	align-self: flex-start;
	justify-self: flex-end;
}

.new-year-activity-note-card-create-btn {
	background-color: var(--green-theme-color);

	height: 23px;
	width: 70px;

	color: #ffffff;
	border-style: none;
	border-radius: 10px;
	border-width: thin;

	font-size: small;
	text-align: center;
	cursor: pointer;

	align-self: center;
	justify-self: center;
}
.new-year-activity-note-card-create-btn:hover {
	background-image: linear-gradient(
		100deg,
		var(--green-theme-color),
		var(--greensoft-theme-color)
	);
}
