.edit-planned-activity-card {
	display: grid;
	gap: 3px;

	background: #ffffff;
	border-style: none;
	border-radius: 20px;

	overflow: auto;
	padding-right: 20px;
	overflow: auto;
}

.edit-planned-activity-title {
	display: flex;
	align-items: center;
	font: normal normal normal 25px Vidaloka;
	letter-spacing: -0.45px;
	color: #191919;
	font-weight: bold;
}

.edit-planned-activity-child {
	font: normal normal normal 20px Vidaloka;
	letter-spacing: -0.45px;
	color: var(--grey-text-color);
}
.edit-planned-activity-year {
	padding-top: 12px;
	padding-left: 5px;
	font: normal normal normal 18px Vidaloka;
	letter-spacing: -0.45px;
	color: var(--green-theme-color);
	cursor: pointer;
	width: fit-content;
	text-decoration: underline;
}
.edit-planned-activity-comment {
	font: normal normal normal 20px Vidaloka;
	letter-spacing: -0.45px;
	color: var(--grey-text-color);
}
.edit-planned-activity-comment-edit-btn-text {
	cursor: pointer;
}

.edit-planned-activity-edit-dates-container {
	display: grid;
	grid-template-columns: repeat(2, 100px);
}

.edit-planned-activity-date-input {
	grid-column: 2;
	background: #e0e9e3 0% 0% no-repeat padding-box;
	border-radius: 10px;
	justify-self: start;
	padding-left: 10px;
	height: 40px;
	width: 100px;
	font: normal normal bold 16px Charter;
	letter-spacing: -0.24px;
	color: rgb(98, 97, 97);
	border: none;
	align-self: center;
}
.edit-planned-activity-comment-edit-btn {
	cursor: pointer;
	color: var(--greendark-theme-color);
}
.edit-planned-activity-comment-edit-cancel-btn {
	cursor: pointer;
	color: var(--pinki-theme-color);
}
.react-quill-container {
	min-height: 100px;
	max-height: 500px;
	overflow-y: auto;
}
.edit-planned-activity-comment-edit-btn-comment {
	grid-row: 1;
	grid-column: 1;
}
.edit-planned-activity-comment-input {
	cursor: pointer;
	color: var(--grey-text-color);
	border-style: none;
	border-color: var(--green-theme-color);
	border-bottom-style: groove;
	width: 350px;
	height: 100%;
}

.edit-planned-card-label {
	font: normal normal normal 17px Vidaloka;
	letter-spacing: -0.45px;
	color: #191919;
	font-weight: bold;
	text-align: start;
	align-self: center;
}

.edit-planned-activity-close-btn {
	font-family: "Roboto";
	padding-right: 10px;
	color: var(--pinki-theme-color);
	background: transparent;
	border-style: none;

	font-size: 22px;
	font-weight: bold;
	text-align: center;
	cursor: pointer;

	align-self: flex-end;
	justify-self: flex-end;
}

.edit-planned-activity-footer-btn-container {
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(20px, 1fr));
	padding-top: 10px;

	justify-items: center;
}

.edit-planned-activity-add-child-btn {
	cursor: pointer;
	font-size: 22px;
	color: var(--greendark-theme-color);
}

.edit-planned-activity-remove-child-btn {
	cursor: pointer;
	font-size: 22px;
	color: red;
}

.edit-planned-activity-save-btn {
	height: 30px;
	width: 80px;

	background-color: var(--greendark-theme-color);
	letter-spacing: 1px;
	color: #ffffff;
	border-style: none;
	border-radius: 10px;
	border-width: thin;

	font-size: small;
	text-align: center;
	cursor: pointer;
	justify-self: flex-start;
	padding: 5px;
	align-self: center;
}

.edit-planned-activity-delete-btn {
	height: 23px;

	color: red;
	background: transparent;
	border-style: none;
	border-radius: 10px;
	border-width: thin;

	cursor: pointer;
	align-self: center;
}
.edit-planned-activity-add-child-icon {
	align-self: center;
}
.edit-planned-activity-duplicate {
	color: grey;
	cursor: pointer;
	font-size: 22px;
	align-self: center;
}

.edit-date-range-option {
	cursor: pointer;
	color: var(--orange-theme-color);
	font: normal normal normal 17px/17px Vidaloka;
	letter-spacing: -0.45px;
}
.edit-date-range-option:hover {
	text-decoration: underline;
	font-weight: 600;
}

.edit-date-range-option-icon {
	font-size: 20px;
}
.edit-date-range-option-text {
	color: var(--orange-theme-color);
	font: normal normal normal 16px/16px Vidaloka;
	letter-spacing: -0.45px;
}

.edit-planned-activity-date-option {
	color: var(--green-theme-color);
	font: normal normal normal 16px/16px Vidaloka;
	letter-spacing: -0.45px;
}

.edit-planned-activity-date-option-icon {
	font-size: 20px;
}
.edit-planned-activity-date-option-text {
	font-size: 12px;
}

.edit-planned-activity-time-option {
	font-size: 15px;
	color: var(--green-theme-color);
	font-weight: 500;
	padding-top: 3px;
}

.edit-planned-activity-time-option-icon {
	font-size: 20px;
}
.edit-planned-activity-time-option-text {
	font-size: 12px;
}

.edit-planned-activity-pending {
	cursor: pointer;
	color: red;
}
.edit-planned-activity-not-pending {
	cursor: pointer;
	color: grey;
}
.edit-in-various-days-optional {
	color: grey;
}
