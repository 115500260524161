.mentorship-calendar-available-container{
    padding-bottom: 10px;
    overflow: auto;    
}
.mentorship-calendar-available-title{
    font: normal normal normal 20px/53px Vidaloka;
    letter-spacing: -0.36px;
    font-weight: bolder;
}
.mentorship-calendar-available-card-container{
    display: grid;
    gap: 10px;
    font: normal normal normal 18px/20px Charter;
    letter-spacing: -0.27px;
    padding-bottom: 10px;

}
.mentorship-calendar-available-card-description-container{
    grid-column: 1;
    font-weight: bolder;
    align-self: center;
    color: grey;


     display: grid;
     grid-template-columns: repeat(auto, 30px);
     font-family: Vidaloka;
}
.mentorship-calendar-available-card-description-day{
    grid-column: 1 / 3;
    font-size: 25px;
    align-self: center;
}
.mentorship-calendar-available-card-description-day-transparent{
    color: transparent;
    grid-column: 1 / 3;
    font-size: 25px;
}
.mentorship-calendar-available-card-description-line{
    grid-column: 3;
    grid-row: 1 / 3;

    border-left: 2px solid;
    height: 100%;
    
}

.mentorship-calendar-available-card-description-month{
    grid-column: 5 / 12;
     grid-row: 1;
    align-self: flex-start;
}
.mentorship-calendar-available-card-description-hour{
    grid-column: 5 / 12;
     grid-row: 2;
}

.mentorship-calendar-available-card-book-btn{
    grid-column: 2;
    color: white;
    font: normal normal bold 15px/20px Charter;
    cursor: pointer;
    align-self: center;
    justify-self: center;
    width: 100px;
    background: #98b5a4 0% 0% no-repeat padding-box;
    border-radius: 10px;
    text-align: center;
    padding-top: 10px;
    padding-bottom: 10px;
}
.mentorship-calendar-available-card-book-btn:hover{
    text-decoration: underline;
    color: var(--link-color);
}