.year-activity-notes-container {
	display: grid;
	grid-template-columns: repeat(auto-fit, auto);

	padding: 10px;
	background: #ffffff;
	border-style: none;
	border-radius: 30px;

	border-bottom-style: solid;
	border-top-style: solid;
	border-color: var(--green-theme-color);
}
.year-activity-notes-close-btn {
	font-family: "Roboto";
	padding-right: 10px;
	color: var(--pinki-theme-color);
	background: transparent;
	border-style: none;

	font-size: 22px;
	font-weight: bold;
	text-align: center;
	cursor: pointer;

	align-self: flex-start;
	justify-self: flex-end;
}
.year-activity-notes-add-icon {
	color: var(--orange-theme-color);
	font-size: 42px;
	cursor: pointer;
}
.year-activity-notes-empty-notes-message {
	font-family: "roboto";
	font-weight: 600;
}
