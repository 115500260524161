.calendar-settings-container {
	padding: 10px;
	border-radius: 10px;
}
.calendar-settings-year-input {
	background: #e0e9e3 0% 0% no-repeat padding-box;
	border-radius: 10px;
	height: 40px;
	width: 100px;
	font: normal normal bold 16px Charter;
	letter-spacing: -0.24px;
	color: rgb(98, 97, 97);
	border: none;
	cursor: pointer;
	text-align: center;
}
.calendar-settings-year-input-text {
	padding-top: 10px;
}
.calendar-settings-label {
	padding-bottom: 5px;
}

.calendar-settings-close-btn {
	font-family: "Roboto";
	font-family: "Roboto";
	padding-right: 10px;
	color: var(--pinki-theme-color);
	background: transparent;
	border-style: none;

	font-size: 22px;
	font-weight: bold;
	text-align: center;
	cursor: pointer;

	align-self: flex-end;
	justify-self: flex-end;
	text-align: end;
}
