.report-param-container {
	display: grid;
	grid-template-columns: repeat(auto-fit, auto);

	grid-auto-flow: dense;

	justify-items: center;
	text-align: center;
}

.report-param-from-to-container {
	display: grid;
	grid-template-columns: repeat(auto-fill, auto);
	gap: 10px;
	grid-column: 1;
	justify-items: center;
	text-align: center;
}
.activity-child-report-btn {
	display: grid;
	grid-template-columns: repeat(auto-fill, auto);
	gap: 10px;
	grid-column: 1;
	justify-items: center;
	text-align: center;
}

.report-from-date-item {
	grid-column: 1;
}
.report-to-date-item {
	grid-column: 2;
}
.report-from-date {
	background-color: var(--orangesoft-theme-color);
	border-radius: 10px;
}
.report-to-date {
	background-color: var(--orangesoft-theme-color);
	border-radius: 10px;
}

.report-getreport-btn {
	width: 100px;
	height: 23px;
	border-radius: 10px;
	background-color: var(--green-theme-color);
	color: #ffffff;
	text-align: center;
	font-size: 16px;
	grid-column: 1;
	align-self: flex-end;
	cursor: pointer;
}
.report-print-item-btn {
	background-color: var(--orange-theme-color);
	border-radius: 10px;
	justify-self: end;
	width: 100px;
	font: normal normal bold 16px Charter;
	letter-spacing: -0.24px;
	color: white;
	border: none;
	cursor: pointer;
	text-align: center;
	padding-top: 2px;
}

.report-getreport-item {
	padding-top: 10px;
	grid-row: 1;
	grid-column: 1;
}
.report-print-item {
	grid-row: 1;
	grid-column: 2;
	align-self: flex-end;
}
