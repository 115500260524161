.edit-child-card {
  font-family: "Newsreader";
  display: grid;
  grid-template-columns: repeat(auto-fit, auto);

  padding: 10px;
  background: #ffffff;
  border-style: none;
  border-radius: 30px;

  border-bottom-style: solid;
  border-top-style: solid;
  border-color: var(--green-theme-color);
}

.edit-child-card-row-content {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
  padding-bottom: 10px;
  gap: 2px;
}

.edit-child-card-footer-btn-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
  padding-top: 10px;
}
.edit-child-input {
  padding-left: 10px;
  border-style: none;
  border-radius: 8px;
  background-color: var(--greensoft-theme-color);
}

.edit-child-create-btn {
  height: 23px;
  left: 12px;
  right: 188px;
  width: 70px;
  bottom: 27px;

  color: white;
  border-style: none;

  border-radius: 10px;
  border-width: thin;

  cursor: pointer;
  align-self: flex-end;
  justify-self: end;
}

.edit-child-save-btn {
  height: 23px;
  left: 12px;
  right: 188px;
  width: 70px;
  bottom: 27px;

  color: #ffffff;

  border-style: none;
  border-radius: 10px;
  border-width: thin;

  cursor: pointer;
  align-self: flex-start;
  justify-self: flex-start;
}

.edit-child-delete-btn {
  height: 23px;
  left: 12px;
  right: 188px;
  width: 70px;
  bottom: 27px;

  color: red;
  background: transparent;
  border-style: none;
  border-radius: 10px;
  border-width: thin;

  cursor: pointer;

  align-self: flex-start;
  justify-self: flex-end;
}

.edit-child-close-btn {
  font-family: "Roboto";
  padding-right: 10px;
  color: var(--pinki-theme-color);
  background: transparent;
  border-style: none;

  font-size: 22px;
  font-weight: bold;
  text-align: center;
  cursor: pointer;

  align-self: flex-end;
  justify-self: flex-end;
}
.edit-child-close-btn:hover {
  padding-right: 12px;
  color: var(--green-theme-color);
}

.childdateofbirth {
  background-color: var(--greensoft-theme-color);
  border-style: none;
  border-radius: 7px;
  font-size: 14px;
  font-weight: bold;
  width: 100px;
  padding-left: 10px;
  /* color: white; */
}
