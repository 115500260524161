.third-party-admin-users-list-container {
	padding: 5px;
}

.third-party-admin-users-list-table-container tr,
.third-party-admin-users-list-table-container th,
.third-party-admin-users-list-table-container td {
	background-color: var(--white-smoke-home);
	padding: 10px;
	margin: auto;
	border: none;
	text-align: start;
}

.third-party-admin-users-list-filter {
	border-radius: 10px;
	width: 370px;
	padding-left: 5px;
}

.third-party-admin-users-list-action-btn {
	height: 30px;
	width: 100px;
	background: var(--greendark-theme-color) 0% 0% no-repeat padding-box;
	border-radius: 10px;
	color: white;
	text-align: center;
	cursor: pointer;

	letter-spacing: 0.21px;
	line-height: 15px;
	padding: 5px;
}
.third-party-admin-users-list-students-btn {
	height: 26px;
	background: var(--greendark-theme-color) 0% 0% no-repeat padding-box;
	border-radius: 10px;
	color: white;
	text-align: center;
	cursor: pointer;

	letter-spacing: 0.21px;
	width: 80px;
	line-height: 15px;
	padding: 5px;
}

.third-party-admin-users-list-member-icon {
	color: var(--greendark-theme-color);
}
.expiring-soon-row {
	background-color: red;
}
