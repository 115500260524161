.year-activities-header-container {
	display: grid;
}
.year-activities-filter {
	grid-column: 1;
	grid-row: 2;
}
.year-activities-year {
	grid-column: 2;
	grid-row: 2;
	cursor: pointer;
	color: #1762a7;
}
.year-activities-year:hover {
	text-decoration: underline;
}

.year-activities-table-container tr,
.year-activities-table-container th,
.year-activities-table-container td {
	background-color: var(--white-smoke-home);
	padding: 10px;
	margin: auto;
	border: none;
}

.year-plan-activity-descriptionHint {
	text-align: left;
	padding-left: 4px;
	font-weight: 400;
}

.year-activity-plan-btn {
	height: 26px;
	background: var(--greendark-theme-color) 0% 0% no-repeat padding-box;
	border-radius: 10px;
	color: white;
	text-align: center;
	cursor: pointer;

	letter-spacing: 0.21px;
	width: 100px;
	line-height: 15px;
	padding: 5px;
}
.year-activities-table-headers-text {
	letter-spacing: -0.27px;
}
.year-activities-table-add-activity {
	color: var(--green-theme-color);
}
.year-plan-activity-item {
	cursor: pointer;
	text-align: left;
	padding-left: 10px;
	color: rgb(85, 84, 84);
}
.year-plan-last-planned {
	color: var(--grey-text-color);
}
.year-plan-activity-item:hover {
	color: var(--link-color);
	text-decoration: underline;
}
.year-activities-table-filter-container {
	padding-bottom: 5px;
}
.year-activities-table-filter {
	border-radius: 10px;
	width: 370px;
	padding-left: 5px;
}
.icon-doc {
	color: var(--orange-theme-color);
}

.year-activities-table {
	background-color: var(--white-smoke-home);
	border-collapse: collapse;

	width: 100%;
	table-layout: fixed;
}

.year-activities-table tr,
.year-activities-table td {
	background-color: var(--white-smoke-home);
	padding: 10px;
	margin: auto;
	border: none;
}
.year-activities-table th {
	position: sticky;
	top: 0;
	z-index: 1;
	text-align: center;
	vertical-align: middle;
	padding: 1px;
	border-bottom: 2px solid var(--green-theme-color);
}

.year-planner-automated-planning-btn {
	background-color: var(--greendark-theme-color);
	border-radius: 10px;
	justify-self: end;
	padding-left: 5px;
	padding-right: 5px;
	color: white;
	border: none;
	cursor: pointer;
	text-align: center;
}

@media screen and (max-width: 639px) {
	.year-activities-table-container {
		overflow: scroll;
	}
	.year-activities-table-filter {
		width: 250px;
	}
}
