.frequency-selector-container {
  display: grid;
  grid-template-columns: repeat(4, auto);
  gap: 5px;
  justify-self: center;
  align-items: center;
}

.frequency-selector-item-container {
  border-style: none;
  border-width: 1px;
  border-radius: 10px;
  width: 50px;
  height: 30px;
  text-align: center;
  cursor: pointer;

  background-color: var(--green-theme-color);
}
.frequency-selector-item-text {
  font: normal normal normal 13px Vidaloka;
  letter-spacing: -0.27px;
  padding-top: 7px;
}
.selected-frequency {
  background-color: var(--orange-theme-color);
  border-radius: 20px;
  font: normal normal bold 18px/20px Charter;
  letter-spacing: -0.27px;
  color: black;
}
