.login-grid-container {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  gap: 10px;
  grid-template-rows: auto;
}

.modalContainer {
  width: 520px;
  height: 500px;
  border-radius: 12px;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;

  padding: 25px;

  grid-column: 6;
  grid-row: 2;
}

.titleCloseBtn {
  grid-column: 12;
}

.titleCloseBtn button {
  width: 35px;
  height: 35px;
  background-color: transparent;
  border: none;
  font-size: 25px;
  cursor: pointer;
  color: #c66868;
}

.titleCloseBtn button:hover {
  background-color: #c66868;
  border: none;
  font-size: 25px;
  cursor: pointer;
  color: white;
  border-radius: 12px;
}

.login_form {
  grid-column: 1 / 12;
}

.login-label {
  width: auto;
  height: 10px;

  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 36px;

  color: #000000;
}

.login-input {
  width: 450px;
  height: 45px;
  font-size: 16px;
  border: 2px solid #1762a7;
  border-radius: 10px;
  color: #c66868;
  width: 100%;
  padding-left: 5px;
}

.forgot-pwd {
  width: auto;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 36px;
  align-items: center;
  text-decoration-line: underline;

  color: #c66868;
  cursor: pointer;

  grid-column: 1 / 12;
  grid-row: 3;
  justify-self: end;
}
.show-pwd-btn {
  padding-left: 5px;
  cursor: pointer;
  font-size: 20px;
  color: var(--pinki-theme-color);
}

.login-btn {
  font-family: "Roboto";

  font-weight: 600;
  font-size: 20px;
  line-height: 28px;

  border-style: none;
  grid-column: 2 / 12;
  grid-row: 8;

  color: #ffffff;
  background: #c66868;
  padding: 0.75rem 1.5rem;
  border-radius: 10px;

  background-image: linear-gradient(
    100deg,
    var(--pinki-theme-color),
    var(--pinkisoft-theme-color)
  );
}
.login-btn:hover {
  background-image: linear-gradient(
    100deg,
    var(--green-theme-color),
    var(--greensoft-theme-color)
  );
  font-size: 25px;
}

.auth-error {
  width: auto;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  align-items: center;
  text-align: center;

  color: #ff0000;

  grid-column: 1 / 12;
  grid-row: 10;
  justify-self: center;
}
.error-Item {
  color: #ff0000;
}
.error-Item-pink {
  color: rgb(245, 164, 177);
}

.loading-label {
  width: auto;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;

  align-items: center;
  text-align: center;

  color: #ff0000;
}

@media only screen and (max-width: 480px) {
  .login-label {
    font-size: 20px;
  }

  .forgot-pwd {
    font-size: 15px;
  }

  .login-btn {
    font-size: 20px;
  }

  .auth-error {
    font-size: 12px;
  }
}
