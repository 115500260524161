.year-plan-activities-selector-container {
	display: grid;
	grid-template-columns: repeat(auto-fit, auto);
	grid-auto-rows: max-content;

	padding-left: 10px;
	padding-right: 10px;
	background: #ffffff;
	border-style: none;

	border-radius: 10px;
	padding-left: 10px;
	padding-right: 10px;
	padding-bottom: 10px;
}

.year-plan-activities-selector-icon {
	color: blue;
	cursor: pointer;
}

.year-plan-activities-selector-header {
	display: grid;
	position: sticky;
	top: 0;
	background-color: #ffffff;
	padding-bottom: 5px;
}
.year-plan-activities-selector-close-btn {
	font-family: "Roboto";
	grid-row: 1;
	padding-right: 10px;
	color: var(--pinki-theme-color);
	background: transparent;
	border-style: none;

	font-size: 22px;
	font-weight: bold;
	text-align: center;
	cursor: pointer;

	align-self: flex-end;
	justify-self: flex-end;
}
.year-plan-activities-selector-filter-container {
	grid-row: 1;
	grid-column: 1;
}
.year-plan-activities-selector-filter {
	border-radius: 10px;
	padding-left: 10px;
	width: 100%;
}
