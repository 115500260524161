.membership-main-component-container {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  gap: 3px;
  background-color: var(--white-smoke-home);
}
.membership-main-component-left-container {
  grid-column: 1 / 3;
}
.membership-main-component-middle-container {
  grid-column: 3 / 10;
  padding-left: 25px;
  padding-top: 10px;
}
.membership-main-component-right-container {
  grid-column: 10 / 13;

  padding-left: 30px;
  padding-right: 30px;
  background-color: white;
  height: 100%;
  border-radius: 15px;
}

@media screen and (max-width: 785px) {
  .membership-main-component-left-container,
  .membership-main-component-right-container {
    display: none;
  }
  .membership-main-component-middle-container {
    grid-column: 1 / 13;
    padding-left: 25px;
  }
}



/* Membership Container */
.membership-container {
    width: 80%;
    margin: 20px auto;
    padding: 20px;
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    
}

/* Membership Header */
.membership-header {
    text-align: center;
    margin-bottom: 20px;
}

.membership-header h1 {
    margin: 0;
    color: #007bff;
}
