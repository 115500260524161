.sidebar-header {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 1rem 1.5rem;
}

.close-btn {
	font-size: 1.75rem;
	background: transparent;
	border-color: transparent;
	cursor: pointer;
	color: #c66868;
	margin-top: 0.2rem;

	grid-column: 12;
}

.close-btn:hover {
	color: #ca4d4d;
}
.sidebar-items-container-main {
	display: grid;
	height: 100%;
	grid-template-columns: repeat(auto-fit, minmax(auto, 1fr));
	grid-template-rows: repeat(12, auto);
	gap: 7px;
}
.sidebar-items-links-main {
	grid-row: 1;
}
.sidebar-items-footer-main {
	grid-row: 9;
}
.links a {
	display: flex;
	align-items: center;
	font-size: 1.25rem;
	text-transform: capitalize;
	padding: 1rem 1.5rem;
	color: var(--clr-grey-3);
	transition: var(--transition);
	letter-spacing: var(--spacing);
	text-decoration: none;
}

.links li {
	list-style-type: none;
}

.links a:hover {
	border-radius: 15px;
	background: var(--clr-grey-10);
	color: var(--clr-grey-2);
}

.links a svg {
	font-size: 1.5rem;
	color: var(--clr-grey-5);
	margin-right: 1rem;
	transition: var(--transition);
}

.links a:hover svg {
	color: var(--clr-grey-4);
}

.sidebar {
	position: fixed;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	background: var(--green-theme-color);

	box-shadow: var(--clr-red-dark);
	transition: var(--transition);
	transform: translate(-100%);
	border-radius: 10px;
	z-index: 1000;
	overflow-y: auto;
	z-index: 1002;
}

.show-sidebar {
	transform: translate(0);
	height: 100%;
	z-index: 1002;
	padding-right: 15px;
}

.sidebar-toggle {
	grid-column: 1;
	font-size: 1.5rem;
	color: var(--clr-primary-5);
	background: transparent;
	border-color: transparent;
	cursor: pointer;

	-webkit-animation: 260ms ease 0s normal forwards 1 fadein;
	animation: 260ms ease 0s normal forwards 1 fadein;
}

.sidebar-subnav {
	padding-bottom: 3px;
}
.sidebar-subnav-icon {
	padding-left: 20px;
}
.sidebar-subnav-label {
	font-size: 15px;
}
@keyframes fadein {
	0% {
		opacity: 0;
	}
	66% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

@-webkit-keyframes fadein {
	0% {
		opacity: 0;
	}
	66% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

.sidebar-toggle:hover {
	color: var(--clr-primary-1);
	transform: rotate(90deg);
}

@media screen and (min-width: 676px) {
	.sidebar {
		width: 500px;
	}
}
