.new-planned-year-activity-card-container {
	display: grid;
	grid-template-rows: repeat(12, 1fr);
	gap: 10px;
	padding-left: 35px;
	overflow-y: auto;
	overflow-x: hidden;
	border-radius: 10px;
}

.new-planned-year-activity-card-close-btn {
	font-family: "Roboto";
	padding-right: 10px;
	color: var(--pinki-theme-color);
	background: transparent;
	border-style: none;

	font-size: 22px;
	font-weight: bold;
	text-align: center;
	cursor: pointer;

	align-self: flex-end;
	justify-self: flex-end;
}
.new-planned-year-activity-card-title {
	grid-column: 1;
	text-align: left;
	font: normal normal normal 30px/30px Vidaloka;
	letter-spacing: -0.45px;
}
.new-planned-year-activity-card-read-only-container-div {
	padding-right: 45px;
}

.new-planned-year-activity-card-container-div {
	padding-right: 45px;
}

.new-planned-year-activity-card-read-only-container {
	grid-column: 1;
	display: grid;
	padding-left: 15px;
	background: #fceacc 0% 0% no-repeat padding-box;
	border-radius: 10px;
	padding-top: 7px;
	height: 68px;
}
.new-planned-year-activity-card-read-only-item {
	grid-column: 1;
	text-align: left;
	font: normal normal normal 16px/16px Vidaloka;
	letter-spacing: -0.24px;
	font-weight: bold;
}
.new-planned-year-activity-card-frequency-container {
	padding-top: 30px;
	padding-bottom: 10px;
	padding-right: 45px;
	display: grid;

	grid-column: 1;
}
.new-planned-year-activity-card-date-picker-container {
	padding-right: 45px;
	display: grid;
	grid-template-columns: repeat(3, minmax(20px, 1fr));
	grid-column: 1;
	text-align: center;
}
.new-planned-year-activity-card-label-item {
	grid-column: 1;
	text-align: left;
	font: normal normal bold 16px/14px Charter;
	letter-spacing: -0.24px;
	align-self: center;
}
.new-planned-year-activity-card-dropdown {
	grid-column: 2;
}
.new-planned-year-activity-card-date-picker {
	grid-column: 2;
	grid-row: 1;
}
.new-planned-year-activity-card-time-picker {
	grid-column: 3;
	grid-row: 1;
}
.new-planned-year-activity-card-show-time {
	cursor: pointer;
	font: normal normal bold 16px/14px Charter;
	letter-spacing: -0.24px;
	grid-column: 4;
	grid-row: 1;
	color: grey;
}
.new-planned-year-activity-card-hide-time {
	cursor: pointer;
	font: normal normal bold 16px/14px Charter;
	letter-spacing: -0.24px;
	grid-column: 4;
	grid-row: 1;
	color: red;
}
.new-planned-year-activity-card-activity-comments {
	grid-column: 1;

	background: #fceacc 0% 0% no-repeat padding-box;
	border-radius: 10px;
}

.new-planned-year-activity-card-comment-content {
	resize: none;
	width: 320px;
	border-radius: 8px;
	background: #fceacc 0% 0% no-repeat padding-box;
	align-items: center;

	color: grey;
	padding-left: 10px;
	border-style: none;
	min-height: 100px;
}

.new-planned-year-activity-card-datetime-picker {
	cursor: pointer;
	background: #e0e9e3 0% 0% no-repeat padding-box;
	border-radius: 10px;
	font-size: small;
}
.new-planned-year-activity-card-datetime-time-picker {
	cursor: pointer;
	background: #e0e9e3 0% 0% no-repeat padding-box;
	border-radius: 10px;
	font-size: small;
}
.new-planned-year-activity-card-frequency-dropdown {
	background: #e0e9e3 0% 0% no-repeat padding-box;
	height: 26px;
	border-style: none;
	border-radius: 10px;
}

.new-planned-year-activity-card-save-btn {
	cursor: pointer;
	align-self: center;
	justify-self: center;
	width: 130px;
	height: 33px;
	background-color: var(--greendark-theme-color);
	border-radius: 10px;
	text-align: center;
	padding: 5px;
}
.new-planned-year-activity-card-save-btn-text {
	letter-spacing: 1px;
	color: #ffffff;
}
