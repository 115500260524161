.tutorial-link-icon {
	position: relative;
}

.tutorial-link-home-container {
	display: grid;
	grid-template-columns: repeat(auto-fill, auto);
	justify-content: start;

	gap: 5px;
	cursor: pointer;
}

.tutorial-link-home-icon {
	grid-column: 1;
	color: var(--green-theme-color);
	font-weight: bolder;
	font-size: 26px;
}
.tutorial-link-home-text {
	grid-column: 2;
	padding-left: 10px;

	align-self: center;
}
