.calendar-periods-container {
	display: grid;
	grid-template-columns: repeat(auto-fill, minmax(70px, 1fr));
	gap: 10px;
	padding: 10px;
}

.calendar-periods-card {
	background-color: #f0f0f0;
	border: 1px solid #ccc;
	border-radius: 5px;
	padding: 10px;
	box-sizing: border-box;
	text-align: center;
	cursor: pointer;
	transition: transform 0.2s;
	width: 100%;
	height: 60px;
}
.calendar-periods-card-add {
	align-self: center;
	color: green;
}

.calendar-periods-card:hover {
	transform: scale(1.05);
}

.calendar-periods-card-edit-icon {
	color: var(--green-theme-color);
	text-align: end;
}
