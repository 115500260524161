/* General Container */
.check-out-window-container {
	display: grid;
	grid-template-columns: 1fr;
	gap: 20px;
	padding: 20px;
	background: #ffffff;
	box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
	border-radius: 10px;
	max-width: 500px;
	margin: 0 auto;
}

/* Input Fields */
.check-out-input {
	width: 100%;
	height: 40px;
	padding: 10px;
	border: 1px solid #d1d5db;
	border-radius: 5px;
	font-size: 16px;
	color: #333333;
	background-color: #f9fafb;
}

.check-out-input:focus {
	border-color: #007bff;
	outline: none;
	box-shadow: 0 0 5px rgba(0, 123, 255, 0.3);
}

/* Card Element */
.check-out-card-element {
	padding: 15px;
	background: #f9fafb;
	border: 1px solid #d1d5db;
	border-radius: 5px;
	box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
}

/* Labels */
.check-out-card-element-label {
	font-size: 14px;
	color: #666666;
	margin-bottom: 5px;
}

/* Buttons */
.check-out-initiate-btn,
.check-out-confirm-pay-btn {
	width: 100%;
	padding: 10px;
	background-color: #007bff;
	color: #ffffff;
	font-size: 16px;
	font-weight: bold;
	border: none;
	border-radius: 5px;
	cursor: pointer;
	transition: background-color 0.3s ease;
	text-align: center;
}

.check-out-initiate-btn:hover,
.check-out-confirm-pay-btn:hover {
	background-color: #0056b3;
}

.check-out-close-btn {
	font-family: "Roboto";
	color: var(--pinki-theme-color);
	background: transparent;
	border-style: none;

	font-size: 22px;
	font-weight: bold;
	text-align: center;
	cursor: pointer;

	align-self: flex-end;
	justify-self: flex-end;
}

/* Summary Section */
.check-out-sumary-details {
	padding: 15px 0;
	border-top: 1px solid #d1d5db;
	text-align: right;
}

.cart-summary-total-price {
	font-size: 18px;
	font-weight: bold;
	color: #333333;
}
.check-out-card-element-stripe {
	padding-top: 10px;
}
.check-out-card-row {
	padding-top: 10px;
}
.price-note {
	font-size: 12px;
	color: #666666;
	margin-top: 5px;
	font-style: italic;
}

/* Responsive Design */
@media only screen and (max-width: 600px) {
	.check-out-window-container {
		padding: 15px;
		width: 90%;
	}
	.check-out-initiate-btn {
		font-size: 14px;
	}
}
