.plan-per-day-report-container {
  display: grid;
  text-align: center;
}

.plan-per-day-report-headers {
  grid-row: 1;

  display: grid;
  grid-template-columns: repeat(12, 1fr);
  gap: 10px;
  grid-auto-flow: dense;
  justify-items: center;
  text-align: center;

  padding-bottom: 10px;
}

.plan-per-day-report-date-input-container {
  grid-row: 1;
  grid-column: 1 / 13;

  display: flex;
  gap: 7px;
}
.plan-per-day-report-date-input {
  background: #e0e9e3 0% 0% no-repeat padding-box;
  border-radius: 10px;
  font: normal normal bold 16px Charter;
  letter-spacing: -0.24px;
  color: rgb(98, 97, 97);
  border: none;
  cursor: pointer;
}

.plan-per-day-report-btn-container {
  grid-row: 2;
  grid-column: 1 / 13;
  align-self: flex-end;
  cursor: pointer;

  display: flex;
  gap: 3px;
}
.plan-per-day-report-btn {
  width: 200px;
  height: 26px;
  background: #98b5a4 0% 0% no-repeat padding-box;
  border-radius: 10px;
  color: white;
  text-align: center;
  cursor: pointer;

  padding-top: 3px;
  font: normal normal 15px Charter;
  letter-spacing: -0.21px;
}

.plan-per-day-report-print-item-btn {
  width: 200px;
  height: 26px;
  background: var(--greensoft-theme-color) 0% 0% no-repeat padding-box;
  border-radius: 10px;
  color: rgb(98, 97, 97);
  text-align: center;
  cursor: pointer;

  padding-top: 3px;
  font: normal normal 15px Charter;
  letter-spacing: -0.21px;
}
.plan-per-day-report-content {
  grid-row: 2;
}
