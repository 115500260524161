.custom-confirmation-container-card {
  /* display: grid;
  grid-template-columns: repeat(auto-fit, auto); */

  padding: 10px;
  background: #ffffff;
  border-style: none;
  border-radius: 30px;

  border-bottom-style: solid;
  border-top-style: solid;
  border-color: var(--green-theme-color);
}

.custom-confirmation-btn-container {
  padding-top: 30px;
  width: 100%;
  text-align: center;
}
.custom-confirmation-continue {
  height: 23px;
  width: 100px;

  color: #ffffff;
  background: var(--green-theme-color);
  border-style: none;
  border-radius: 10px;
  border-width: thin;

  cursor: pointer;
  font-size: 15px;
}
