.planner-sidebar-links .planner-sidebar-item {
  cursor: pointer;
  display: flex;
  height: 50px;
  align-items: center;
  font-size: 1.25rem;
  text-transform: capitalize;
  color: var(--clr-grey-3);
  transition: var(--transition);
  letter-spacing: var(--spacing);
  text-decoration: none;
  padding-left: 25px;
}
.planner-sidebar-links .planner-sidebar-sub-item {
  cursor: pointer;
  display: flex;
  height: 60px;
  align-items: center;
  font-size: 15px;
  text-transform: capitalize;
  color: var(--clr-grey-3);
  transition: var(--transition);
  letter-spacing: var(--spacing);
  text-decoration: none;
  padding-left: 40px;

  background: #b4cdc3;
  border-radius: 20px;
}

.planner-sidebar-links li {
  list-style-type: none;
}

.planner-sidebar-links div:hover {
  border-radius: 15px;
  background: var(--clr-grey-10);
  color: var(--clr-grey-2);
}

.planner-sidebar-links div svg {
  font-size: 1.5rem;
  color: var(--clr-grey-5);
  margin-right: 1rem;
  transition: var(--transition);
}

.planner-sidebar-links div:hover svg {
  color: var(--clr-grey-4);
}

@media only screen and (min-width: 1019px) {
  .planner-sidebar-links-container {
    display: none;
  }
}
