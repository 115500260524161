.done-component-transaction-retries-circle {
	position: relative; /* Add this line to allow absolute positioning within */
	width: 200px;
	height: 200px;
	background-color: transparent;
	border-radius: 50%;
	opacity: 1;
	transition: opacity 0.5s ease-in-out;
}

.done-component-transaction-retries-fade-in {
	opacity: 1;
	transition: opacity 0.5s ease-in-out;
}

.done-component-transaction-retries-fade-out {
	opacity: 0;
}

.done-component-transaction-retries-circle-grid {
	position: relative;
}

.done-component-transaction-retries-logo {
	width: 100%;
	height: 100%;
}

.done-component-transaction-retries-check {
	position: absolute;
	top: 58%;
	left: 77%;
	transform: translate(-50%, -50%);
	color: orange;
	font-size: 100px;
}
