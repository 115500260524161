.weeklyscheduledate {
	width: 150px;
	justify-self: center;
	background-color: var(--orangesoft-theme-color);
	color: black;
	border-style: none;
	border-radius: 7px;
	font-size: 14px;
	z-index: 999;
	grid-row: 1;
}
.report-print-item-weekly-schedule {
	justify-self: center;
}
.report-print-item-weekly-schedule-btn-container {
	display: flex;
	gap: 7px;
	grid-row: 2;
}
.move-week-down-weekly-schedule {
	grid-column: 1;

	background-color: var(--green-theme-color);
	border-radius: 10px;
	justify-self: end;
	width: 100px;
	font: normal normal bold 16px Charter;
	letter-spacing: -0.24px;
	color: white;
	border: none;
	cursor: pointer;
	text-align: center;
	padding-top: 2px;
}
.print-btn-weekly-schedule {
	grid-column: 2;
}
.select-child-weekly-schedule {
	grid-column: 3;

	background-color: var(--greendark-theme-color);
	border-radius: 10px;
	justify-self: end;
	width: 70px;
	font: normal normal bold 16px Charter;
	letter-spacing: -0.24px;
	color: white;
	border: none;
	cursor: pointer;
	text-align: center;
}

.hide-weekend-btn-weekly-schedule {
	grid-column: 4;

	background-color: var(--green-theme-color);
	border-radius: 10px;
	justify-self: end;
	width: 100px;
	font: normal normal bold 16px Charter;
	letter-spacing: -0.24px;
	color: white;
	border: none;
	cursor: pointer;
	text-align: center;
	padding-top: 2px;
}
.move-week-btn {
	grid-column: 5;
	background-color: var(--green-theme-color);
	border-radius: 10px;
	justify-self: end;
	width: 100px;
	font: normal normal bold 16px Charter;
	letter-spacing: -0.24px;
	color: white;
	border: none;
	cursor: pointer;
	text-align: center;
	padding-top: 2px;
}

.revert-btn {
	grid-column: 6;
	background-color: var(--pinki-theme-color);
	border-radius: 10px;
	justify-self: end;
	width: 100px;
	font: normal normal bold 16px Charter;
	letter-spacing: -0.24px;
	color: white;
	border: none;
	cursor: pointer;
	text-align: center;
	padding-top: 2px;
}
.options-btn {
	grid-column: 4;
	background-color: var(--greendark-theme-color);
	border-radius: 10px;
	justify-self: end;
	width: auto;
	font: normal normal bold 16px Charter;
	letter-spacing: -0.24px;
	color: white;
	border: none;
	cursor: pointer;
	text-align: center;
	padding-top: 2px;
	padding-left: 10px;
	padding-right: 10px;
}

.skip-week-tag-weekly-schedule {
	grid-column: 1/4;

	font-family: "Alegreya";
	text-align: center;

	padding-bottom: 5px;
}

.edit-selected-activities-btn-weekly-schedule {
	grid-column: 6;

	background-color: var(--greendark-theme-color);
	border-radius: 10px;
	justify-self: end;
	width: 100px;
	font: normal normal bold 16px Charter;
	letter-spacing: -0.24px;
	color: white;
	border: none;
	cursor: pointer;
	text-align: center;
	padding-top: 2px;
}

.weekly-schedule-print-header {
	width: 100%;
	color: #d4d4d4;
	text-align: end;
}
.weekly-schedule-controls {
	display: grid;
	grid-template-columns: repeat(3, auto);
	gap: 10px;
	grid-auto-flow: dense;

	text-align: center;
	padding-bottom: 10px;
	width: 100%;
	bottom: 5%;

	color: #d4d4d4;
	padding-top: 7px;
}

.weekly-schedule-controls-back {
	cursor: pointer;
	font-size: 24px;
	font-weight: bold;
	grid-row: 1;
	grid-column: 1;
	color: var(--green-theme-color);
}
.weekly-schedule-controls-forward {
	cursor: pointer;
	font-size: 24px;
	font-weight: bold;
	grid-row: 1;
	grid-column: 3;
	color: var(--green-theme-color);
}
.weekly-schedule-dates-data {
	display: grid;
	grid-template-columns: repeat(auto-fit, auto);
	gap: 5px;

	grid-row: 1;
	grid-column: 2;
}
.weekly-schedule-controls-kid-name {
	color: rgb(98, 97, 97);
}
.weekly-schedule-date-input {
	padding-left: 5px;
	padding-right: 5px;
	background: #e0e9e3 0% 0% no-repeat padding-box;
	border-radius: 10px;
	height: 40px;
	width: auto;
	font: normal normal bold 16px Charter;
	letter-spacing: -0.24px;
	color: rgb(98, 97, 97);
	border: none;

	justify-self: center;
	cursor: pointer;

	background-color: var(--greensoft-theme-color);
}
.weekly-schedule-duplicate {
	padding-left: 10px;
}

@media screen and (max-width: 700px) {
	.print-btn-weekly-schedule {
		display: none;
	}
	.report-print-item-weekly-schedule-btn-container {
		display: flex;
		width: 100%;
		flex-wrap: wrap;
		/* justify-content: space-between; */
	}
}
