.plan-per-day-custom-report-container {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
}

.plan-per-day-custom-report-table {
	width: 100%;
	max-width: 100vw;
	margin: 0 auto;
	border-collapse: collapse;
}

.plan-per-day-custom-report-table tr {
	width: 100%;
}

.plan-per-day-custom-report-table td,
.plan-per-day-custom-report-table th {
	border: 1px solid #ddd;
	padding: 8px;
	text-align: center;

	text-align: left;
}

.plan-per-day-custom-report-table th.table-header-activity,
.plan-per-day-custom-report-table td.table-header-activity {
	width: 700px;
}
.plan-per-day-custom-report-table th.table-header-date,
.plan-per-day-custom-report-table td.table-header-date {
	width: 200px;
}
.plan-per-day-custom-report-table th.table-header-student,
.plan-per-day-custom-report-table td.table-header-student {
	width: 300px;
}

.report-table-section-title {
	text-align: right;
	padding-top: 10px;
	padding-bottom: 7px;
}
.plan-per-day-custom-report-table-header {
	background-color: #f2f2f2;
}

.plan-per-day-custom-report-container {
	display: grid;
	text-align: center;
}

.plan-per-day-custom-report-headers {
	grid-row: 1;

	display: grid;
	grid-template-columns: repeat(12, 1fr);
	gap: 10px;
	grid-auto-flow: dense;
	justify-items: center;
	text-align: center;

	padding-bottom: 10px;
}

.plan-per-day-custom-report-date-input-container {
	grid-row: 1;
	grid-column: 1 / 13;

	display: flex;
	gap: 7px;
}
.plan-per-day-custom-report-date-input {
	background: #e0e9e3 0% 0% no-repeat padding-box;
	border-radius: 10px;
	font: normal normal bold 16px Charter;
	letter-spacing: -0.24px;
	color: rgb(98, 97, 97);
	border: none;
	cursor: pointer;
	padding-top: 5px;
	padding-bottom: 5px;
	padding-left: 15px;
	padding-right: 15px;
}

.plan-per-day-custom-report-btn-container {
	grid-row: 2;
	grid-column: 1 / 13;
	align-self: flex-end;
	cursor: pointer;

	display: flex;
	gap: 3px;
	padding-left: 10px;
	padding-right: 10px;
}
.plan-per-day-custom-report-btn {
	width: 200px;
	height: 26px;
	background: #98b5a4 0% 0% no-repeat padding-box;
	border-radius: 10px;
	color: white;
	text-align: center;
	cursor: pointer;

	padding-top: 3px;
	font: normal normal 15px Charter;
	letter-spacing: -0.21px;
}

.plan-per-day-custom-report-print-item-btn {
	width: 200px;
	height: 26px;
	background: var(--greensoft-theme-color) 0% 0% no-repeat padding-box;
	border-radius: 10px;
	color: rgb(98, 97, 97);
	text-align: center;
	cursor: pointer;

	padding-top: 3px;
	font: normal normal 15px Charter;
	letter-spacing: -0.21px;
}
.plan-per-day-custom-report-content {
	grid-row: 2;
}
.plan-per-day-custom-report-table-row {
	cursor: pointer;
}
.plan-per-day-custom-report-table-field-activity {
	text-align: start;
}
.plan-per-day-custom-report-table-field-activity-title {
	font-weight: bold;
}
.plan-per-day-custom-report-table-field-date,
.plan-per-day-custom-report-table-field-child {
	font-style: normal;
	font-variant: normal;
	font-size: 16px;
	line-height: 16px;
	font-family: Vidaloka;
	letter-spacing: -0.36px;
}
.plan-per-day-custom-report-children-btn {
	background-color: var(--greendark-theme-color);
	border-radius: 10px;
	justify-self: end;
	width: 70px;
	font: normal normal bold 20px Charter;
	letter-spacing: -0.24px;
	color: white;
	border: none;
	cursor: pointer;
	text-align: center;
}

.plan-per-day-custom-report-table-field-activity-title {
	font-size: 14px;
	line-height: 15px;
	letter-spacing: -0.3px;

	font-family: "Alegreya";
	font-style: normal;
	font-weight: bold;
}
.plan-per-day-custom-report-table-field-activity-date {
	font-size: 14px;
	line-height: 15px;
	letter-spacing: -0.3px;

	font-family: "Alegreya";
	font-style: normal;
	font-weight: 400;
}

.plan-per-day-custom-report-table-field-activity-comment,
.plan-per-day-custom-report-table-field-activity-comment p,
.plan-per-day-custom-report-table-field-activity-comment .ql-bubble {
	cursor: pointer;
	font-size: 14px;
	letter-spacing: -0.3px;

	font-family: "Alegreya";
}

.plan-per-day-custom-report-group-by {
	width: 200px;
	/* height: 26px; */
	background: var(--greensoft-theme-color) 0% 0% no-repeat padding-box;
	border-radius: 10px;
	color: rgb(98, 97, 97);
	text-align: center;
	cursor: pointer;

	padding-top: 3px;
	font: normal normal 15px Charter;
	letter-spacing: -0.21px;
}

.plan-per-day-custom-report-group-by label {
	font-weight: bold;
}

.plan-per-day-custom-report-group-by select {
	background: #e0e9e3;
	border-radius: 10px;
	border: none;
	width: 80px;
	text-align: center;
	color: rgb(98, 97, 97);
	cursor: pointer;
	appearance: none;
	-webkit-appearance: none;
	-moz-appearance: none;
}

.plan-per-day-custom-report-group-by select:focus {
	outline: none;
	box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
}

@media only screen and (max-width: 687px) {
	.plan-per-day-custom-report-btn-container {
		width: 100%;
	}
}
