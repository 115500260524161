.activity-child-report-table table {
  border-collapse: collapse;

  width: 100%;
  table-layout: fixed;
  font-family: "Roboto";
}

.activity-child-report-table th,
.activity-child-report-table td,
.activity-child-report-table tr {
  border-bottom: 1px solid #ddd;
  height: 52px;
  align-self: center;
  text-align: center;
  padding: 1px;
}

.activity-child-report-table th {
  background-color: var(--greensoft-theme-color);
}
.activity-child-report-table td {
  font-size: 15px;
}

.activity-child-report-table {
  padding-top: 10px;
  font-weight: 550;
}

.activity-child-report-table tr:hover {
  background-color: var(--greensoft-theme-color);
  border-radius: 20px;
}

.activity-child-report-row-activity {
  font-weight: bold;
  text-align: left;
  padding-left: 10px;
}
.activity-child-report-row-comments {
  color: grey;
  font-size: 12px;
  text-align: left;
  padding-left: 17px;
}
