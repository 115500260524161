.select-activity-checkbox-container {
	text-align: end;
}
.select-activity-checkbox {
	display: none;
}
.select-activity-checkbox-show {
	display: block;
	cursor: pointer;
}
