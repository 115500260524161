.error-boundary-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%; 
  text-align: center;
  padding: 20px; 
  box-sizing: border-box; 
}

