.is-loading-component-circle {
	width: 100%;
	height: 200px;
	background-color: transparent;
	border-radius: 50%;
	opacity: 1;
	transition: opacity 0.5s ease-in-out;
	align-items: center;
}

.is-loading-component-fade-in {
	opacity: 1;
}

.is-loading-component-fade-out {
	opacity: 0;
}
.is-loading-component-logo {
	width: 100%;
	height: 100%;
}
.is-loading-component-text {
	text-align: center;
}
