.planner-home-planned-activities {
	padding-right: 20px;
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(auto, 1fr));
	gap: 15px;
}
.planner-home-container {
	overflow: auto;
}

.planner-home-middle-container {
	display: grid;
	font-family: "Newsreader";
	font-weight: bolder;
	padding-top: 15px;
	padding-bottom: 30px;
}
.planner-home-middle-left {
	grid-column: 1;
	text-align: start;

	font-weight: bolder;
}
.planner-home-middle-right {
	cursor: pointer;
	grid-column: 2;
	text-align: end;
	font-weight: lighter;
	color: var(--link-color);

	/* font: normal normal normal 20px Vidaloka;
  letter-spacing: -0.36px; */
}
.planner-home-middle-right:hover {
	font-weight: bolder;
}

.react-calendar {
	text-align: center;
	max-width: 100%;
	background: white;
	border: none;
	font-family: Vidaloka;
	font-size: 14px;
	line-height: 0.3em;
}

.planner-home-planned-activities-embeded-spinner-container {
	width: 100%;
	text-align: center;
}

@media screen and (max-width: 800px) {
	.react-calendar {
		line-height: 1.5em;
	}
}
