.login-mobile-container {
  display: grid;
  grid-template-rows: repeat(12, auto);
  background: transparent linear-gradient(180deg, #98b5a4 0%, #4c5b52 100%) 0%
    0% no-repeat padding-box;
  border: 1px solid #707070;
  opacity: 0.95;
}
.show-pwd-btn-black {
  padding-left: 5px;
  cursor: pointer;
  font-size: 20px;
  color: black;
}
.login-mobile-text {
  text-align: center;
  font: normal normal normal 19px/20px Charter;
  letter-spacing: -0.24px;
  color: #ffffff;
  padding-left: 60px;
  padding-right: 60px;

  grid-column: 1 / 12;
  grid-row: 3;
}
.login-mobile-back {
  cursor: pointer;
  text-align: center;
  font: normal normal bolder 35px/35px Charter;

  letter-spacing: -0.24px;
  color: #ffffff;

  grid-column: 1 / 12;
  grid-row: 4;
}

.login-mobile-title-text {
  text-align: center;
  font: normal normal normal 32px/30px Vidaloka;
  letter-spacing: -0.45px;
  color: #ffffff;

  grid-column: 1 / 12;
  grid-row: 6;
}

.login-mobile-form-container {
  grid-column: 1 / 12;
  grid-row: 7;
  padding-left: 20px;
  padding-right: 20px;
}
.login-mobile-form-item {
  display: grid;
  gap: 7px;
  padding-left: 20px;
  padding-right: 20px;
  z-index: 1002;
}
.login-mobile-label {
  padding-top: 10px;
  text-align: left;
  font: normal normal normal 18px/20px Charter;
  letter-spacing: -0.27px;
  color: #ffffff;
}
.login-mobile-input {
  font-size: 18px;
  background: #cbdad1 0% 0% no-repeat padding-box;
  border-radius: 10px;
  padding-left: 5px;
  height: 45px;
}

.login-mobile-has-account-container {
  padding-bottom: 5px;
}
.login-mobile-has-account {
  font-size: 18px;
  text-align: left;
  letter-spacing: -0.27px;
  color: #ffffff;
}

.login-mobile-login {
  cursor: pointer;
  font-size: 18px;
  text-align: left;
  letter-spacing: -0.27px;
  color: #ffffff;
  font-weight: bold;
}
.login-mobile-login:hover {
  text-decoration: underline;
}
.login-mobile-btn-container {
  padding-left: 50px;
  padding-right: 50px;
  grid-column: 1 / 12;
  grid-row: 9;
  height: 66px;
  text-align: center;
}

.login-mobile-btn-container-background {
  height: 66px;
  background: #98b5a4 0% 0% no-repeat padding-box;
  border-radius: 20px;
}
.login-mobile-btn-login {
  padding-top: 10px;
  font: normal normal bold 17px/45px Charter;
  letter-spacing: -0.26px;
  color: #ffffffde;
}
